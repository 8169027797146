import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PRIVACY_kr, PRIVACY_ca } from "../../tandc";
import "./Footer.css";

const Privacy = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const Privacy = lang === "kr" ? PRIVACY_kr : PRIVACY_ca;

    return (
        <>
            <div className="contentContainer">
                <div className="contentTitle">{t("Privacy")}</div>
                <div
                    className="htmlDiv"
                    dangerouslySetInnerHTML={{
                        __html: Privacy,
                    }}
                />
            </div>
        </>
    );
};

export default Privacy;
