import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_APP_AUTH } from "views/App/constants";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Logout = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isSuperUser = useSelector((state) => state.app.isSuperUser);

    const init = () => {
        localStorage.removeItem("loginData");
        localStorage.removeItem("loginDataExpiration");

        dispatch({
            type: UPDATE_APP_AUTH,
            username: "",
            password: "",
            accessToken: "",
            isAdmin: false,
            isSuperUser: false,
            email: "",
        });

        if (isSuperUser) {
            localStorage.removeItem("forumData");
        }
    };

    useEffect(init, []);

    return (
        <>
            <div className={styles.logOutDiv}>
                <div className={styles.logOutTxt}>{t("LoggedOut")}</div>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <Link to="/">{t("ToHome")}</Link>
                </div>
            </div>
        </>
    );
};

export default Logout;
