export const TANDC_kr = `<b>제1조(목적)</b>
1. 이 약관은 전기통신사업법 및 동법 시행령에 의하여 C&K투어(이하 당사라 한다)가 제공하는 인터넷 여행 서비스(이하 서비스)인 cnktour.com의 이용조건, 절차, 그리고 회원규칙에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.

<b>제2조(약관의 효력과 준용)</b>
1. 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로서 효력을 발생합니다. 
2. 당사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 규정함으로써 효력을 발생합니다.

<b>제3조(약관 외 준칙)</b>
1. 이 약관에 명시되지 않은 사항은 전기통신본법, 전기통신사업사업법 및 기타관계 법령의 규정에 따릅니다.

<b>제4조(용어의 정의)</b>
1. 이 규정에서 사용하는 용어의 정의는 다음과 같습니다.
가. 이용자 : 당사가 제공하는 서비스를 이용하는 모든 자
나. 회원 : 이용자 중 당사와 서비스 이용계약을 체결한 자
다. 아이디 : 회원식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 문자 또는 이루어진 조합
라. 비밀번호 : 회원이 부여받은 아이디(ID)와 일치된 회원임을 확인하고, 회원 자신의 비밀을 보호하기 위하여 정한 문자와 숫자의 조합
마. 해지 : 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것

<b>제5조(이용계약의 성립)</b>
1. 이용 계약은 이용자의 이용 신청에 대한 당사의 이용 응낙과 이용자의 약관 내용에 대한 동의로 성립됩니다. 
2. 회원에 가입하여 서비스를 이용하고자 하는 희망자는 당사에서 요청하는 개인 신상정보를 제공해야 합니다. 
3. 회원에 가입하여 서비스를 이용하고자 하는 희망자는 지정된 온라인 가입신청 양식에 정보를 필수로 제공하며, 그 외의 정보는 선택사항으로 합니다.
-이용자 번호(ID) -비밀번호(PASSWORD) -이름(실명/영문명) -주민등록번호 -전화번호와 주소
4. 위의 내용 이외에도 당사가 필요하다고 인정되는 내용을 추가로 덧붙일 수 있으며, 이 사실을 공고합니다.

<b>제6조(이용신청의 승낙)</b>
1. 당사는 이용자가 제2장 3조에서 정한 사항을 정확히 기재하여 이용신청을 하였을 때 승낙합니다.
2. 당사는 다음에 해당하는 이용신청에 대하여서는 승낙을 유보할 수 있습니다.
- 설비에 여유가 없거나 기술상 지장이 있는 경우 - 기타 본사가 필요하다고 인정되는 경우
3. 당사는 다음에 해당하는 이용신청에 대하여서는 이를 응낙하지 아니할 수 있습니다.
- 이름이 실명이 아닌 경우 - 다른 사람의 명의를 사용하여 신청한 경우 - 이용신청시 필요내용을 허의로 기재하여 신청한 경우 - 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우 - 기타 당사가 정한 이용신청요건이 미비된 경우 - 기타 당사가 정한 이용신청요건이 미비되었을 때

<b>제7조(계약사항의 변경)</b>
1. 회원은 이용신청시 기재한 사항이 변경되었을 경우에는 온라인을 통해 수정을 해야합니다. 수정을 하지 않아서 발생하는 이용자의 피해에 대한 책임은 이용자에게 있습니다.

<b>제8조(개인정보의 보호)</b>
1. 당사는 서비스 이용자의 정보수집시 반드시 당해 이용자의 동의를 얻어 필요한 최소한의 정보만을 수집하며, 이용자는 온라인을 통하여 수시로 본인의 신장정보를 열람, 정정 또는 삭제할 수 있습니다.
2. 당사는 이용고객의 개인 신상정보를 본인의 동이없이는 절대 제3자에게 제공, 분실 또는 유출하여서는 아니되며 이를 지키지 않아서 발생하는 고객의 모든 피해에 대한 책임은 당사에 있습니다. 단, 다음의 각호에 해당하는 경우에는 예외로 합니다.
   가. 전기통신기본법 등 관계법령에 의해 국가기관 등의 요구가 있는 경우 
   나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 
   다. 배송 업무상 배송업체에게 고객의 정보를 알려주는 경우 
   라. 은행업무상 관련사항에 한하여 일부 정보를 공유하는 경우 
   마. 특정인을 식별할 수 없는 통계작성, 홍보자료, 학술연구 등의 목적인 경우 
   바. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
이용자의 동의 없이 개인정보가 유출되는 등 부당한 사례에 발견했을 때에는 한국소비자보호원, 경실련 등을 통해 신고할 수 있으며, 이에 대해 본사는 적절한 조치를 취할 의무가 있습니다.

<b>제9조(회원 아이디, 이메일, 비밀번호 관리에 대한 의무)</b>
1. 아이디, 이메일과 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID), 이메일(E-mail), 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다. 
2. 자신의 아이디(ID), 이메일(E-mail)과 비밀번호가 부정하게 사용된 경우 회원은 반드시 당사에 그 사실을 통보해야 합니다.

<b>제10조(서비스 종류)</b>
1. 당사는 서비스의 내용과 종류를 변경할 수 있으며, 변경사항은 공주사항을 통하여 공지합니다. 서비스의 종류는 아래와 같습니다.
가. 인터넷 온라인을 통한 항공/호텔예약/판매 
나. 인터넷 온라인을 통한 여행상품 예약/판매 
다. 기타 여행관련 상품 및 정보 서비스

<b>제11조(정보의 제공)</b>
1.당사는 이용자가 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편, 유선매체, 서신우편 등의 방법으로 이용자에게 제공할 수 있습니다.

<b>제12조(서비스 제공의 중지)</b>
1. 당사는 다음 각 호에 해당하는 경우 서비스의 일부 혹은 전부의 제공을 중지할 수 있습니다
가. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우 
나. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우 
다. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주등으로 정상적인 서비스 이용에 지장이 있는 경우

<b>제13조(당사의 서비스 유지책임)</b>
1. 당사는 서비스 제공설비를 항상 운용가능한 상태로 유지하고 서비스 제공을 위한 설비에 장애가 발생하거나 또는 그 설비가 멸실될 때에는 이를 신속하게 수리, 복구합니다.

<b>제14조(이용자의 의무)</b>
1. 이용자는 서비스를 이용할 때 다음 각호의 행위를 하지 않아야 합니다.
가. 부정한 ID를 사용하는 행위나. 서비스에서 얻은 정보를 회사의 사전승낙없이 회원의 이용이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위 
나. 제3자의 저작권 등 기타 권리를 침해하는 행위 라. 범죄와 결부된다고 객관적으로 판단되는 행위 
다. 기타 관계법령에 위배되는 행위
2. 이용자는 이 규정에서 규정하는 사항과 서비스 이용안내, 공지사항에 기재하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.
3. 이용자는 회사의 사전승낙없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 이용자가 규정에 위반한 영업활동을 이용하여 발생한 결과에 대하여 책임을 지지 않습니다.

<b>제15조(요금의 체계 등)</b>
1. 당사가 제공하는 서비스 요금체계는 다음과 같습니다.
-가입 : 무료-여행관련 정보열람 : 무료-여행관련 서비스 예약 및 판매 : 유료-당사는 서비스 요금을 변경할 수 있으며, 요금에 관한 세부사항은 따로 공지합니다.

<b>제16조(계약해지)</b>
1. 회원이 이용계약을 해지하고자 하는 때에는 회원본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.
2. 당사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지없이 이용계약을 해지하거나 기간을 정하여 서비스 이용을 중지할 수 있습니다.
가. 부정한 ID를 사용하거나 이메일(E-mail) 및 비밀번호를 도용한 경우 
나. 서비스 운영을 고의로 방해한 경우 다. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우 
다. 타인의 명예를 손상시키거나 불이익을 주는 경우 바. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우 
라. 기타 회사가 정한 이용조건에 위배되는 경우

<b>제17조(이용제한에 관한 사항)</b>
1. 당사가 제 5 장 제1조 2항 각호의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유과 일시, 기간, 기타사항을 정하여 이메일(E-mail)이나 기타방법에 의하여 해당 이용자나 대리인에게 통지합니다. 단, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.
2. 제1항의 규정에 의해 이용제한의 통지를 받은 이용자 또는 대리인은 그 이용정지의 통지에 대하여 이의가 있을 경우 이의신청을 할 수 있습니다.
3. 당사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
4. 회사는 이용정지 기간중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시 해제합니다.

<b>제18조(손해배상에 관한 사항)</b>
1. 당사는 무료 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 관하여도 책임지지 않습니다. 2. 당사는 유료로 제공되는 서비스와 관련되어 회사의 귀책사유로 발생하는 모든 손해에 관하여 관계법률이 정하는 손해배상을 해당회원에게 하여야 합니다.

<b>제19조(면책조항)</b>
1. 당사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2. 당사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
3. 당사는 회원이 서비스를 이용하여 기대되는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
4. 당사는 회원이 서비스에 게제한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.

<b>제20조(관할법원)</b>
1. 서비스의 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.
`;

export const TANDC_ca = `<b>Article 1 (Purpose)</b>
1. These terms and conditions are established to regulate the conditions, procedures, and membership rules of the Internet travel service (hereinafter referred to as "the Service") provided by C&K Tour (hereinafter referred to as "the Company") under the Telecommunications Business Act and its enforcement decree.

<b>Article 2 (Effect and Application of the Terms)</b>
1. The contents of these terms and conditions become effective by posting them on the service screen or notifying the members by other means.
2. The Company can modify these terms, and the modified terms become effective by the same method as in paragraph 1.

<b>Article 3 (Supplementary Provisions to the Terms)</b>
1. Matters not specified in these terms and conditions shall be governed by the provisions of the Telecommunications Basic Act, the Telecommunications Business Act, and other relevant laws and regulations.

<b>Article 4 (Definitions of Terms)</b>
1. The definitions of terms used in these regulations are as follows:
   a. User: Anyone who uses the services provided by the Company.
   b. Member: A user who has entered into a service usage agreement with the Company.
   c. ID: A combination of characters or numbers selected by a member for identification and service usage, approved by the Company.
   d. Password: A combination of characters and numbers set by a member to confirm their identity, matching the ID.
   e. Termination: The cancellation of a service usage agreement by the Company or the member after the service is opened.

<b>Article 5 (Formation of Service Agreement)</b>
1. The service agreement is established by the Company's acceptance of the user's application and the user's agreement to the contents of the terms.
2. Individuals wishing to join as members and use the service must provide the personal information requested by the Company.
3. Individuals wishing to join as members and use the service must provide information as required in the designated online registration form, with the following information being mandatory:
   - User number (ID)
   - Password (PASSWORD)
   - Name (Real name/English name)
   - Resident registration number
   - Phone number and address
4. In addition to the above, the Company may add additional information deemed necessary, and this fact will be announced.

<b>Article 6 (Approval of Application)</b>
1. The Company approves the application when the user accurately completes the items specified in Chapter 2, Article 3.
2. The Company may withhold approval for the following types of applications:
   - When there is no capacity in the equipment or there is a technical hindrance
   - When deemed necessary by the headquarters for other reasons
3. The Company may not approve the following types of applications:
   - When the name is not a real name
   - When applying using another person's name
   - When deliberately providing false information when applying
   - When planning to use the service for the purpose of disrupting social order or public morals
   - When other conditions for application set by the Company are not met

<b>Article 7 (Change of Contractual Matters)</b>
1. Members must modify the information if there are changes to the information provided during the application. Members are responsible for any damages incurred due to failure to modify.

<b>Article 8 (Protection of Personal Information)</b>
1. When collecting information from service users, the Company obtains the necessary minimum information with the user's consent. Users can view, correct, or delete their personal information online at any time.
2. The Company does not provide, lose, or leak personal information of users to third parties without the user's consent, and the Company is responsible for all damages incurred. However, exceptions apply in cases such as:
   a. When required by government agencies under laws such as the Telecommunications Basic Act
   b. When there is a request from the Information Communication Ethics Committee for investigation purposes
   c. When providing information to a delivery company for delivery business
   d. When sharing some information for banking business-related matters
   e. When compiling statistics, promotional materials, academic research, etc., for purposes that do not identify specific individuals
   f. When there is a request according to the procedure specified by other related laws

If a user discovers cases of personal information leakage without consent, they can report it through the Korea Consumer Protection Agency, the Fair Trade Commission, etc., and the Company has the obligation to take appropriate measures.

<b>Article 9 (Obligations Regarding Member ID, Email, and Password Management)</b>
1. Members are responsible for all management of their ID, email, and password. Members are responsible for any damages resulting from the negligent management of their assigned ID, email, and password.
2. If one's ID, email, or password is used improperly, the member must notify the Company immediately.

<b>Article 10 (Types of Services)</b>
1. The Company can change the content and types of services and announce changes through official notices. The types of services are as follows:
   a. Airline/hotel reservation/sale through online internet
   b. Reservation/sale of travel products through online internet
   c. Other travel-related product and information services

<b>Article 11 (Provision of Information)</b>
1. The Company may provide users with various information deemed necessary during the use of the service through methods such as email, wire media, and written mail.

<b>Article 12 (Suspension of Service Provision)</b>
1. The Company may suspend the provision of all or part of the service under the following circumstances:
   a. Due to unavoidable circumstances such as maintenance of service equipment or construction work
   b. When the period telecommunication service provider specified in the Telecommunications Business Act suspends telecommunication services
   c. In case of a national emergency, power outage, failure of service facilities, or a surge in service usage that hinders normal service usage

<b>Article 13 (Company's Service Maintenance Responsibility)</b>
1. The Company constantly maintains service-providing facilities in operable condition. In case of an obstacle to the facilities for service provision or their destruction, the Company promptly repairs and recovers them.

<b>Article 14 (User's Obligations)</b>
1. Users must not engage in the following acts when using the service:
   a. Using a fraudulent ID
   b. Replicating information obtained from the service for purposes other than the member's use without the Company's prior approval or using it for publication, broadcasting, etc., or providing it to a third party
   c. Infringing on the copyrights or other rights of a third party
   d. Engaging in objectively judged acts associated with crimes
   e. Other acts in violation of related laws

2. Users must comply with the matters stipulated in these regulations, service usage guidelines, and announcements separately posted or notified by the Company.

3. Users are not allowed to use the service for business activities without the Company's prior approval. The Company is not responsible for any results of business activities and the consequences of violating the regulations in using them.

<b>Article 15 (Fee System, etc.)</b>
1. The fee system for services provided by the Company is as follows:
   - Joining: Free
   - Viewing travel-related information: Free
   - Reservation and sale of travel-related services: Paid
   - The Company may change the service fees, and details regarding fees will be separately announced.

<b>Article 16 (Termination of Contract)</b>
1. When a member wishes to terminate the service contract, the member must apply for termination online.
2. The Company may terminate the service contract without prior notice or suspend service for a specified period if a member engages in any of the following acts:
   a. Using a fraudulent ID or stealing email and password
   b. Deliberately obstructing the operation of the service
   c. Deliberately spreading content that hinders public order and morals
   d. Damaging the honor of others or causing harm
   e. Planning or executing service use with the intention of harming national interests or public welfare
   f. Violating the usage conditions set by the Company

<b>Article 17 (Matters Regarding Service Restriction)</b>
1. When the Company intends to restrict the use of a member under the provisions of Chapter 5, Article 1, Paragraph 2, each item, the reason, date, period, and other details will be notified to the respective user or representative by email or other means. However, this does not apply in cases where the Company deems it necessary to urgently suspend use.

2. If a user or representative who receives a notice of service restriction under the provisions of Paragraph 1 has objections to the notice of service suspension, they may file an objection.

3. The Company may temporarily postpone the suspension of use until the confirmation period for objections under the provisions of Paragraph 2 and notify the user or representative of the results.

4. If it is confirmed during the suspension period that the reason for suspension has been resolved, the Company will immediately lift the suspension.

<b>Article 18 (Matters Regarding Compensation for Damages)</b>
1. The Company is not responsible for any damages incurred by users related to the use of free services.
2. Regarding damages caused by the Company's fault related to paid services, the Company must compensate the respective member according to the laws and regulations.

<b>Article 19 (Disclaimer)</b>
1. The Company is exempt from liability for providing services in case of force majeure or events equivalent to force majeure.
2. The Company is not responsible for service disruptions caused by the member's fault.
3. The Company is not responsible for damages caused by the member's expectations of profits or losses incurred through the service.
4. The Company is not responsible for the reliability, accuracy, etc., of the information, data, and facts posted by the member on the service.

<b>Article 20 (Jurisdiction of the Court)</b>
1. In the event of a dispute arising from the use of the service, the court having jurisdiction over the location of the Company's head office shall be the competent court.
`;

export const PRIVACY_kr = `C&K Tour Ltd(이하 'C&K투어는')는 고객의 개인정보보호와 정보주체자의 권익보장을 위해 최선의 노력을 다하고 있습니다. C&K투어는 『개인정보보호법』 관련조항과 『정보통신망 이용촉진 및 정보보호에 관한 법률』의 기준에 따라 ‘개인정보처리방침’을 수립하여 이를 준수하고 있습니다. 고객의 개인정보가 어떠한 목적과 절차로 수집,이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 다음과 같이 알려드립니다. 본 개인정보처리방침을 개정하는 경우에는 C&K투어는 웹사이트를 통하여 사전에 고지 하겠습니다.

1. 개인정보 수집 및 이용목적
(1) C&K투어는 여행상품의 예약 및 여행관련 서비스 제공 등의 업무처리를 위하여 고객으로부터 최소한의 필수정보를 수집하며, 제공한 모든 개인정보는 별도의 동의가 없는 한 해당목적 이외의 다른 목적으로 사용하지 않습니다.
(2) C&K투어는 개인정보의 수집항목, 이용목적, 용도 등이 변경될 경우 사전 고지하며, 고객의 동의를 구할 것입니다.
(3) C&K투어는 수집한 개인정보를 다음의 목적을 위하여 사용합니다.
가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
여행상품 예약, 여행자보험 가입, 항공권/호텔 등의 예약 및 출입국 정보확인, 예약내역의 확인 및 상담, 국내 해외 여행서비스 이행, 컨텐츠 제공, 여행서비스 이용 시 회원의 우대, 내부방침 상 반복된 악성민원 제기로 인한 이용자 본인확인 및 서비스 제한 / 조회 / 사용 및 이에 관한 안내, 구매 및 요금결제, 물품배송 또는 청구서 등 발송, 금융서비스 등
나. 회원관리
회원제 서비스 이용을 위한 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 이용 및 이용횟수 제한, 연령확인, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 분쟁조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달 등

2. 개인정보 수집 항목 및 수집방법
(1) C&K투어는 적법한 절차와 법적 기준에 의거하여 고객의 개인정보를 수집하고 있으며, 고객의 서비스이용에 필요한 최소한의 정보만을 수집하고 있습니다. 정보통신망법과 개인정보보호법에 의거하여 수집, 이용을 제한하고 있습니다.
(2) C&K투어는 고객의 인권을 침해할 우려가 있는 아래와 같은 민감한 개인정보항목(성향, 성생활정보 등)은 수집하지 않습니다. 그러나, 당사의 중요서비스인 여행서비스 진행을 위해 의학적 도움이 필요한 고객의 경우, 또는 고객이 자발적으로 제공한 경우에 한하여 아래의 정보를 수집하고 있습니다. 
ㆍ 수집 항목 : 건강상황(일정 중 발생되는 질병/상해 정보 포함), 섭취불가/비선호 음식(알러지 등) 정보
(3) C&K투어에서 운영하는 홈페이지(cnktour.com)에 회원가입, C&K투어 상품을 판매하는 각 지사에서 고객가입신청서 작성, 여행상품예약, 홈페이지 또는 각 지사에서 경품 행사 응모, 상담게시판, 전화, 팩스를 통한 본인 확인, 제휴사로부터의 제공 등으로 개인정보를 수집합니다.
(4) 회원서비스 및 여행서비스 제공을 위해 수집하는 상세 개인정보 항목, 용도, 보유 및 이용기간은 서비스가 지속가능한 기간 동안 계속 보유합니다.

※ 원치않을 경우, 여행서비스종료 후 개인정보 삭제 신청을 통해 즉시 삭제 가능합니다.
* 일부 서비스에서는 특화된 여러 기능을 제공하기 위해, 위의 수집정보 외 추가적인 개인정보를 수집할 수 있습니다.

3. 개인정보의 이용 및 보유기간
(1) 개인정보 이용기간
고객님의 개인정보는 서비스를 제공하는 기간 또는 분쟁처리 기간 동안에 한하여 보유하며 정보주체자의 삭제 요구와 가입 탈퇴 동의가 있을 경우 지체없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우는 아래와 같이 관계법령에서 정한 일정기간 동안 회원 정보를 안전하게 보관합니다.
가. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
나. 대금결재 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)

(2) 개인정보 유효기간 제도 준행
가. C&K투어는 개인정보 유효기간제도를 준행하며, 해당 정보통신 서비스를 1년 동안 이용하지 않은 이용자의 개인정보를 보호하기 위하여 파기 등의 필요한 조치를 취하여 개인정보의 오, 남용 및 유출을 방지하고 있습니다.
나. C&K투어는 1년 의 유효기간 만료가 도래하기 30일 전까지, C&K투어는의 보안규정 및 지침에 따라 이용자의 개인정보항목이 파기 되거나 보관이 필요 할 경우 관련 법령에 따라 안전하게 분리 저장, 관리되는 사실을 고지합니다.

4. 개인정보 제3자 제공에 관한 사항
C&K투어는 회원고객의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고 어떠한 경우에도 ‘개인정보의 수집 및 이용목적’ 에서 고지한 범위를 넘어서거나, 해당되는 회원 서비스 영역과 무관한 타 기업/기관에 제공하거나 이용하지 않습니다. 단, 고객 서비스 수행에 직접적으로 관계되는 업체에 한해서 제한적인 조건으로 개인정보가 제공됩니다.

※ 다음의 경우에는 예외적으로 고객의 동의없이 개인정보를 제공할 수 있습니다.
- 형사소송법, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있는경우
- 통계작성 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 연구단체 등에 제공하는 경우

5. C&K투어에서 고객의 개인정보를 위탁하는 업무 내용 및 현황
(1) C&K투어는 고객편의 서비스를 원활하게 제공하기 위해서 일부 업무를 전문업체에 위탁 운영하고 있습니다. 
(2) C&K투어는 개인정보 보호의 만전을 기하기 위하여 서비스 제공자의 개인정보 처리위탁관련 적법한 처리절차, 보안지시엄수, 개인정보에 관한 비밀 유지, 업무 목적 및 범위를 벗어난 사용의 제한, 재위탁 제한 등 사고시의 손해배상 책임부담을 명확히 규정하고 해당 계약내용을 서면 또는 전자적으로 보관하여 이를 엄격하게 관리감독 하고 있습니다.
(3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개합니다.
(4) C&K투어에서 고객의 개인정보를 위탁하는 현황은 다음과 같습니다.
- C&K투어 홈페이지 및 모바일을 통한 항공권 예약/결제
- 국내 외 랜드사
- Stripe
- 신용카드 결제승인, 판매내역 자동입금 서비스
- 시스템 유지보수 업체
- 홈페이지, 시스템 개발 및 유지보수

6. 개인정보의 자동 수집 장치에 관한 사항
C&K투어는 이용자의 개인화된 서비스를 제공하기 위하여 '쿠키(cookie)'를 사용하기도 합니다. ‘쿠키(Cookie)’란? 웹사이트가 이용자의 컴퓨터 브라우저(넷스케이프, 인터넷익스플로러 등)로 전송하는 소량의 정보로서 이용자가 웹사이트에 로그인(log-in)하면 서버는 고객의 브라우저에 있는 쿠키의 내용을 읽고, 고객의 추가정보를 고객 본인의 컴퓨터에서 찾아 서비스를 제공하게 됩니다. 이러한 쿠키 정보는 이용자께 유용하고 보다 편리한 맞춤 서비스를 제공하는데 사용되게 됩니다. 다음과 같은 목적을 위해 쿠키를 사용합니다.
(1) 회원으로 등록하면, 처음 로그인할 때 쿠키가 설정되며, 로그아웃 하면 삭제됩니다. 회원 로그인과 관련하여 쿠키가 사용되는 것은 현재 로그인 상태인지를 확인하기 위하여 사용됩니다.
(2) 사용자 환경 설정 등에서도 쿠키를 사용합니다. 개인이 직접 선택한 검색 사용환경 값을 저장해 두었다가 사용자가 필요로 하는 환경 설정을 위하여 이 정보를 사용합니다.
(3) 회원과 비회원의 접속 빈도의 차이나 반복 사용 정도 등 이용자의 이용 행동에 관한 정보를 파악하여 타겟 마케팅에 사용합니다.
(4) 쿠키는 C&K투어는가 진행하는 이벤트 등과 같은 각종 프로모션 행사 등에서 고객의 참여 빈도수 확인 등을 위하여 사용됩니다.
(5) 이용자는 웹 브라우저의 옵션을 조정하여 모든 쿠키를 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있습니다. 그 방법은 아래와 같습니다.
가. Internet Explorer를 사용하는 경우
- [도구] 메뉴에서 [인터넷옵션]을 클릭합니다.
- [개인정보]탭을 클릭합니다.
- 개인정보 설정수준을 사용자 조정하시면 됩니다.
나. Chrome 를 사용하는 경우
- […] 메뉴에서 [설정]을 클릭합니다.
- [고급]을 누른 후 [개인정보 및 보안] 카테고리에서 [콘텐츠 설정]를 클릭 후 [쿠키]를 클릭합니다.
- 원하시는 쿠키설정을 선택하시면 됩니다.

7. 개인정보 주체의 권리와 의무
개인정보주체는 C&K투어에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.
1) 개인정보 열람요구(정보주체 및 정보주체 이외로 부터 수집된 개인정보의 출처, 처리목적, 이용내역에 대한 열람)
2) 오류 등이 있을 때 정정요구
3) 개인정보 삭제요구
4) 처리정지 요구
상기 항목에 대한 권리행사는 C&K투어에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, C&K투어는 이에 대해 지체없이 조치하겠습니다.
정보주체자는 개인정보보호법 등 관계법령을 위반하는 수준으로, C&K투어가 처리하고 있는 정보주체 본인 및 타인의 개인정보 및 사생활을 침해하지 않도록 해야 할 의무가 있습니다.

8. 개인정보의 열람 및 정정, 삭제방법
고객님의 개인정보 열람 및 정정을 위해서는 C&K투어 웹사이트(cnktour.com)에 접속 후, 마이페이지의 내 정보관리를 클릭하여 열람 또는 정정하실 수 있습니다 또한 C&K투어 고객센터 및 개인정보관리 담당자에게 이메일, 전화 등을 이용하여 본인 개인정보의 열람,정정,삭제를 요청할 수 있으며, 이 경우 본인여부 확인 후 필요한 조치를 취해드립니다. 회원/고객의 개인정보 정정요구에 대하여 C&K투어는 해당 내용에 대하여 정정 완료 전까지는 개인정보를 이용, 제공하지 않습니다.
* C&K투어 고객 개인정보 열람.정정.삭제 요청방법
개인정보 열람, 정정, 삭제, 처리정지 요청서를 작성하셔서 이메일(info@cnktour.com)로 발송 해주시고 C&K투어 상담센터로 문의해주시면 본인확인절차를 거친 후 신속하게 처리해 드립니다.

9. 개인정보 수집/이용/제공에 대한 동의철회
고객님은 회원가입시 개인정보 수집,이용,제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 동의 철회는 C&K투어에서 운영하는 웹사이트(cnktour.com)에 로그인후 마이페이지 메뉴를 통해서 동의철회(가입탈퇴)를 신청하실수 있고, C&K투어의 고객센터 및 개인정보 담당자에게 서면/이메일 등으로 송부하여 주시면 신속하게 조치해 드립니다.

10. 만14세 미만 아동의 개인정보 보호
만 14세 미만 아동의 경우, 법정대리인(부모, 법정대리인, 친권자)을 통하여 여행상품/서비스 이용계약이 가능하며, 해당 서비스 수행에 필요한 최소한의 개인정보를 수집합니다. 만14세 미만 아동의 법정대리인은 아동의 개인정보 열람, 정정을 요구하시거나, 개인정보의 수집과 이용,제공, 위탁에 대한 동의철회를 하실 수 있습니다. 만 14세 미만 아동의 개인정보는 법정대리인의 동의 없이는 제3자에게 제공하거나 공유되지 않습니다.
 
11. 개인정보보호를 위한 기술적 / 관리적 안전성 확보조치
(1). 기술적 대책
- 고객님의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화 하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
- C&K투어는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
- C&K투어는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고 있습니다.
- 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.

(2). 관리적 대책
C&K투어는 고객님의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.

- 여행상품 예약/결제, 서비스 이행 계약 등 관련 업무를 수행하는 자
- 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자
- 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자
- 기타 업무상 개인정보의 처리가 불가피한 자
- 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.
- 입사시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
- 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.
- 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의 서버를 통해 분리하여 보관하고 있습니다.
- C&K투어는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
- 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.

12. 개인정보 파기관리
C&K투어는 수집한 개인정보의 이용목적이 달성된 후에는 이용 및 보관기간에 따라 해당 정보를 지체 없이 파기하며 파기절차 및 방법은 다음과 같습니다.
가. 파기절차
고객님이 회원가입 및 여행서비스 이용 등을 위해 기재한 개인정보는 이용목적이 달성된 후 내부방침 및 기타 관련 법령에 의한 정보보호사유에 따라(이용 및 보유기간 참조) 일정기간 동안 저장된 후 삭제되거나 파기합니다.
나. 파기방법
- 종이에 출력된 개인정보: 분쇄기를 이용하여 분쇄
- 전자적파일형태로 저장된 개인정보: 개인정보는 남기지 않으며, 기록을 재생할수 없는 방법을 통하여 기록삭제
다. 파기시점
- 회원가입정보의 경우 : 회원가입을 탈퇴하거나 회원에서 제명된 때.
- 대금지급정보의 경우 : 대금의 완제일 또는 채권소멸 시효기간이 만료된 때
- 설문조사,이벤트 등의 목적을 위하여 수집한 경우 : 당해 설문조사,이벤트 등이 종료한 때
다만, 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 회원의 개인정보를 보유할 수 있습니다.

13. 개인정보관련 의견수렴
개인정보보호 관련 정책 문의 및 고객님의 기타 의견이 있을 경우 전자메일 및 팩스 수신처는 다음과 같습니다.
- 전자우편 : info@cnktour.com
- 전화번호 : 403-561-4038
- 주소 :  121 Candle Place Southwest, Calgary, AB
개인정보 침해 및 신고 상담이 필요하신 경우에는 아래의 외부 기관에 문의를 통해서 확인 받으실 수 있습니다.

○ 개인정보 분쟁 조정 위원회
- 전화 : (국번없이) 118
- URL : https://kopico.go.kr

○ 개인정보 침해신고 센터
- 전화 : (국번없이) 118
- URL : http://privacy.kisa.or.kr

○ 대검찰청 사이버범죄수사단
- 전화 : 02-3480-3571
- 이메일 : cybercid@spo.go.kr

○ 경찰청 사이버안전국
- 전화 : (국번없이) 182
- URL : http://cyberbureau.police.go.kr
 
14. 개인정보처리방침 변경 등에 대한 고지
개인정보처리방침내용의 추가, 삭제 및 수정이 있을 시에는 개정내용이 반영되기 전에 웹 사이트의 공지사항을 통하여 고지할 것입니다. 또한 개인정보 처리방침에 버전번호 및 개정일자 등을 부여하여 개정여부를 쉽게 알 수 있도록 하며, 중요한 사항일 경우, 고객의 이메일로 안내사항을 전달해 드리도록 하겠습니다.
개인정보처리방침 버전번호 1.0
개인정보처리방침 시행일자 2023년 12월 01일
개인정보처리방침 최종변경일자 2023년 12월 01일

15. 개인정보 관리책임자 연락처
C&K투어는 고객의 개인정보를 안전하게 보호하고 개인정보와 관련된 불만 및 민원사항을 처리하기 위하여 개인정보 관리책임 조직을 지정하고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 담담자에게 연락주시기 바랍니다. 기준에 따라 성실하고 신속하게 답변드리도록 하겠습니다.
E-mail : info@cnktour.com`;

export const PRIVACY_ca = `C&K Tour Ltd (hereinafter referred to as 'C&K Tour') strives to make the best efforts to protect the personal information of customers and ensure the rights of information subjects. C&K Tour establishes and complies with a 'Privacy Policy' based on the provisions of the 'Personal Information Protection Act' and the standards of the 'Act on Promotion of Information and Communication Network Utilization and Information Protection.' We will inform you of how customer's personal information is collected, used, and what measures are taken to protect personal information for privacy. In the event of amending this privacy policy, C&K Tour will provide prior notice through the website.

1. Purpose of Collection and Use of Personal Information
(1) C&K Tour collects the minimum necessary information from customers for processing business such as reservation of travel products and providing travel-related services. All provided personal information will not be used for purposes other than the intended purpose without separate consent.
(2) In case of changes in the collection items or purposes of personal information, C&K Tour will notify in advance and seek customer consent.
(3) C&K Tour uses the collected personal information for the following purposes:
a. Contract performance and fee settlement related to service provision
b. Membership management for user identification, prevention of unauthorized use by fraudulent members, confirmation of intent to join, age verification, handling of complaints, delivery of notices, etc.

2. Items and Methods of Personal Information Collection
(1) C&K Tour collects customer's personal information through legal procedures and standards based on relevant laws. Only the minimum information necessary for customer service is collected, and collection and use are restricted in accordance with the Information and Communication Network Act and the Personal Information Protection Act.
(2) C&K Tour does not collect sensitive personal information that may infringe on the customer's rights (such as preferences, sexual information, etc.). However, for customers who require medical assistance for the important service of travel services or voluntarily provide information, the following information is collected:

Collection items: Health status (including information on diseases/injuries that occur during the itinerary), information on foods that cannot be consumed/preferred (allergies, etc.).

3. Use and Retention Period of Personal Information
(1) The use period of personal information is limited to the period of providing the service or the dispute processing period, and information will be destroyed without delay if the information subject requests deletion and agrees to withdraw from membership. However, in cases where it is necessary to retain in accordance with relevant laws, the information will be securely stored for a certain period.
Record of contract or withdrawal: 5 years (Consumer Protection Act in E-commerce)
Record of payment and supply of goods: 5 years (Consumer Protection Act in E-commerce)
Record of consumer complaints or dispute resolution: 3 years (Consumer Protection Act in E-commerce)
(2) Compliance with the Effective Period System for Personal Information

C&K Tour adheres to the personal information effective period system and takes necessary measures, including destruction, to protect the personal information of users who have not used the service for one year.
C&K Tour notifies users 30 days before the expiration of the one-year effective period, and if personal information needs to be retained after the expiration, it is clearly specified in accordance with related laws.

4. Matters regarding the Provision of Personal Information to Third Parties
C&K Tour does not provide personal information beyond the scope notified in the 'Purpose of Collection and Use of Personal Information' unless customer consent or legal regulations are involved. However, personal information may be provided without customer consent in the following cases:
When requested by investigative agencies for investigation purposes under related laws such as the Criminal Procedure Act, the Law on Real-Name Financial Transactions, and Confidentiality, and the Credit Information Use and Protection Act.
When providing to research organizations for statistical research or academic research in a form that cannot identify specific individuals.

5. Outsourcing of Personal Information Processing by C&K Tour
(1) C&K Tour outsources some tasks to specialized companies to provide convenient services to customers.
(2) C&K Tour strictly manages and supervises outsourced tasks by clearly defining legal processing procedures, security instructions and obligations to maintain confidentiality of personal information, and restrictions on the use beyond the scope of business purposes, and responsibility for damage compensation in case of accidents.
(3) If the content of the outsourced task or the entrusted company changes, it will be promptly disclosed through this privacy policy.
(4) The current status of outsourcing personal information by C&K Tour is as follows:

Reservation/payment of air tickets through C&K Tour website and mobile.
Land agencies in Korea and abroad.
Stripe: Credit card payment approval, automatic deposit service for sales history.
Maintenance companies for websites and systems.

6. Information about Automatic Collection Devices for Personal Information
C&K Tour uses 'cookies' to provide personalized services to users. A 'cookie' is a small amount of information sent by a website to a user's computer browser (Netscape, Internet Explorer, etc.). When a user logs in to a website, the server reads the contents of the cookie in the user's browser and provides services by finding additional information on the user's computer. This cookie information is used to provide users with useful and convenient customized services. Cookies are used for the following purposes:
(1) When you register as a member, a cookie is set the first time you log in and deleted when you log out. The use of cookies in relation to member logins is to verify the current login status.
(2) Cookies are used in user environment settings. Information about the user's selected search environment values is stored to provide the user with the desired environment settings.
(3) Cookies are used to grasp information about user behavior, such as the difference in connection frequency between members and non-members or the frequency of repeated use, and are used for target marketing.
(4) Cookies are used for various promotions such as events conducted by C&K Tour to confirm the frequency of customer participation.
(5) Users can adjust their web browser options to accept all cookies, send a notification when cookies are set, or reject all cookies. The method is as follows:
If you are using Internet Explorer:
Click 'Internet Options' from the 'Tools' menu.
Click on the 'Privacy' tab.
Adjust the privacy level according to user preference.
If you are using Chrome:
Click 'Settings' from the [...] menu.
After clicking 'Advanced', click 'Content settings' in the 'Privacy and security' category.
Click 'Cookies'.
Select the desired cookie settings.

7. Rights and Obligations of Data Subjects
Individuals have the right to exercise the following personal information protection-related rights at any time with respect to C&K Tour:
Request for Access to Personal Information: The right to inspect personal information, including the source of information collected from the data subject and others, the purpose of processing, and usage history.
Correction Request in Case of Errors: The right to request correction when errors are identified.
Request for Deletion of Personal Information: The right to request the deletion of personal information.
Request for Suspension of Processing: The right to request the suspension of processing.
The exercise of these rights can be done through written requests, phone calls, emails, facsimile (FAX), or other means. C&K Tour will promptly take necessary actions upon receiving such requests. Data subjects are obligated to ensure that C&K Tour processes personal information, both their own and others', in compliance with the Personal Information Protection Act and other relevant laws.

8. Method for Accessing and Correcting Personal Information
To access or correct personal information, customers can log in to the C&K Tour website (cnktour.com), go to "My Page," and manage their information. Alternatively, customers can request access, correction, or deletion of personal information via email, phone, or by contacting C&K Tour's customer center and the personal information manager. C&K Tour will promptly handle requests after verifying the customer's identity. Until corrections are completed for member/customer correction requests, C&K Tour will not use or provide personal information.
Method for Requesting Access, Correction, Deletion, or Suspension of Processing of Personal Information by C&K Tour Customers:
Customers should submit a request for access, correction, deletion, or suspension of processing of personal information via email (info@cnktour.com). Contacting the C&K Tour customer service center will assist in completing the process promptly after confirming the customer's identity.

9. Withdrawal of Consent for the Collection/Use/Provision of Personal Information
Customers have the right to withdraw their consent for the collection, use, and provision of personal information at any time. Consent withdrawal can be requested through the C&K Tour website (cnktour.com) by logging in and applying for consent withdrawal (membership withdrawal) through the My Page menu. Alternatively, customers can submit a written request via email or mail to C&K Tour's customer center and the personal information manager, and C&K Tour will promptly take action after confirming the customer's identity.

10. Protection of Personal Information for Children Under 14 Years Old
For children under 14 years old, travel product/service contracts can be made through legal representatives (parents, legal guardians, custodians). Only the minimum necessary personal information for performing the service is collected. The legal representative of a child under 14 years old can request access or correction of the child's personal information and withdraw consent for the collection, use, provision, and outsourcing of personal information. Personal information of children under 14 years old will not be provided or shared with third parties without the consent of the legal representative.

11. Measures for Technical/Managerial Security to Ensure Personal Information Protection
(1). Technical Measures
Customer's personal information is protected by passwords, and important data is further secured using encryption or file lock functions (Lock).
C&K Tour adopts security devices (SSL or SET) to securely transmit personal information over the network using encryption algorithms.
C&K Tour takes measures to prevent damage from computer viruses by using regularly updated antivirus programs.
Security measures, including intrusion prevention systems and vulnerability analysis systems, are implemented on each server to prepare for external intrusion such as hacking.
(2). Managerial Measures
C&K Tour limits access to customer's personal information to the minimum necessary personnel.
Regular in-house training and external delegated training on security technology acquisition and compliance with personal information protection obligations are conducted.
Security oaths from all employees are obtained at the time of employment to prevent information leakage by human error.
Internal procedures are established to audit compliance with the personal information processing policy.
Personal information and general data are stored separately on dedicated servers.
C&K Tour is not responsible for individual mistakes or risks of the basic internet that may occur.
In the event of a loss, leakage, alteration, or damage to personal information due to internal administrator errors or technical management accidents, C&K Tour will immediately notify you of the facts and take appropriate measures and compensation.

12. Management of Personal Information Destruction
C&K Tour promptly destroys collected personal information after the purpose of use is achieved, following the information protection reasons under internal policies and other relevant laws. The destruction process and method are as follows:
A. Destruction Process
Personal information provided by customers for membership registration and travel service usage is stored for a certain period according to the purpose of use and retention period, and then it is deleted or destroyed in accordance with internal policies and other relevant laws.
B. Destruction Method
Personal information printed on paper: Shredded using a shredder.
Personal information stored in electronic file format: Deleted in a way that cannot be reproduced by using an irreversible method.
C. Destruction Timing
In the case of membership registration information: When withdrawing from membership or being expelled from membership.
In the case of payment information: On the completion date of the payment or when the statute of limitations for debt extinction has expired.
In the case of information collected for surveys, events, etc.: When the survey, event, etc., ends.
However, even if the purpose of collection or the provision purpose is achieved, personal information may be retained if there is a need to retain it under the provisions of commercial law and other laws.

13. Collection of Opinions on Personal Information
If you have inquiries about the privacy policy or any other opinions, please contact the following email and fax reception points.

Email: info@cnktour.com
Phone: 403-561-4038
Address: 121 Candle Place Southwest, Calgary, AB
If you need consultation for personal information infringement and reporting, you can contact the following external organizations:

○ Personal Information Dispute Mediation Committee

Phone: 118 (without area code)
URL: https://kopico.go.kr
○ Personal Information Infringement Report Center

Phone: 118 (without area code)
URL: http://privacy.kisa.or.kr
○ Cyber Crime Investigation Department, Supreme Prosecutors' Office

Phone: 02-3480-3571
Email: cybercid@spo.go.kr
○ Cyber Safety Bureau, National Police Agency

Phone: 182 (without area code)
URL: http://cyberbureau.police.go.kr

14. Notification of Changes to the Privacy Policy
If there are additions, deletions, or modifications to the contents of the privacy policy, they will be announced through the notice on the website before the revised content is reflected. Furthermore, to easily identify the revision status, a version number and revision date will be assigned to the privacy policy, and in the case of important matters, notifications will be sent to customers via email.

Privacy Policy Version 1.0
Effective Date: December 01, 2023
Last Revised: December 01, 2023

15. Contact Information for the Personal Information Management Officer
C&K Tour is committed to securely protecting customer's personal information and addressing complaints and inquiries related to personal information. If you have any questions regarding personal information or related matters, please contact the designated personal information management officer. We will provide sincere and prompt responses based on established standards.
E-mail: info@cnktour.com
`;

export const SIGNUPEMAIL_kr = `
<!DOCTYPE html>
<html>
<head>
    <title>이메일 인증</title>
    <style>
        .container {
            background-color: #EEE;
        }
        .subContainer {
            width: 50em;
            margin: auto;
            background-color: white;
        }
        .header {
            background-color: #dc4756;
            align-items: center;
            padding: 15px 0 11px 15px;
        }
        .link {
          display:inline-block;
          padding: 10px;
          background-color: #dc4756;
          border-radius: 3px;
        }
        .link a {
          color: white;
          text-decoration: none;
        }
        .footer {
            margin-top: 30px;
            background-color: rgb(73, 73, 73);
            padding: 5px 10px 5px 0;
            color: white;
            text-align: right;
            font-size: 0.8em;
        }
        .header a, .footer p, .footer a {
            color: white !important;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        .footer p {
           line-height: 70%;
        }
        .center {
            text-align: center;
        }
        .subContainer p {
            padding: 0 10px;
        }
        .link {
         display:inline-block;
         padding: 10px;
         background-color: #dc4756;
         border-radius: 3px;
       }
       .link a {
         color: white;
         text-decoration: none;
       }
       .linkDiv {
         text-align: center;
       }
    </style>
</head>
<body>
<div class="container">
<div class="subContainer">
    <div class="header">
        <img src="https://www.cnktour.com/bgImg/CNKTourLogo.png" alt="Logo">
    </div>
      <div class="title">이메일을 인증해주세요.</div>
      <p>아래 "이메일 확인"을 클릭하면 회원가입이 완료됩니다.</p>
      <div class="linkDiv"><span class="link"><a href="https://www.cnktour.com/verify/?email={0}&code={1}">이메일 확인</a></span></div>
      <div class="footer">
        <p>Alberta 사업자 등록번호: 712277813</p>
        <p>121 Candle Place Southwest, Calgary, AB</p>
        <p>© {2} <a href="https://www.cnktour.com">C&K 투어</a></p>
      </div>
</div>
</div>
</body>
</html>`;

export const SIGNUPEMAIL_ca = `
<!DOCTYPE html>
<html>
<head>
    <title>Verify</title>
    <style>
        .container {
            background-color: #EEE;
        }
        .subContainer {
            width: 50em;
            margin: auto;
            background-color: white;
        }
        .header {
            background-color: #dc4756;
            align-items: center;
            padding: 15px 0 11px 15px;
        }
        .link {
          display:inline-block;
          padding: 10px;
          background-color: #dc4756;
          border-radius: 3px;
        }
        .link a {
          color: white;
          text-decoration: none;
        }
        .footer {
            margin-top: 30px;
            background-color: rgb(73, 73, 73);
            padding: 5px 10px 5px 0;
            color: white;
            text-align: right;
            font-size: 0.8em;
        }
        .header a, .footer p, .footer a {
            color: white !important;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        .footer p {
           line-height: 70%;
        }
        .center {
            text-align: center;
        }
        .subContainer p {
            padding: 0 10px;
        }
        .link {
         display:inline-block;
         padding: 10px;
         background-color: #dc4756;
         border-radius: 3px;
       }
       .link a {
         color: white;
         text-decoration: none;
       }
       .linkDiv {
         text-align: center;
       }
    </style>
</head>
<body>
<div class="container">
<div class="subContainer">
    <div class="header">
        <img src="https://www.cnktour.com/bgImg/CNKTourLogo.png" alt="Logo">
    </div>
    <div class="title">Please verify your email.</div>
    <p>Click "Verify Email" below to complete your registration.</p>
    <div class="linkDiv"><span class="link"><a href="https://www.cnktour.com/verify/?email={0}&code={1}">Verify Email</a></span></div>
    <div class="footer">
        <p>Alberta Business Registration Number: 712277813</p>
        <p>121 Candle Place Southwest, Calgary, AB</p>
        <p>© {2} <a href="https://www.cnktour.com">C&K Tour</a></p>
    </div>
</div>
</div>
</body>
</html>`;

export const INQUIRE_EMAIL_kr = `
<!DOCTYPE html>
<html>
<head>
    <title>문의하기</title>
    <style>
        .container {
            background-color: #EEE;
        }
        .subContainer {
            width: 50em;
            margin: auto;
            background-color: white;
        }
        .header {
            background-color: #dc4756;
            align-items: center;
            padding: 15px 0 11px 15px;
        }
        .link {
          display:inline-block;
          padding: 10px;
          background-color: #dc4756;
          border-radius: 3px;
        }
        .link a {
          color: white;
          text-decoration: none;
        }
        .footer {
            margin-top: 30px;
            background-color: rgb(73, 73, 73);
            padding: 5px 10px 5px 0;
            color: white;
            text-align: right;
            font-size: 0.8em;
        }
        .header a, .footer p, .footer a {
            color: white !important;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        .footer p {
           line-height: 70%;
        }
        .center {
            text-align: center;
        }
        .subContainer p {
            padding: 0 10px;
        }
    </style>
</head>
<body>
<div class="container">
  <div class="subContainer">
      <div class="header">
          <img src="https://www.cnktour.com/bgImg/CNKTourLogo.png" alt="Logo">
      </div>
        <div class="title">아래 문의가 정상적으로 접수되었습니다.</div>
        <p>{0}</p>
        <div class="center"><a href="https://www.cnktour.com">https://cnktour.com</a></div>
        <div class="footer">
          <p>Alberta 사업자 등록번호: 712277813</p>
          <p>121 Candle Place Southwest, Calgary, AB</p>
          <p>© {1} <a href="https://www.cnktour.com">C&K 투어</a></p>
        </div>
  </div>
</div>
</body>
</html>`;

export const INQUIRE_EMAIL_ca = `
<!DOCTYPE html>
<html>
<head>
    <title>Inquire</title>
    <style>
        .container {
            background-color: #EEE;
        }
        .subContainer {
            width: 50em;
            margin: auto;
            background-color: white;
        }
        .header {
            background-color: #dc4756;
            align-items: center;
            padding: 15px 0 11px 15px;
        }
        .link {
          display:inline-block;
          padding: 10px;
          background-color: #dc4756;
          border-radius: 3px;
        }
        .link a {
          color: white;
          text-decoration: none;
        }
        .footer {
            margin-top: 30px;
            background-color: rgb(73, 73, 73);
            padding: 5px 10px 5px 0;
            color: white;
            text-align: right;
            font-size: 0.8em;
        }
        .header a, .footer p, .footer a {
            color: white !important;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
        }
        .footer p {
           line-height: 70%;
        }
        .center {
            text-align: center;
        }
        .subContainer p {
            padding: 0 10px;
        }
    </style>
</head>
<body>
<div class="container">
  <div class="subContainer">
      <div class="header">
          <img src="https://www.cnktour.com/bgImg/CNKTourLogo.png" alt="Logo">
      </div>
      <div class="title">Your inquiry has been successfully submitted.</div>
      <p>{0}</p>
      <div class="center"><a href="https://www.cnktour.com">https://cnktour.com</a></div>
      <div class="footer">
          <p>Alberta Business Registration Number: 712277813</p>
          <p>121 Candle Place Southwest, Calgary, AB</p>
          <p>© {1} <a href="https://www.cnktour.com">C&K Tour</a></p>
      </div>
  </div>
</div>
</body>
</html>`;
