import React, { useState } from "react";
import {
    FaFacebook,
    FaInstagram,
    FaPhone,
    FaEnvelope,
    FaCopyright,
} from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { HiMiniBuildingOffice } from "react-icons/hi2";
import styles from "./index.module.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import KakaoLoginButton from "../../components/Kakao";
import Interact from "img/Interact.png";
import Flag from "react-world-flags";
import { Link } from "react-router-dom";
const { siteUrl } = require("../../config");

const Footer = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const bankInfo = {
        beneficiaryBank: "KEB Hana Bank Canada",
        beneficiaryBankAddress:
            "1222-11th Avenue SW, suite 100 Calgary, AB T3C 0M4",
        beneficiaryName: "C & K TOUR LTD.",
        beneficiaryAddress: "121 CANDLE PL SW CALGARY AB T2W5R7",
        transitNumber: "00500",
        institutionCode: "275",
        accountNumber: "69010003380",
        swiftBIC: "KOEXCATT",
    };

    const RBCBankInfo = {
        beneficiaryBank: "Royal Bank of Canada",
        beneficiaryName: "C & K TOUR LTD.",
        beneficiaryAddress: "121 CANDLE PL SW CALGARY AB T2W5R7",
        accountType: "Chequing",
        accountNumber: "02299-1020767",
    };

    return (
        <div className={styles.background}>
            <div className={styles.footerTopBg}>
                <div className={styles.footerTop}>
                    <Link to={`/attention`}>{t("Attention")}</Link>
                    <span className={styles.arrow}>|</span>
                    <Link to={`/contact`}>{t("ContactUs")}</Link>
                    <span className={styles.arrow}>|</span>
                    <Link to={`/tandc`}>{t("TandC")}</Link>
                    <span className={styles.arrow}>|</span>
                    <Link to={`/privacy`}>{t("Privacy")}</Link>
                </div>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.left}>
                    <div className={styles.logoText}>
                        C<span className={styles.logoAnd}>&</span>K {t("Tour")}
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <div className={styles.socialLinks}>
                            <a
                                className={styles.icon}
                                href="https://www.instagram.com/candk_worldtour_immigration/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="C&amp;K Tour on Instagram"
                            >
                                <FaInstagram />
                            </a>
                            <a
                                className={styles.icon}
                                href="https://www.facebook.com/profile.php?id=100087392774575"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="C&amp;K Tour on Facebook"
                            >
                                <FaFacebook />
                            </a>
                            <KakaoLoginButton />
                        </div>
                    </div>
                    <div className={styles.phoneDiv}>{t("CEOPhone")}</div>
                    <span className={styles.phone}>
                        <FaPhone /> 403-561-4038
                    </span>
                    <span>
                        <HiMiniBuildingOffice /> 121 Candle Place Southwest,
                        Calgary, AB
                    </span>
                    <span>
                        <FaEnvelope />{" "}
                        <a href="mailto:info@cnktour.com">info@cnktour.com</a>
                    </span>
                    <span className={styles.bankInfoForMobile}>
                        <BsBank2 />{" "}
                        <a href="#" onClick={() => setOpen(true)}>
                            {t("AccountInfo")}
                        </a>
                    </span>
                    <div className={styles.busineseDiv}>
                        {t("BusineseInfo")}
                    </div>
                    <span>{t("LegalName")}: C & K TOUR LTD.</span>
                    <span>{t("ACAN")}: 2025666237</span>
                    <span>{t("BN")}: 712277813</span>
                    <span>
                        {t("Document")}:{" "}
                        <a
                            target="_blank"
                            href={`${siteUrl}/bgImg/CRABusinessNumber.pdf`}
                        >
                            {t("PDFDownload")}
                        </a>
                    </span>

                    <div style={{ marginTop: "90px" }}>
                        <FaCopyright /> C&K Tour Ltd. All rights reserved.
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.phoneDiv}>
                        {t("InteractAddress")}
                    </div>
                    <div className={styles.interactInfo}>
                        <img src={Interact} width="50px" alt="Interact" />
                        <p style={{ margin: "0" }}>{t("Interact")}</p>
                    </div>
                    <div className={styles.busineseDiv}>{t("AccountInfo")}</div>
                    <Flag
                        code="kr"
                        height="16"
                        style={{
                            marginBottom: "3px",
                        }}
                    />
                    {Object.entries(bankInfo).map(([key, value]) => (
                        <p key={key}>
                            <strong>{t(key)}:</strong> {value}
                        </p>
                    ))}
                    <Flag
                        code="ca"
                        height="16"
                        style={{
                            marginTop: "10px",
                            marginBottom: "3px",
                        }}
                    />
                    {Object.entries(RBCBankInfo).map(([key, value]) => (
                        <p key={key}>
                            <strong>{t(key)}:</strong> {value}
                        </p>
                    ))}
                </div>
            </div>
            <Modal centered show={open} onHide={handleClose}>
                <Modal.Body>
                    <div className={styles.modalBankContainer}>
                        <h4>{t("AccountInfo")}</h4>
                        <Flag
                            code="kr"
                            height="16"
                            style={{ border: "1px solid white" }}
                        />
                        {Object.entries(bankInfo).map(([key, value]) => (
                            <p key={key}>
                                <strong>{t(key)}:</strong> {value}
                            </p>
                        ))}
                        <br />
                        <Flag
                            code="ca"
                            height="16"
                            style={{ border: "1px solid white" }}
                        />
                        {Object.entries(RBCBankInfo).map(([key, value]) => (
                            <p key={key}>
                                <strong>{t(key)}:</strong> {value}
                            </p>
                        ))}
                        <button
                            className="myButton cancel right"
                            onClick={handleClose}
                        >
                            {t("Close")}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Footer;
