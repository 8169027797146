import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import validator from "validator";
import { AiTwotoneMail } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import { RiLockPasswordFill } from "react-icons/ri";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const SignUp = ({ setToken }) => {
    const { t } = useTranslation();
    const [setCookie] = useCookies(); // Use useCookies to manage cookies

    const email = useRef();
    const username = useRef();
    const password = useRef();

    const [error, setError] = useState("");
    const [emailValidity, setEmailValidity] = useState(false);
    const [usernameValidity, setUsernameValidity] = useState(false);
    const [passwordValidity, setPasswordValidity] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [showFailedAlert, setShowFailedAlert] = useState(false);

    const checkCorrectness = () => {
        setIsButtonDisabled(
            !(emailValidity && usernameValidity && passwordValidity)
        );
    };
    const isPasswordValid = () => {
        if (
            validator.matches(password.current.value, /^[\w\d!@#$%^&*]+$/) &&
            validator.isLength(password.current.value, { min: 3, max: 20 })
        ) {
            setPasswordValidity(true);
        } else {
            setPasswordValidity(false);
        }
        checkCorrectness();
    };

    const isUsernameValid = () => {
        if (
            validator.isAlphanumeric(username.current.value) &&
            validator.isLength(username.current.value, { min: 1, max: 50 })
        ) {
            setUsernameValidity(true);
        } else {
            setUsernameValidity(false);
        }
        checkCorrectness();
    };

    const isEmailValid = () => {
        if (validator.isEmail(email.current.value)) {
            setEmailValidity(true);
        } else {
            setEmailValidity(false);
        }
        checkCorrectness();
    };

    const onSubmit = async (event) => {
        try {
            event.preventDefault();
            const res = await axios.post(`/api/user`, {
                email: email.current.value,
                username: username.current.value,
                password: password.current.value,
            });

            if (res.status === 200) {
                const loginData = {
                    username: username.current.value,
                    password: password.current.value,
                    accessToken: res.data.accessToken,
                    isSuperUser: res.data.isSuperUser,
                    email: res.data.email,
                };
                setCookie("loginData", loginData, { maxAge: 70 * 3600 }); // 70 hours

                setToken(
                    username.current.value,
                    password.current.value,
                    res.data.accessToken,
                    res.data.isSuperUser,
                    res.data.email
                );
            } else {
                throw Error("failed");
            }
        } catch (error) {
            setError(error.response.data);
            setShowFailedAlert(true);
        }
    };

    useEffect(() => {
        checkCorrectness();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailValidity, usernameValidity, passwordValidity]);

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>
                    <AiTwotoneMail />
                    &nbsp;
                    <strong>{t("Email")}</strong>
                </Form.Label>
                <Form.Control
                    type="email"
                    placeholder=""
                    ref={email}
                    onChange={isEmailValid}
                    isValid={emailValidity}
                />
            </Form.Group>
            <Form.Group controlId="formBasicUsername">
                <Form.Label>
                    <BiSolidUser />
                    &nbsp;
                    <strong>{t("Username")}</strong>
                </Form.Label>
                <Form.Control
                    type="username"
                    placeholder=""
                    ref={username}
                    onChange={isUsernameValid}
                    isValid={usernameValidity}
                />
                <span style={{ fontSize: "0.8em" }}>{t("UsernameReq")}</span>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>
                    <RiLockPasswordFill />
                    &nbsp;<strong>{t("Password")}</strong>
                </Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    ref={password}
                    onChange={isPasswordValid}
                    isValid={passwordValidity}
                />
                <span style={{ fontSize: "0.8em" }}>{t("PasswordReq")}</span>
            </Form.Group>
            {showFailedAlert && (
                <Alert
                    variant="danger"
                    onClose={() => {
                        setShowFailedAlert(false);
                    }}
                    dismissible
                >
                    {t("RegFailed")} {error.msg}
                </Alert>
            )}
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    disabled={isButtonDisabled}
                    className={
                        isButtonDisabled
                            ? "myButton submit disabled"
                            : "myButton submit"
                    }
                >
                    {t("Signup")}
                </Button>
            </div>
        </Form>
    );
};

SignUp.defaultProps = { setToken: () => {} };

export default SignUp;
