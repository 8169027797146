import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styles from "./index.module.css";
import noImage from "../../../img/noImage.gif";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../utility/utility";
import DiscountedImg from "../../../img/sale.svg";
import Review from "../../Review";
const { supportedLang } = require("../../../config");

const { forumNameConfig, firebaseBaseUrl } = require("../../../config");

const ForumList = ({ isLoading, forums }) => {
    const { t } = useTranslation();
    let langCode = useSelector((state) => state.app.lang);
    let forumCategoryId = useSelector((state) => state.app.forumCategoryId);

    const generateGridItems = (forums, langCode, condition) => {
        //console.log("forums:" + JSON.stringify(forums));
        return forums
            .filter((forum) => condition(forum.status))
            .map((forum) => {
                const mainImageUrl = forum.mainImageUrl
                    ? firebaseBaseUrl.replace("{0}", forum.mainImageUrl)
                    : noImage;

                const cellWidth = {
                    xs: 12,
                    sm: 6,
                    md: 3,
                };

                let priceContent = formatPrice(
                    forum.price,
                    forum.status,
                    forum.discount,
                    t("Inquire")
                );

                // Generate link
                let engName = forum.name.find(
                    (item) => item.lang === "ca"
                )?.txt;

                let nameLink = engName
                    ? engName.replace(/[^a-zA-Z0-9]/g, "_")
                    : "";
                nameLink = nameLink + "-" + forum._id + "-" + langCode;

                let modifiedName = forum.name.find(
                    (item) => item.lang === langCode
                )?.txt;

                const desc = forum.description.find(
                    (item) => item.lang === langCode
                )?.txt;

                const cellClassName =
                    forum.status === 0
                        ? `${styles.cell} ${styles.blurred}`
                        : styles.cell;

                const DISCOUNTED_PRODUCT = 3;

                return (
                    <Grid item {...cellWidth} key={forum._id}>
                        {supportedLang.map((lang) => (
                            <Link
                                key={`${forum._id}_${lang}`}
                                to={{
                                    pathname: `/${forumNameConfig}/${nameLink}${lang}`,
                                }}
                            />
                        ))}
                        <Link
                            to={{
                                pathname: `/${forumNameConfig}/${nameLink}`,
                                search: "",
                                hash: "",
                                state: {
                                    forumId: forum._id,
                                },
                            }}
                        >
                            <Paper className={cellClassName}>
                                <div className={styles.cellContent}>
                                    <div
                                        className={`${styles.topRow} ${styles.imgDiv}`}
                                    >
                                        <img src={mainImageUrl} alt="Image" />
                                        {forum.status ===
                                            DISCOUNTED_PRODUCT && (
                                            <div
                                                className={
                                                    styles.overlappingImage
                                                }
                                            >
                                                <img
                                                    src={DiscountedImg}
                                                    alt="Overlapping Image"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.tourName}>
                                        {modifiedName}
                                    </div>
                                    <div className={styles.tourDescription}>
                                        {desc}
                                    </div>
                                    <hr />
                                    {forum.status === DISCOUNTED_PRODUCT &&
                                    forum.discount > 0 ? (
                                        <>
                                            <div className={styles.tourPrice}>
                                                <div
                                                    className={styles.oldPrice}
                                                >
                                                    {priceContent}
                                                </div>{" "}
                                                <div
                                                    className={styles.newPrice}
                                                >
                                                    C${forum.price}{" "}
                                                    <span
                                                        className={
                                                            styles.perPerson
                                                        }
                                                    >
                                                        /{t("PerPerson")}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={styles.tourPrice}>
                                            {priceContent === t("Inquire") ? (
                                                priceContent
                                            ) : (
                                                <span>
                                                    {priceContent}{" "}
                                                    <span
                                                        className={
                                                            styles.perPerson
                                                        }
                                                    >
                                                        /{t("PerPerson")}
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </Link>
                    </Grid>
                );
            });
    };

    if (isLoading === false) {
        //console.log("forums:" + JSON.stringify(forums));
        const filteredForums =
            forumCategoryId >= 1
                ? forums.filter((forum) => forum.categoryId === forumCategoryId)
                : forums;
        //console.log("filteredForums:" + JSON.stringify(filteredForums));
        if (Array.isArray(filteredForums) && filteredForums.length > 0) {
            const DISCOUNTED_ITEMS = 3;
            const gridDiscountItems = generateGridItems(
                filteredForums,
                langCode,
                (status) => status === DISCOUNTED_ITEMS
            );
            const gridItems = generateGridItems(
                filteredForums,
                langCode,
                (status) => status !== DISCOUNTED_ITEMS
            );

            /* 여행상품위 시티 설명
            if (!forumCategoryId) forumCategoryId = 0;
            let cityTitle = FORUM_CATEGORY_TYPE_ID[forumCategoryId];
            const cityDesc =
                langCode === "kr"
                    ? FORUM_CATEGORY_DESC_kr[cityTitle]
                    : FORUM_CATEGORY_DESC_ca[cityTitle];
            cityTitle =
                langCode === "kr"
                    ? cityTitle
                    : FORUM_CATEGORY_TYPE_ID_ca[forumCategoryId];
            if (
                !forumCategoryId ||
                forumCategoryId === 0 ||
                forumCategoryId === "0"
            ) {
                cityTitle = "";
            }*/
            return (
                <div className={styles.forumListContainer}>
                    {/*
                    {cityTitle === "" ? (
                        ""
                    ) : (
                        <div className={styles.ytParent}>
                            <div className={styles.ytDiv}>
                                <div className={styles.ytImg}><img width="50px" src={YoutubeLogo} /></div>
                                <div className={styles.ytBody}>
                                    {t('YoutubeText')}
                                </div>
                            </div>
                        </div> 
                        <div className={styles.cityDesc}>
                            <div className={styles.cityName}>{cityTitle}</div>
                            {cityDesc}
                        </div>
                    )}*/}
                    <div className={styles.tourContainer}>
                        <span className={styles.leftBar}>&nbsp;</span>
                        <span className={styles.tourTypeTitle}>
                            {t("TourReview")}
                        </span>
                    </div>
                    <Review />
                    {gridDiscountItems.length > 0 && (
                        <div className={styles.tourContainer}>
                            <span className={styles.leftBar}>&nbsp;</span>
                            <span className={styles.tourTypeTitle}>
                                {t("Discounted")}
                            </span>
                        </div>
                    )}
                    <Grid container spacing={1}>
                        {gridDiscountItems}
                    </Grid>
                    {gridItems.length > 0 && (
                        <div className={styles.tourContainer}>
                            <span className={styles.leftBar}>&nbsp;</span>
                            <span className={styles.tourTypeTitle}>
                                {t("Recommended")}
                            </span>
                        </div>
                    )}
                    <Grid container spacing={1}>
                        {gridItems}
                    </Grid>
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.workingDiv}>
                        <div className={styles.workingTxt}>
                            {t("NoTourPackage")}
                        </div>
                        <img
                            tag="Under Construction"
                            src="./bgImg/working_sm.png"
                            className={styles.responsiveImage}
                        />
                    </div>
                </>
            );
        }
    } else {
        return null;
    }
};

export default ForumList;
