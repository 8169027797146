import React from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Office from "../../img/office.jpg";

const Ceo = () => {
    const lang = useSelector((state) => state.app.lang);
    const { t } = useTranslation();
    const members = [
        {
            name: t("HJKim"),
            role: t("HJKimRole"),
            img: "/bgImg/HJ.jpg",
            remarks: t("HJKimRemarks"),
        },
        {
            name: t("Kai"),
            role: t("KaiRole"),
            img: "/bgImg/Kai.png",
            remarks: t("KaiRemarks"),
        },
        {
            name: t("Gil"),
            role: t("GilRole"),
            img: "/bgImg/Gil.jpg",
            remarks: t("GilRemarks"),
        },
        {
            name: t("DEHan"),
            role: t("DEHanRole"),
            img: "/bgImg/DEH.png",
            remarks: t("DEHanRemarks"),
        },
        {
            name: t("Lym"),
            role: t("LymRole"),
            img: "/bgImg/Lym.jpg",
            remarks: t("LymRemarks"),
        },
        {
            name: t("IHKim"),
            role: t("IHKimRole"),
            img: "/bgImg/GreyAvartar.png",
        },
        {
            name: t("JEKang"),
            role: t("JEKangRole"),
            img: "/bgImg/GreyAvartar.png",
        },
        {
            name: t("SMLee"),
            role: t("SMLeeRole"),
            img: "/bgImg/GreyAvartar.png",
        },
    ];
    return (
        <>
            <div className={styles.ceoContainer}>
                {lang === "ca" ? (
                    <>
                        <div>
                            <div className={styles.topHeader}>
                                Greetings from the CEO
                            </div>
                            <p>
                                Hello, I am Kim Hee Jung, the representative of
                                C&K Tour, headquartered in Calgary, Alberta,
                                Canada. We are a company that provides travel,
                                immigration, and education programs, offering
                                you fantastic and affordable travel experiences
                                for a vibrant daily life. With 25 years of
                                experience, we conduct various types of trips
                                based on the daily life and know-how of locals,
                                allowing tourists to enjoy a more enriching and
                                in-depth travel experience in Canada. Especially
                                through customized trips, we strive to capture
                                the essence of true Canadian travel. We are
                                committed to introducing unique places and
                                special locations not experienced even by those
                                who have lived in Canada for a long time,
                                through theme tours that will not disappoint
                                you.
                            </p>
                            <p>
                                In addition, with 25 years of experience helping
                                and guiding numerous individuals aspiring to
                                immigrate, we provide consultations on
                                immigration methods tailored to you and will do
                                our best to assist you until the completion of
                                your immigration process. We promise to lead
                                your immigration successfully through the most
                                suitable method from your perspective. We look
                                forward to the growth of C&K with your support
                                and encouragement. Thank you.
                            </p>
                        </div>
                        <div>
                            <div className={styles.lastHeader}>Biography</div>
                            <ul>
                                <li>
                                    Graduated from the Department of English
                                    Language and Literature at Hanyang
                                    University
                                </li>
                                <li>
                                    Master's in English Literature from the
                                    University of Alberta
                                </li>
                                <li>
                                    Representative of Canada Youth Education
                                    (CYE)
                                </li>
                                <li>
                                    CYE (Canada Youth Education- Part of
                                    Immigration) Assistant Special Administrator
                                </li>
                                <li>
                                    2019 COEX Immigration and Study Abroad
                                    Exhibition held
                                </li>
                                <li>
                                    MOU signing and consulting with Korean
                                    branch ESTART
                                </li>
                                <li>
                                    Signed MOU with SK Immigration Immigration
                                    and study abroad consulting
                                </li>
                                <li>
                                    Immigration Service Canada(ISC) Interpreter
                                </li>
                                <li>
                                    Bow Valley University International Student
                                    Counselor
                                </li>
                                <li>
                                    Canada Petroleum Corporation ESL teacher
                                </li>
                                <li>
                                    Representative of IMI (International
                                    Multimedia English Institute) in Noryangjin,
                                    Korea
                                </li>
                                <li>
                                    English lectures for directors of famous
                                    Korean companies (Samsung, Hyundai Dacom,
                                    Board of Audit and Inspection, etc.)
                                </li>
                                <li>
                                    EIL (English International Living) Korea
                                    Section Secretary
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <div className={styles.topHeader}>대표 인사말</div>
                            <p>
                                저는 캐나다 알버타주 캘거리에 본사를 두고 있는
                                C&K투어의 대표 김희정입니다. 저희는 여행과 이민,
                                교육 프로그램을 제공하는 회사로, 여러분에게
                                생동감 있는 일상을 경험할 수 있는 훌륭한 여행을
                                저렴한 비용으로 제공하고 있습니다. 25년 동안의
                                경험을 통해 현지사람들이 경험하는 일상과
                                노하우를 바탕으로 관광객들이 더 풍부하고 깊이
                                있는 캐나다 여행을 즐길 수 있도록 다채로운
                                종류의 여행을 진행하고 있습니다. 특히, 맞춤
                                여행을 통해 진정한 캐나다 여행의 본질을
                                담아내고자 최선을 다하고 있습니다. 오랫동안
                                캐나다에 거주한 분들이라도 경험하지 못한 장소와
                                독특한 곳을 소개하는 테마 여행은 여러분을
                                실망시키지 않을 것입니다.
                            </p>

                            <p>
                                더불어 25년 간 수많은 이민을 희망하는 분들을
                                돕고 안내한 경험과 많은 분들에게 도움을 주며
                                얻은 노하우로, 여러분에게 맞는 이민 방법을
                                상담하고 이민을 완료하기까지 최선을
                                다하겠습니다. 여러분의 입장에서 가장 적합한
                                방법으로 여러분의 이민을 성공적으로 이끌 것을
                                약속드립니다. 여러분의 지지와 격려로 C&K가
                                발전하고 함께 성장할 수 있기를 기대합니다.
                                감사합니다.
                            </p>
                        </div>
                        <div>
                            <div className={styles.lastHeader}>
                                김희정 대표 이력
                            </div>
                            <ul>
                                <li>한양대학교 영문학과 졸업</li>
                                <li>알버타 주립대학 영문학 석사</li>
                                <li>CYE (Canada Youth Education) 대표</li>
                                <li>
                                    CYE (Canada Youth Education- Immigration
                                    부분) 보조 특별 관리자
                                </li>
                                <li>2019 코엑스 이민과 유학 전시회 개최</li>
                                <li>한국 지사 ESTART 와 MOU 체결 및 컨설팅</li>
                                <li>SK 이민과 MOU 체결 이민 및 유학 컨설팅</li>
                                <li>ISC (immigration Service Canada) 통역사</li>
                                <li>보우벨리 대학 국제학생 상담사</li>
                                <li>캐나다 석유공사 ESL 교사 역임</li>
                                <li>
                                    대한민국 노량진 IMI (International
                                    Multimedia English Institute)의 대표
                                </li>
                                <li>
                                    유명 한국회사 이사진들을 위한 영어강의
                                    (삼성, 현대 데이콤, 감사원 등)
                                </li>
                                <li>
                                    EIL (English International Living) 한국 섹션
                                    비서
                                </li>
                            </ul>
                        </div>
                    </>
                )}
                <div className={styles.header}>스태프</div>
                <div className={styles.membersContainer}>
                    {members.map((member, index) => (
                        <div key={index} className={styles.memberCard}>
                            <img
                                className={styles.memberImage}
                                src={member.img}
                                alt={member.name}
                            />
                            <div className={styles.memberName}>
                                {member.name}
                            </div>
                            <div className={styles.memberRole}>
                                {member.role}
                            </div>
                            {member.remarks && (
                                <>
                                    <hr />
                                    <div className={styles.memberRemarks}>
                                        {member.remarks}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div style={{ marginTop: "20px" }}>
                    <img className={styles.headImg} src={Office} alt="Office" />
                </div>
            </div>
        </>
    );
};

export default Ceo;
