import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Bulletin from "components/Bulletin";
import Loading from "components/Loading";
import TopicList from "components/Topic/List";
import AdminHeader from "components/AdminHeader";
import TopicListController from "components/Topic/ListController";
import TopicToolBar from "components/Topic/TopicToolBar";
import styles from "./index.module.css";
import {
    UPDATE_TOPICS_START,
    UPDATE_TOPICS_SUCCESS,
    UPDATE_TOPICS_FAILURE,
    TOGGLE_TOPICS_SORT_METHOD,
    TOPICS_SORT_METHODS,
} from "./constants";
import { UPDATE_APP_ADDRESS } from "views/App/constants";
import { CATEGORY_TYPES } from "../../constants";
import { useTranslation } from "react-i18next";
import LoadingImg from "../../img/loading.svg";

const Topics = () => {
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const forumName = useSelector((state) => state.app.forumName);
    const forumId = useSelector((state) => state.app.forumId);
    let isAdmin = useSelector((state) => state.app.isAdmin);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    const accessToken = useSelector((state) => state.app.accessToken);
    const sortMethod = useSelector((state) => state.topics.sortMethod);
    const topicList = useSelector((state) => state.topics.topicList);
    const isLoading = useSelector((state) => state.topics.isLoading);
    const [justThisForumData, setJustThisForumData] = useState(null);

    const initTopics = () => {
        const ID_LANG_REGEX = /-(\w+)-(\w+)$/;
        let forumNameFromUrl = "";
        let forumId = 0;

        const pathnameParts = location.pathname.split("/");
        const forumNameParam = pathnameParts[2];

        if (
            location.state !== undefined &&
            location.state.forumId !== undefined
        ) {
            forumNameFromUrl = forumNameParam.replace(ID_LANG_REGEX, "");
            forumId = location.state.forumId;
        } else {
            const matches = forumNameParam.match(ID_LANG_REGEX);

            if (matches && matches.length > 1) {
                forumId = matches[1];
            }
            forumNameFromUrl = forumNameParam.replace(ID_LANG_REGEX, "");
        }

        updateRedux(forumNameFromUrl, forumId, isAdmin);
        getTopicsAndBulletin(forumId);
    };

    const updateRedux = (useThisForumName, useThisForumId, useIsAdmin) => {
        dispatch({
            type: UPDATE_APP_ADDRESS,
            forumName: useThisForumName,
            forumId: useThisForumId,
            isAdmin: useIsAdmin,
        });
    };

    // Load only once
    useEffect(() => {
        initTopics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTopicsAndBulletin = async (thisForumId) => {
        try {
            if (!thisForumId) thisForumId = forumId;
            dispatch({ type: UPDATE_TOPICS_START });

            // Check if resTopic data exists in localStorage
            let storedTopicData = "";
            const resTopic = await axios.get(
                `/api/forum/${thisForumId}/topic?sort_method=${sortMethod}`
            );
            storedTopicData = JSON.stringify(resTopic.data);
            dispatch({
                type: UPDATE_TOPICS_SUCCESS,
                topicList: JSON.parse(storedTopicData),
            });

            // Get forum bulletin
            const storedData = localStorage.getItem("forumData-" + thisForumId);
            if (storedData) {
                const storedTime = localStorage.getItem(
                    "forumDataTime-" + thisForumId
                );
                const apiResponse = await axios.get(
                    `/api/forum/byId/${thisForumId}?&storedTime=${storedTime}`
                );
                if (apiResponse.status !== 200) {
                    alert("개별여행: 데이터를 불러올 수 없습니다.");
                }
                if (Object.keys(apiResponse.data).length > 0) {
                    console.log("개별여행: DB가 업데이트되 새로 데이터 얻음");
                    setJustThisForumData(apiResponse.data);
                    // Update localStorage with fresh data and current time
                    localStorage.setItem(
                        "forumData-" + thisForumId,
                        JSON.stringify(apiResponse.data)
                    );
                    localStorage.setItem(
                        "forumDataTime-" + thisForumId,
                        new Date().getTime()
                    );
                } else {
                    console.log(
                        "개별여행: DB가 업데이트되지 않아 예전 데이터 사용"
                    );
                    setJustThisForumData(JSON.parse(storedData));
                }
            } else {
                // If no data in localStorage, make an API call to get fresh data
                const apiResponse = await axios.get(
                    `/api/forum/byId/${thisForumId}`
                );
                setJustThisForumData(apiResponse.data);
                // Update localStorage with fresh data and current time
                localStorage.setItem(
                    "forumData-" + thisForumId,
                    JSON.stringify(apiResponse.data)
                );
                localStorage.setItem(
                    "forumDataTime-" + thisForumId,
                    new Date().getTime()
                );
                console.log(
                    "개별여행: 로컬스토리지에 데이터가 없음. 새로 데이터 얻음"
                );
            }
        } catch (error) {
            dispatch({
                type: UPDATE_TOPICS_FAILURE,
                error: error.message,
            });
        }
    };

    const toggleSortMethod = (newMethod) => {
        dispatch({
            type: TOGGLE_TOPICS_SORT_METHOD,
            sortMethod: newMethod,
        });
    };

    const topicToolBarRef = useRef();
    const handleTopicToolBarAction = () => {
        // Call the handleOpen function of the TopicToolBar component
        if (topicToolBarRef.current) {
            topicToolBarRef.current.handleOpen(CATEGORY_TYPES.TourQuestion);
        }
    };

    return (
        <div className={styles.topicContainer}>
            {isSuperUser ? <AdminHeader forumId={forumId} /> : ""}
            {(isLoading || !justThisForumData) && (
                <div>
                    <img
                        src={LoadingImg}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                        }}
                        alt="Loading animation from SVGBackgrounds.com"
                    />
                </div>
            )}
            {/* Render Bulletin only when justThisForumData is ready */}
            {justThisForumData && !isLoading && (
                <div>
                    <Bulletin
                        value={justThisForumData}
                        forumId={forumId}
                        onAction={handleTopicToolBarAction} // Pass the callback function
                    />
                    <div>
                        <div className={styles.boardMeTooTopBox}>
                            {t("TripBoardHeader")}
                        </div>
                        <div className={styles.listContainer}>
                            <TopicListController
                                topicSortMethods={TOPICS_SORT_METHODS}
                                toggleSortMethod={toggleSortMethod}
                            />
                            {isLoading && <Loading />}
                            <TopicList
                                isLoading={isLoading}
                                forumName={forumName}
                                forumId={forumId}
                                items={topicList}
                            />

                            <TopicToolBar
                                ref={topicToolBarRef}
                                url={`/api/forum/${forumId}/topic`}
                                accessToken={accessToken}
                                onSubmit={getTopicsAndBulletin}
                                tourData={justThisForumData}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Topics;
