import React from "react";
import { Link, useHistory } from "react-router-dom"; // Import useHistory from react-router-dom
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import axios from "axios";

const AdminHeader = ({ forumId }) => {
    const history = useHistory(); // Get the history object from react-router-dom
    const accessToken = useSelector((state) => state.app.accessToken);

    const deleteForum = async () => {
        try {
            const response = await axios.delete(`/api/forum/${forumId}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                alert("여행정보 삭제됨");
                localStorage.removeItem("forumData"); // 로컬스토리에서 데이터 없앰.
                history.goBack(); // Move back 1 history after delete is successful
            }
        } catch (error) {
            console.error(error);
            alert("여행정보 삭제 실패");
        }
    };

    return (
        <>
            <div className={styles.adminHeader}>여행정보 수정</div>
            <div className={styles.adminHeaderContainer}>
                <span className={styles.link}>
                    <Link to={`/admin/${forumId}`}>사진/메인 수정하기</Link>
                    <span className={styles.arrow}>|</span>
                    <Link to={`/admin/route/${forumId}`}>
                        포함/불포함/일정 수정하기
                    </Link>
                    <span className={styles.arrow}>|</span>
                    <Link to={`/admin/img/${forumId}`}>
                        이 여행상품에 사용된 이미지 보기
                    </Link>
                    <span className={styles.arrow}>|</span>
                    <span className={styles.deleteLink} onClick={deleteForum}>
                        여행 삭제하기
                    </span>
                </span>
            </div>
        </>
    );
};

export default AdminHeader;
