import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import styles from "./index.module.css";
import Login from "components/User/Login";
import SignUp from "components/User/SignUp";
import { useTranslation } from "react-i18next";

const UserAccess = ({ username, setToken, isSuperUser, onCloseMobileMenu }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const reduxAccessToken = useSelector((state) => state.app.accessToken);
    const lang = useSelector((state) => state.app.lang);

    const handleClose = () => {
        setShow(false); // Add this line to ensure menuOpen is updated
    };

    const handleShow = (isLogin) => {
        switchState(isLogin);
        setShow(true);
    };

    const [isLogin, setIsLogin] = useState(true);
    const [isSignIn, setIsSignIn] = useState(false);

    useEffect(() => {
        if (reduxAccessToken) {
            handleClose();
        }
    }, [reduxAccessToken]);

    const switchState = (isLogin) => {
        switch (isLogin) {
            case false:
                setIsLogin(false);
                setIsSignIn(true);
                break;
            case true:
                setIsLogin(true);
                setIsSignIn(false);
                break;
            default:
                break;
        }
    };

    const UserAvartarInHeader = () => (
        <div className={styles.menuBox}>
            {reduxAccessToken ? (
                <span className={styles.menu}>
                    {isSuperUser ? "[관리자] " : ""}
                    <Link to={`/user/${username}`} onClick={onCloseMobileMenu}>
                        {t("Profile")}
                    </Link>
                    <span className={styles.divider}>|</span>
                    <Link to="/logout" onClick={onCloseMobileMenu}>
                        {t("Logout")}
                    </Link>
                </span>
            ) : (
                <>
                    <span className={styles.menu}>
                        <a
                            className={styles.menuLink}
                            onClick={() => {
                                handleShow(true);
                                onCloseMobileMenu(); // Close mobile menu when Login is clicked
                            }}
                        >
                            {t("Login")}
                        </a>
                        <span className={styles.divider}>|</span>
                        <Link
                            to={"/register/" + lang}
                            onClick={onCloseMobileMenu}
                        >
                            {t("SimpleSignup")}
                        </Link>
                    </span>
                </>
            )}
        </div>
    );

    const Auth = ({ isLogin, isSignIn }) => {
        return (
            <div>
                {isLogin && (
                    <div className={styles.auth}>
                        <Login setToken={setToken} />
                    </div>
                )}
                {isSignIn && (
                    <div className={styles.auth}>
                        <SignUp setToken={setToken} />
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <UserAvartarInHeader />

            <Modal centered show={show} onHide={handleClose}>
                <Modal.Dialog className={styles.loginModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Login")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Auth isLogin={isLogin} isSignIn={isSignIn} />
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </>
    );
};
export default UserAccess;
