import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Header from "views/Header";
import Footer from "views/Footer";
import Logout from "views/Logout";
import Ceo from "views/CEO";
import Forums from "views/Forums";
import Topics from "views/Topics";
import Discussion from "views/Discussion";
import User from "views/Profile";
import Admin from "views/Admin";
import AdminRoute from "views/AdminRoute";
import AdminImage from "views/AdminImage";
import Register from "views/Register";
import RegisterVerify from "views/Register/verify";
import ContactUs from "views/Register/contact";
import Career from "views/Register/career";
import NotFound from "views/NotFound";
import Attention from "views/Footer/attention";
import Privacy from "views/Footer/privacy";
import TandC from "views/Footer/tandc";
import YouTube from "react-youtube";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import {
    FORUM_CATEGORY_TYPE_ID_ca,
    FORUM_CATEGORY_TYPE_NAME,
} from "../../constants";
import { UPDATE_APP_FORUMCATEGORYID } from "views/App/constants";
import { Helmet } from "react-helmet";

const { forumNameConfig, siteUrl, supportedLang } = require("../../config");

const App = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    let langCode = useSelector((state) => state.app.lang);
    const [backgroundStyle, setBackgroundStyle] = useState({
        backgroundSize: "contain",
    });
    const [videoId, setYoutubeId] = useState("sy8mjGYgTVU");
    const [videoTitle, setYoutubeTitle] = useState("");
    const [videoText, setYoutubeText] = useState("");

    // 새 페이지가 로드시 화면 젤 위로 스크롤하기
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Get/Set forumCategoryId based on the extracted category name
    const cityName = location.pathname.split("/")[1];
    const matchingCategoryId = cityName
        ? Object.keys(FORUM_CATEGORY_TYPE_ID_ca).find(
              (categoryId) =>
                  FORUM_CATEGORY_TYPE_ID_ca[categoryId].toLowerCase() ===
                  cityName.toLowerCase()
          )
        : undefined;
    let forumCategoryId =
        matchingCategoryId !== undefined ? parseInt(matchingCategoryId, 10) : 0;

    // Meta 태그 생성
    let title = "";
    let desc = "";
    let previewImg = "";
    switch (location.pathname.toLowerCase()) {
        default:
            previewImg = "/bgImg/boat.jpg";
            title = "C&K 투어|Tour";
            desc =
                "25년 경험을 가진 캘거리 최고의 여행사 (Calgary's Travel Agency)";
            break;
        case "/" +
            FORUM_CATEGORY_TYPE_ID_ca[
                FORUM_CATEGORY_TYPE_NAME.전부
            ].toLowerCase():
        case "/" +
            FORUM_CATEGORY_TYPE_ID_ca[
                FORUM_CATEGORY_TYPE_NAME.전부
            ].toLowerCase() +
            "/kr":
            previewImg = "/bgImg/boat.jpg";
            title = "C&K 투어";
            desc = "25년 경험을 가진 캘거리 최고의 여행사 C&K투어입니다.";
            break;
        case "/" +
            FORUM_CATEGORY_TYPE_ID_ca[
                FORUM_CATEGORY_TYPE_NAME.전부
            ].toLowerCase() +
            "/ca":
            previewImg = "/bgImg/boat.jpg";
            title = "C&K Tour";
            desc =
                "Calgary's C&K Tour conduct various types of trips with 25 years of experience.";
            break;
        /*
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.아시아
        ].toLowerCase()}`:
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.아시아
        ].toLowerCase()}/kr`:
            previewImg = "/bgImg/Asia.jpg";
            title = "아시아";
            desc =
                "아시아의 흥미로운 명소와 다채로운 문화를 C&K와 함께 체험해보세요.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.아시아
        ].toLowerCase()}/ca`:
            previewImg = "/bgImg/Asia.jpg";
            title = "Asia";
            desc =
                "Experience the beautiful landmarks and diverse culture of Asia with C&K Tour.";
            break; */
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.옐로나이프
        ].toLowerCase()}`:
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.옐로나이프
        ].toLowerCase()}/kr`:
            previewImg = "/bgImg/aurora.jpg";
            title = "옐로나이프";
            desc =
                "25년 여행 경험을 가진 C&K와 옐로나이프의 자연 경관과 활기찬 북부 문화를 함께 탐험해보세요.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.옐로나이프
        ].toLowerCase()}/ca`:
            previewImg = "/bgImg/aurora.jpg";
            title = "Yellowknife";
            desc =
                "Explore Yellowknife's natural wonders and vibrant northern culture with C&K Tour.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.카나나스키스
        ].toLowerCase()}`:
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.카나나스키스
        ].toLowerCase()}/kr`:
            previewImg = "/bgImg/KananaskisCity.jpg";
            title = "카나나스키스";
            desc =
                "25년 여행 경험을 가진 C&K와 함께 카나나스키스의 아름다운 자연을 만끽해보세요.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.카나나스키스
        ].toLowerCase()}/ca`:
            previewImg = "/bgImg/KananaskisCity.jpg";
            title = "Kananaskis";
            desc = "Enjoy the natural beauty of Kananaskis with C&K Tour.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.록키
        ].toLowerCase()}`:
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.록키
        ].toLowerCase()}/kr`:
            previewImg = "/bgImg/Rockies.jpg";
            title = "록키";
            desc =
                "25년 여행 경험을 가진 C&K와 함께 로키 산맥의 웅장한 자연, 다양한 야생동물과 야외 온천을 체험하세요.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.록키
        ].toLowerCase()}/ca`:
            previewImg = "/bgImg/Rockies.jpg";
            title = "Rockies";
            desc = "Explore the Rockies' natural beauty with C&K.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.캘거리
        ].toLowerCase()}`:
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.캘거리
        ].toLowerCase()}/kr`:
            previewImg = "/bgImg/CalgaryCity.jpg";
            title = "캘거리";
            desc =
                "25년 여행 경험을 가진 C&K와 함께 다채로운 캘거리 모습을 경험 해 보세요.";
            break;
        case `/${FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.캘거리
        ].toLowerCase()}/ca`:
            previewImg = "/bgImg/CalgaryCity.jpg";
            title = "Calgary";
            desc = "Experience and explorer Calgary with C&K Tour.";
            break;
        /*case FORUM_CATEGORY_TYPE_ID_ca[
            FORUM_CATEGORY_TYPE_NAME.멕시코
        ].toLowerCase():
            previewImg = "/bgImg/mexico.jpg";
            title = "멕시코(Mexico)";
            desc =
                "C&K와 함께하는 환상적인 멕시코 여행 (Explore Mexico's stunning destinations with C&K)";
            break; */
        case "/ceo":
        case "/ceo/kr":
            previewImg = "/bgImg/flight.jpg";
            title = "회사소개";
            desc = "C&K투어 회사소개입니다.";
            break;
        case "/ceo/ca":
            previewImg = "/bgImg/flight.jpg";
            title = "Our Company";
            desc = "Get to know our company, C&K Tour better.";
            break;
        case "/register":
        case "/register/kr":
            previewImg = "/bgImg/register.jpg";
            title = "가입하기";
            desc = "C&K투어에 가입하고 다양한 혜택을 받으세요.";
            break;
        case "/register/ca":
            previewImg = "/bgImg/register.jpg";
            title = "Register";
            desc = "Register & enjoy various benefits from C&K Tour";
            break;
        case "/contact":
        case "/contact/kr":
            previewImg = "/bgImg/contactus.jpg";
            title = "연락하기";
            desc = "C&K투어에 궁금한점이 있으신가요?";
            break;
        case "/contact/ca":
            previewImg = "/bgImg/contactus.jpg";
            title = "Contact Us";
            desc = "Any questions C&K Tour can answer?";
            break;
        case "/career":
        case "/career/kr":
            previewImg = "/bgImg/careers.jpg";
            title = "채용정보";
            desc = "C&K투어를 함께 성장시킬분을 모십니다.";
            break;
        case "/career/ca":
            previewImg = "/bgImg/careers.jpg";
            title = "Careers";
            desc = "Join C&K Tour to grow our company together.";
            break;
    }
    previewImg = siteUrl + previewImg;

    const dynamicRoutes = Object.keys(FORUM_CATEGORY_TYPE_ID_ca).flatMap(
        (categoryId) =>
            supportedLang.flatMap((lang) => [
                <Route
                    exact
                    path={`/${FORUM_CATEGORY_TYPE_ID_ca[categoryId]}${lang}`}
                    component={Forums}
                    key={`${lang}_${categoryId}`}
                />,
            ])
    );

    // Check if the route is the home route or any of the dynamic routes
    const isDynamicRoute = dynamicRoutes.some((dynamicRoute) =>
        location.pathname
            .toLowerCase()
            .startsWith(dynamicRoute.props.path.toLowerCase())
    );

    useEffect(() => {
        // Dynamically set background style based on the current route
        const route = location.pathname;
        let style = {
            backgroundSize: "contain",
        };
        if (route === "/" || isDynamicRoute) {
            //style.backgroundImage = "url('/bgImg/Banff.jpg')";
            style.backgroundSize = "cover";
            style.opacity = "rgba(248, 245, 247, 0.2)";
            style.backgroundColor = "#f0f0f0";
            style.marginTop = "460px";

            // 카테고리 별로 다른 백그라운드 표시
            forumCategoryId = parseInt(forumCategoryId, 10);
            if (forumCategoryId === FORUM_CATEGORY_TYPE_NAME.전부) {
                setYoutubeId("sy8mjGYgTVU");
                setYoutubeTitle(t("YoutubeHomeTitle"));
                setYoutubeText(t("YoutubeHomeText"));
            } else if (forumCategoryId === FORUM_CATEGORY_TYPE_NAME.록키) {
                setYoutubeId("TIKUOQk5rzg");
                setYoutubeTitle(t("Rockies"));
                setYoutubeText(t("RockyDescription"));
            } else if (
                forumCategoryId === FORUM_CATEGORY_TYPE_NAME.카나나스키스
            ) {
                //style.backgroundImage = "url('/bgImg/kananaskis.jpg')";
                setYoutubeId("TIKUOQk5rzg"); // 카나나: 7nsFHQlkUIU
                setYoutubeTitle(t("Kananaskis"));
                setYoutubeText(t("KananaskisDescription"));
            } else if (forumCategoryId === FORUM_CATEGORY_TYPE_NAME.캘거리) {
                //style.backgroundImage = "url('/bgImg/calgary_road.jpg')";
                setYoutubeId("kvxxVf3hktE");
                setYoutubeTitle(t("Calgary"));
                setYoutubeText(t("CalgaryDescription"));
            } else if (forumCategoryId === FORUM_CATEGORY_TYPE_NAME.멕시코) {
                //style.backgroundImage = "url('/bgImg/cancun.jpg')";
                setYoutubeId("kwNAZQawdck");
                setYoutubeTitle(t("Mexico"));
                setYoutubeText(t("MexicoDescription"));
            } else if (
                forumCategoryId === FORUM_CATEGORY_TYPE_NAME.옐로나이프
            ) {
                //style.backgroundImage = "url('/bgImg/aurora.jpg')";
                setYoutubeId("r4uQJLEmrog");
                setYoutubeTitle(t("Yellowknife"));
                setYoutubeText(t("YellowknifeDescription"));
            } /*else if (forumCategoryId === FORUM_CATEGORY_TYPE_NAME.아시아) {
                //style.backgroundImage = "url('/bgImg/aurora.jpg')";
                setYoutubeId("OY-M1KIb11U");
                setYoutubeTitle(t("Asia"));
                setYoutubeText(t("AsiaDescription")); 
            }*/
        } else if (route.startsWith(`/${forumNameConfig}`)) {
            style.backgroundColor = "#f8f5f7";
            style.backgroundImage = "url('/bgImg/pink_mt.jpg')";
            style.backgroundSize = "cover";
        } else if (route.includes("/ceo")) {
            style.backgroundImage = "url('/bgImg/calgary.jpg')";
            style.backgroundSize = "contain";
        } else if (route.includes("/user")) {
            style.backgroundImage = "url('/bgImg/flight.jpg')";
            style.backgroundSize = "cover";
        } else if (route.includes("/logout")) {
            style.backgroundImage = "url('/bgImg/pink_bus.jpg')";
            style.backgroundSize = "cover";
        }
        setBackgroundStyle(style);

        dispatch({
            type: UPDATE_APP_FORUMCATEGORYID,
            forumCategoryId: forumCategoryId,
        });
    }, [location.pathname, forumCategoryId, langCode]);

    const [player, setPlayer] = useState(null);

    const opts = {
        width: "100%",
        height: "100%",
        playerVars: {
            autoplay: 1,
            controls: 0,
            loop: 1,
            showinfo: 0,
            mute: 1,
            modestbranding: 1,
            disablekb: 1,
        },
    };

    const onReady = (event) => {
        setPlayer(event.target);
    };

    const onEnd = () => {
        // Restart the video when it ends
        if (player) {
            player.seekTo(0);
        }
    };

    const otherRoutes = supportedLang.flatMap((lang) => [
        <Route exact path={`/ceo${lang}`} component={Ceo} />,
        <Route exact path={`/register${lang}`} component={Register} />,
        <Route exact path={`/contact${lang}`} component={ContactUs} />,
        <Route exact path={`/tandc${lang}`} component={TandC} />,
        <Route exact path={`/privacy${lang}`} component={Privacy} />,
        <Route exact path={`/attention${lang}`} component={Attention} />,
        <Route exact path={`/career${lang}`} component={Career} />,
        <Route
            exact
            path={`/${forumNameConfig}/:forumName`}
            component={Topics}
        />,
    ]);

    return (
        <>
            <Helmet>
                <html lang={langCode} />
            </Helmet>
            <Header />
            {title && desc && previewImg && (
                <Helmet>
                    <title>{title}</title>
                    <meta name="og:image" content={previewImg} />
                    <meta name="og:description" content={desc} />
                </Helmet>
            )}
            {(location.pathname === "/" || isDynamicRoute) &&
                !location.pathname.includes("/" + forumNameConfig + "/") && (
                    <div className={styles.youtubeWrap}>
                        <YouTube
                            videoId={videoId} // Replace with your actual YouTube video ID
                            className={styles.youtubeVideo} // Add a CSS class for styling
                            opts={opts}
                            onReady={onReady}
                            onEnd={onEnd}
                        />

                        <div className={styles.youtubeText}>{videoTitle}</div>
                        <div
                            className={styles.youtubeSubText}
                            dangerouslySetInnerHTML={{ __html: videoText }}
                        ></div>
                    </div>
                )}
            <div className={styles.appWrap}>
                <div className={styles.appContainer} style={backgroundStyle}>
                    <Switch>
                        <Route exact path={`/`} component={Forums} />
                        <Route
                            exact
                            path={`/${forumNameConfig}/:topicName/:forumId/:topicId`}
                            component={Discussion}
                        />
                        <Route exact path="/user/:username" component={User} />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/admin/:forumId" component={Admin} />
                        <Route
                            exact
                            path="/admin/route/:forumId"
                            component={AdminRoute}
                        />
                        <Route
                            exact
                            path="/admin/img/:forumId"
                            component={AdminImage}
                        />
                        <Route
                            exact
                            path="/verify"
                            component={RegisterVerify}
                        />
                        {dynamicRoutes}
                        {otherRoutes}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default App;
