import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import UserAccess from "components/User/Access";
import styles from "./index.module.css";
import CNKTourLogo from "img/CNKTourLogo_PINK.png";
import Flag from "react-world-flags";
import { UPDATE_APP_AUTH } from "views/App/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    UPDATE_APP_FORUMCATEGORYID,
    UPDATE_APP_LANG,
} from "views/App/constants";
import {
    FORUM_CATEGORY_TYPE_ID,
    FORUM_CATEGORY_TYPE_ID_ca,
} from "../../constants";
import i18n from "../../app/i18n";
import KakaoLoginButton from "../Kakao";
import { useLocation } from "react-router-dom";
const { supportedLang } = require("../../config");

const Header = ({ username, setToken, isSuperUser }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const forumCategoryId =
        useSelector((state) => state.app.forumCategoryId) || 0;

    // 언어 설정
    let navigatorLang = "ca";
    if (navigator.language.startsWith("ko")) {
        navigatorLang = "kr";
    }
    let selectedFlag = localStorage.getItem("selectedFlag") || navigatorLang;
    let urlLang = location.pathname.split("/").pop(); // Get the last part of the URL
    if (
        urlLang.toLowerCase().endsWith("-kr") ||
        urlLang.toLowerCase().endsWith("-ca")
    ) {
        urlLang = urlLang.split("-").pop(); // Url이 상품명일경우 예) http://cnktour.com/travel/Banff_day_trip-65627babece6c41d9c726567-kr
    }

    // If lang code is passed in from URL, 최우선순위가 되어야함.
    if (urlLang === "kr" || urlLang === "ca") {
        selectedFlag = urlLang;
    }

    dispatch({
        type: UPDATE_APP_LANG,
        lang: selectedFlag,
    });

    useEffect(() => {
        // LocalStorage data를 사용해서 자동 로그인하기 (페이지 refresh때)
        const storedLoginData = localStorage.getItem("loginData");
        const storedExpirationTime = localStorage.getItem(
            "loginDataExpiration"
        );

        if (storedLoginData && storedExpirationTime) {
            const { username, password, accessToken, isSuperUser, email } =
                JSON.parse(storedLoginData);

            // Check if loginData has not expired
            const expirationTime = parseInt(storedExpirationTime, 10);
            if (expirationTime && expirationTime > new Date().getTime()) {
                dispatch({
                    type: UPDATE_APP_AUTH,
                    username: username,
                    password: password,
                    accessToken: accessToken,
                    isSuperUser: isSuperUser,
                    email: email,
                });
            } else {
                // Clear expired loginData
                localStorage.removeItem("loginData");
                localStorage.removeItem("loginDataExpiration");
            }
        }

        i18n.changeLanguage(selectedFlag);

        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    const handleFlagClick = (countryCode) => {
        localStorage.setItem("selectedFlag", countryCode);
        dispatch({
            type: UPDATE_APP_LANG,
            lang: countryCode,
        });
        i18n.changeLanguage(countryCode);

        let newPathname = location.pathname.replace(
            /\/(kr|ca)\b/,
            `/${countryCode}`
        );
        if (
            location.pathname.toLowerCase().endsWith("-kr") ||
            location.pathname.toLowerCase().endsWith("-ca")
        ) {
            newPathname = location.pathname.replace(
                /\-(kr|ca)$/i,
                `-${countryCode}`
            ); // Url이 상품명일경우 예) http://cnktour.com/travel/Banff_day_trip-65627babece6c41d9c726567-kr
        }
        history.push(newPathname);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const updateForumCategoryId = (id, city) => {
        dispatch({
            type: UPDATE_APP_FORUMCATEGORYID,
            forumCategoryId: id,
        });
        history.push(`/` + city);
    };

    const closeMobileMenu = () => {
        setMenuOpen(false);
    };

    const words =
        lang === "kr" ? FORUM_CATEGORY_TYPE_ID : FORUM_CATEGORY_TYPE_ID_ca;

    return (
        <div className={styles.background}>
            <nav className={styles.headerContainer}>
                <div className={styles.logoLink}>
                    <img
                        src={CNKTourLogo}
                        className={styles.logoImg}
                        alt="Home"
                        onClick={() =>
                            updateForumCategoryId(0, "All/" + selectedFlag)
                        }
                    />
                    <span className={styles.kakaoLink}>
                        <KakaoLoginButton />
                    </span>
                </div>
                <div className={styles.flagLink}>
                    {selectedFlag === "kr" ? null : (
                        <Link
                            className={styles.flag}
                            onClick={() => handleFlagClick("kr")}
                        >
                            <Flag code="kr" height="14" />
                            <span className={styles.flagTxt}>한국어</span>
                        </Link>
                    )}
                    {selectedFlag === "ca" ? null : (
                        <Link
                            className={styles.flag}
                            onClick={() => handleFlagClick("ca")}
                        >
                            <Flag
                                code="ca"
                                height="16"
                                style={{ border: "1px solid white" }}
                            />
                            <span className={styles.flagTxt}>English</span>
                        </Link>
                    )}
                </div>
                <div
                    id="mobileMenu"
                    className={
                        menuOpen ? styles.mobileMenu : styles.desktopMenu
                    }
                >
                    <span className={styles.leftBar}>&nbsp;</span>
                    <Link
                        key={lang}
                        className={styles.ceoLink}
                        to={`/career/${selectedFlag}`}
                        onClick={closeMobileMenu}
                    >
                        {t("Hire")}
                    </Link>
                    {supportedLang.map((lang) => (
                        <Link to={`/career${lang}`} />
                    ))}
                    <span className={styles.leftBar}>&nbsp;</span>
                    <Link
                        className={styles.ceoLink}
                        to={`/contact/${selectedFlag}`}
                        onClick={closeMobileMenu}
                    >
                        {t("ContactUs")}
                    </Link>
                    {supportedLang.map((lang) => (
                        <Link to={`/contact${lang}`} />
                    ))}
                    <span className={styles.leftBar}>&nbsp;</span>
                    <Link
                        className={styles.ceoLink}
                        to={`/ceo/${selectedFlag}`}
                        onClick={closeMobileMenu}
                    >
                        {t("IntroCEO")}
                    </Link>
                    {supportedLang.map((lang) => (
                        <Link to={`/ceo${lang}`} />
                    ))}
                    <UserAccess
                        username={username}
                        setToken={setToken}
                        isSuperUser={isSuperUser}
                        onCloseMobileMenu={closeMobileMenu}
                    />
                </div>

                <div className={styles.mobileMenuIcon} onClick={toggleMenu}>
                    <FaBars />
                </div>
            </nav>
            <div className={styles.subMenu}>
                <div className={styles.cityMenu}>
                    {Object.keys(words).map((index) => (
                        <Link
                            key={index}
                            to={`/${FORUM_CATEGORY_TYPE_ID_ca[index]}/${selectedFlag}`}
                            className={`${styles.cityItem} ${
                                index == forumCategoryId
                                    ? styles.selectedWord
                                    : ""
                            }`}
                            onClick={closeMobileMenu}
                        >
                            {words[index]}
                        </Link>
                    ))}
                    {Object.keys(words).map((index) =>
                        supportedLang.map((lang) => (
                            <Link
                                key={`${index}_${lang}`}
                                to={`/${FORUM_CATEGORY_TYPE_ID_ca[index]}${lang}`}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

Header.defaultProps = {
    username: "visitor",
    address: "./",
    accessToken: "",
    isSuperUser: false,
};

export default Header;
