import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TANDC_kr, TANDC_ca } from "../../tandc";
import "./Footer.css";

const TandC = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const TANDC = lang === "kr" ? TANDC_kr : TANDC_ca;

    return (
        <>
            <div className="contentContainer">
                <div className="contentTitle">{t("TandC")}</div>
                <div
                    className="htmlDiv"
                    dangerouslySetInnerHTML={{
                        __html: TANDC,
                    }}
                />
            </div>
        </>
    );
};

export default TandC;
