import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styles from "./index.module.css";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select"; // Import Select
import MenuItem from "@material-ui/core/MenuItem"; // Import MenuItem
import { sendEmail, todayDate } from "utility/utility";
import {
    CATEGORY_TYPES,
    CATEGORY_TYPES_LANG_ca,
    CATEGORY_TYPES_LANG_kr,
} from "../../../constants";
/*
import TagEditor from "components/Modal/TagEditor";
import TagPoolEditor from "components/Modal/TagPoolEditor";
*/
// ReactQuill
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
const {
    quillToolBarFullAccess,
    quillToolBarMinAccess,
} = require("../../../config");

const TopicToolBar = forwardRef(
    ({ url, accessToken, onSubmit, tourData }, ref) => {
        const { t } = useTranslation();
        const [open, setOpen] = useState(false);
        const [name, setName] = useState("");
        const [category, setCategory] = useState(CATEGORY_TYPES.TourQuestion);
        //const [tags, setTags] = useState([]);
        const [content, setContent] = useState("");
        const isSuperUser = useSelector((state) => state.app.isSuperUser);
        const username = useSelector((state) => state.user.username);
        const lang = useSelector((state) => state.app.lang);

        // Use useImperativeHandle to expose a function to the parent component
        useImperativeHandle(ref, () => ({
            handleOpen: handleOpen,
        }));

        const handleOpen = (categoryType) => {
            const forumName = tourData.name.find(
                (item) => item.lang === lang
            )?.txt;
            setCategory(categoryType);
            setOpen(true);

            if (categoryType === CATEGORY_TYPES.TourQuestion) {
                // 컨텐트
                let questionContent =
                    "<p><strong>Trip Name</strong>: {0}</p><p><strong>Trip Days:</strong> {1}<p><strong>Number of people</strong>: {2}  <p><strong>Question/Etc</strong>:";

                const questionTitle =
                    lang === "kr"
                        ? CATEGORY_TYPES_LANG_kr[categoryType]
                        : CATEGORY_TYPES_LANG_ca[categoryType];
                if (lang === "kr") {
                    questionContent =
                        "<p><strong>상품명</strong>: {0}</p><p><strong>투어일자:</strong> {1}<p><strong>인원수</strong>: {2}  <p><strong>질문/요청사항</strong>:";
                }
                questionContent = questionContent.replace("{0}", forumName);
                questionContent = questionContent.replace(
                    "{1}",
                    todayDate(0) + " ~ " + todayDate(tourData.tripDays)
                );
                questionContent = questionContent.replace(
                    "{2}",
                    username ? username : ""
                );
                questionContent = questionContent.replace(
                    "{4}",
                    tourData.tripDays ? tourData.tripDays : ""
                );
                setName(questionTitle);
                setContent(questionContent.replace("{0}", forumName));
            } else {
                // Reset fieldds for other category types.
                setName("");
                setContent("");
            }
        };

        const handleClose = () => {
            setOpen(false);
        };
        /*
    const handleAddOneTag = (tag) => {
        if (!tags.includes(tag)) {
            setTags([...tags, tag].sort());
        }
    };
    const handleDeleteOneTag = (id) => {
        setTags(tags.filter((_, index) => index !== id));
    };
*/
        const handleNameChange = (event) => {
            setName(event.target.value);
        };

        const handleCategoryChange = (event) => {
            setCategory(event.target.value);
        };

        const handleContentChange = (value) => {
            setContent(value);
        };

        const handleSubmit = async (event) => {
            event.preventDefault();
            try {
                await axios.post(
                    url, // /api/forum/654acd419e6ab009408810de/topic (문의하기 포럼: 656d428066bd1720bc4bad70)
                    {
                        topicName: name,
                        category: category,
                        tags: [],
                        content: content,
                    },
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                );
                setName("");
                setCategory("");
                //setTags([]);
                setContent("");
                handleClose();
                onSubmit();
                sendEmail(axios, {
                    topicId: "", // Topic ID
                    replyId: "", // Reply ID
                    subject: "새 게시판글",
                    body: `${window.location.href}\n\n${content}`,
                    // emailto가 없으면 admin email로 감.
                });
            } catch (error) {
                if (error.response.status === 401) {
                    alert(t("PlzLogin"));
                } else if (error.response.status === 403) {
                    alert(t("WriteLimit"));
                } else if (error.response.status === 422) {
                    alert(t("WriteContent"));
                } else {
                    alert(error);
                }
                console.error(error);
            }
        };

        const selectedQuillToolbar = isSuperUser
            ? quillToolBarFullAccess
            : quillToolBarMinAccess;

        return (
            <div className={styles.container}>
                <div className={styles.writeButtonDiv}>
                    <button
                        className="myButton secondary extraRightSpace"
                        onClick={() => handleOpen(CATEGORY_TYPES.TourQuestion)}
                    >
                        {t("WriteTripInquire")}
                    </button>
                    <button
                        className="myButton submit"
                        onClick={() => handleOpen(CATEGORY_TYPES.Question)}
                    >
                        {t("WriteNew")}
                    </button>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <div className="modalContainer">
                        <div className="modalHeader">
                            <Typography
                                variant="h6"
                                component="h2"
                                id="modal-title"
                            >
                                {t("WriteBoard")}
                            </Typography>
                        </div>
                        <div className="modalBody">
                            <Typography variant="body1" id="modal-description">
                                <div className={styles.head}>
                                    <label className={styles.item}>
                                        {t("Category")}
                                    </label>
                                    <Select
                                        value={category}
                                        onChange={handleCategoryChange}
                                    >
                                        <MenuItem
                                            value={CATEGORY_TYPES.TourQuestion}
                                        >
                                            {lang === "kr"
                                                ? CATEGORY_TYPES_LANG_kr[
                                                      CATEGORY_TYPES
                                                          .TourQuestion
                                                  ]
                                                : CATEGORY_TYPES_LANG_ca[
                                                      CATEGORY_TYPES
                                                          .TourQuestion
                                                  ]}
                                        </MenuItem>
                                        <MenuItem
                                            value={CATEGORY_TYPES.TourStory}
                                        >
                                            {lang === "kr"
                                                ? CATEGORY_TYPES_LANG_kr[
                                                      CATEGORY_TYPES.TourStory
                                                  ]
                                                : CATEGORY_TYPES_LANG_ca[
                                                      CATEGORY_TYPES.TourStory
                                                  ]}
                                        </MenuItem>
                                        <MenuItem
                                            value={CATEGORY_TYPES.Question}
                                        >
                                            {lang === "kr"
                                                ? CATEGORY_TYPES_LANG_kr[
                                                      CATEGORY_TYPES.Question
                                                  ]
                                                : CATEGORY_TYPES_LANG_ca[
                                                      CATEGORY_TYPES.Question
                                                  ]}
                                        </MenuItem>
                                        <MenuItem value={CATEGORY_TYPES.Other}>
                                            {lang === "kr"
                                                ? CATEGORY_TYPES_LANG_kr[
                                                      CATEGORY_TYPES.Other
                                                  ]
                                                : CATEGORY_TYPES_LANG_ca[
                                                      CATEGORY_TYPES.Other
                                                  ]}
                                        </MenuItem>
                                    </Select>
                                </div>
                                <div className={styles.head}>
                                    <label className={styles.item}>
                                        {t("Title")}
                                    </label>
                                    <input
                                        className={styles.input}
                                        type="text"
                                        value={name}
                                        onChange={handleNameChange}
                                    />
                                </div>
                                {/*
                            <label className={styles.item}>태그: </label>
                            <TagPoolEditor
                                value={tags}
                                addOne={handleAddOneTag}
                                deleteOne={handleDeleteOneTag}
                            /> */}
                                <ReactQuill
                                    value={content}
                                    onChange={handleContentChange}
                                    modules={{
                                        toolbar: selectedQuillToolbar,
                                    }}
                                    style={{
                                        border: "1px solid #7e7e7e",
                                        minHeight: "200px",
                                    }}
                                />
                            </Typography>
                            <div className="modalButtonBar">
                                <button
                                    className="myButton submit extraRightSpace"
                                    onClick={handleSubmit}
                                    style={{ marginTop: "16px" }}
                                >
                                    {t("Post")}
                                </button>
                                <button
                                    className="myButton cancel"
                                    onClick={handleClose}
                                    style={{ marginTop: "16px" }}
                                >
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
);

export default TopicToolBar;
