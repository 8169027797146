import React, { useRef, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { newFormatDate } from "utility/utility";
import styles from "components/Reply/Item.module.css";
import ReactQuill from "react-quill";
import ReactQuillWithTable from "react-quill-with-table";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { sendEmail } from "../../utility/utility";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ReplyItem = ({
    _id,
    user,
    content,
    createdAt,
    updateAlertBanner,
    reloadReplyList,
}) => {
    const quillRef = useRef(null);
    const lang = useSelector((state) => state.app.lang);
    const accessToken = useSelector((state) => state.app.accessToken);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    const username = useSelector((state) => state.app.username);
    const { t } = useTranslation();
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const [newContent, setNewContent] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        // Prepopulate newContent with current content when entering edit mode
        if (isEditMode) {
            setNewContent(content);
        }
    }, [isEditMode, content]);

    const handleDelete = () => {
        setIsDialogOpen(true);
    };

    const handleDeleteConfirmed = () => {
        axios
            .delete(`/api/reply/${_id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.isTopicDeleted) {
                        // 아래를 지워줘야 리다이렉트해서 이게 지워진 리스트를 새로 받아서 보여줌
                        localStorage.removeItem(
                            "forumTopicData-" + response.data.forumId
                        );
                        history.goBack(); // Redirect to the previous route
                    } else {
                        // Show "Deleted" if the response is successful
                        updateAlertBanner({
                            alertOpen: true,
                            alertMsg: t("Deleted"),
                            alertSev: "success",
                        });
                        reloadReplyList();
                    }
                } else {
                    // Handle other successful status codes if needed
                    updateAlertBanner({
                        alertOpen: true,
                        alertMsg: t("NotDeleted"),
                        alertSev: "error",
                    });
                }
            })
            .catch((error) => {
                // Show "NotDeleted" in case of an error
                updateAlertBanner({
                    alertOpen: true,
                    alertMsg: t("NotDeleted"),
                    alertSev: "error",
                });
                console.log(JSON.stringify(error));
            });
    };

    const handleContentChange = (value) => {
        setNewContent(value);
    };

    const handleCancel = () => {
        setIsEditMode(false);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleUpdate = () => {
        setIsEditMode(true);
    };

    const handleUpdateSubmit = async () => {
        try {
            const response = await axios.post(
                `/api/reply/${_id}`,
                {
                    content: newContent,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setIsEditMode(false);
                updateAlertBanner({
                    alertOpen: true,
                    alertMsg: t("Updated"),
                    alertSev: "success",
                });
                reloadReplyList();
                sendEmail(axios, {
                    topicId: "", // Topic ID
                    replyId: _id, // Reply ID
                    subject: "답글이 수정됨",
                    body: `${window.location.href}\n\n${newContent}`,
                    // emailto가 없으면 admin email로 감.
                });
            } else {
                updateAlertBanner({
                    alertOpen: true,
                    alertMsg: t("NotUpdated"),
                    alertSev: "error",
                });
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            updateAlertBanner({
                alertOpen: true,
                alertMsg: t("NotUpdated"),
                alertSev: "error",
            });
        }
    };

    const canEditOrDelete = user && (user.username === username || isSuperUser);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["blockquote"],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                ],
                handlers: {
                    image: () => {
                        const imageUrl = window.prompt("이미지 URL:");
                        if (imageUrl) {
                            const range = quillRef.current
                                .getEditor()
                                .getSelection(true);
                            const index = range.index + range.length;
                            quillRef.current
                                .getEditor()
                                .insertEmbed(index, "image", imageUrl);
                        }
                    },
                },
            },
        }),
        []
    );

    return (
        <div className={styles.replyItemContainer}>
            <div className={styles.title}>
                <Link
                    className={styles.bold_link}
                    to={`/user/${user.username}`}
                >
                    <img
                        alt="Avatar"
                        className={styles.circle_img}
                        src={user.avatarUrl}
                    ></img>
                    {user.username}
                </Link>
                <div className={styles.replyDate}>
                    {newFormatDate(new Date(createdAt), lang)}
                    <span>
                        {canEditOrDelete && (
                            <>
                                {isEditMode ? (
                                    <>
                                        <button
                                            className="myButton cancel thinBtn extraLeftSpace"
                                            onClick={handleCancel}
                                        >
                                            {t("Cancel")}
                                        </button>
                                        <button
                                            className="myButton secondary thinBtn extraRightSpace extraLeftSpace"
                                            onClick={handleUpdateSubmit}
                                        >
                                            {t("Confirm")}
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className="myButton submit thinBtn extraRightSpace extraLeftSpace"
                                        onClick={handleUpdate}
                                    >
                                        {t("Update")}
                                    </button>
                                )}

                                <button
                                    className="myButton delete thinBtn"
                                    onClick={handleDelete}
                                >
                                    {t("Delete")}
                                </button>
                            </>
                        )}
                    </span>
                </div>
            </div>
            {isEditMode ? (
                <>
                    <ReactQuillWithTable
                        ref={quillRef}
                        value={newContent}
                        onChange={handleContentChange}
                        modules={modules}
                    />
                </>
            ) : (
                <>
                    <div className="hideqltoolbar">
                        <ReactQuill
                            value={content}
                            readOnly={true}
                            modules={{
                                toolbar: [],
                            }}
                        />
                    </div>
                </>
            )}
            <Modal
                show={isDialogOpen}
                onHide={handleClose}
                centered
                fade={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("ConfirmDeletion")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ padding: "10px" }}>{t("DeletionMsg")}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirmed}>
                        {t("Delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ReplyItem;
