import React, { useCallback, useState, useRef } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Alert, Button } from "react-bootstrap"; // Import Alert from react-bootstrap
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const { firebaseBaseUrl } = require("../../../config");

const FileUploader = ({ forumId, routeId = null, isSmall = false, setOneRoute }) => {
    const accessToken = useSelector((state) => state.app.accessToken);
    const [latestAccessToken, setAccessToken] = useState(accessToken);
    const [alertMessage, setAlertMessage] = useState(null); // State for alert message
    const [alertVariant, setAlertVariant] = useState("success"); // State for alert variant

    const latestAccessTokenRef = useRef();
    latestAccessTokenRef.current = latestAccessToken;

    const forumIdRef = useRef();
    forumIdRef.current = forumId;

    const onDrop = useCallback(async (acceptedFiles) => {
        for (const file of acceptedFiles) {
            await uploadFile(file);
        }
    }, []);

    const uploadFile = async (file) => {
        if (!latestAccessTokenRef.current) {
            // Set alert message and variant for login message
            setAlertMessage("로그인 하세요.");
            setAlertVariant("danger");
            return false;
        }

        // Read the image file to get its dimensions
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const image = new Image();
                image.src = e.target.result;

                image.onload = async () => {
                    const width = image.width;
                    const height = image.height;

                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("forumId", forumIdRef.current);
                    if (routeId !== null) {
                        formData.append("routeId", routeId);
                    }
                    formData.append("width", width);
                    formData.append("height", height);

                    if (isSmall) {
                        formData.append("appendForumId", true);
                    }

                    const response = await axios.post(
                        `/api/file/upload`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${latestAccessTokenRef.current}`,
                            },
                        }
                    );

                    const uploadedFileName = response.data.fileName;
                    const imageUrlWithBase = firebaseBaseUrl.replace(
                        "{0}",
                        uploadedFileName
                    );

                    if (isSmall && setOneRoute) {
                        // Update the specific route
                        setOneRoute(routeId, uploadedFileName);
                    } else {
                        setAlertMessage(`${imageUrlWithBase}`);
                        setAlertVariant("success");
                    }

                    // Copy the file name to the clipboard
                    navigator.clipboard.writeText(uploadedFileName).then(
                        function () {
                            console.log("File name copied to clipboard");
                        },
                        function (err) {
                            console.error(
                                "Unable to copy file name to clipboard",
                                err
                            );
                        }
                    );
                };
            } catch (error) {
                console.error(`Error uploading ${file.name}:`, error);
                // Set error alert message and variant
                setAlertMessage(
                    `Error uploading ${file.name}: ${error.message}`
                );
                setAlertVariant("danger");
            }
        };

        reader.readAsDataURL(file);
    };

    useEffect(() => {
        setAccessToken(accessToken);
    }, [accessToken]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*",
        multiple: true,
    });

    return (
        <div className={`${styles.dropzoneContainer} ${isSmall ? styles.isSmall : ''}`}>
            {/* Render alert banner based on alertMessage */}
            {alertMessage && (
                <Alert
                    variant={alertVariant}
                    onClose={() => setAlertMessage(null)}
                    dismissible
                >
                    {alertMessage}

                    <Button
                        variant="primary"
                        style={{
                            float: "right",
                            padding: "5px",
                            height: "34px",
                            marginTop: "-6px"
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(alertMessage).then(
                                function () {
                                    console.log(
                                        "File name copied to clipboard"
                                    );
                                },
                                function (err) {
                                    console.error(
                                        "Unable to copy file name to clipboard",
                                        err
                                    );
                                }
                            );
                        }}
                    >
                        URL복사
                    </Button>
                </Alert>
            )}

            {isSmall ? (
                <div {...getRootProps()} className={styles.smdropzone}>
                    <input {...getInputProps()} />
                    이미지 업로드
                </div>
            ) : (<div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                여기로 이미지를 끌어다 놓거나 여기를 클릭해서 이미지를 선택해서업로드하세요.
            </div>)}
        </div>
    );
};

export default FileUploader;
