import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { fitNameToUrl } from "utility/utility";
import styles from "./Item.module.css";
import { newFormatDate } from "utility/utility";
const { forumNameConfig } = require("../../config");

const Item = ({ forumName, forumId, topicName, topicId, createdAt }) => {
    const lang = useSelector((state) => state.app.lang);

    const localizedForumName = forumName.find(
        (item) => item.lang === lang
    )?.txt;

    const formattedCreatedAt = newFormatDate(new Date(createdAt), lang);

    return (
        <div className={styles.container}>
            <div className={styles.small_item}>
                <Link
                    className={styles.link}
                    to={{
                        pathname: `/${forumNameConfig}/${localizedForumName}`, // Use localizedForumName
                        search: "",
                        hash: "",
                        state: {
                            forumId: forumId,
                        },
                    }}
                >
                    {localizedForumName} {/* Render the localized forum name */}
                </Link>
            </div>
            <div className={styles.small_item}>
                <Link
                    className={styles.link}
                    to={{
                        pathname: `/${forumNameConfig}/${localizedForumName}/topic/${fitNameToUrl(
                            topicName
                        )}`,
                        search: "",
                        hash: "",
                        state: {
                            forumId: forumId,
                            topicId: topicId,
                        },
                    }}
                >
                    {topicName}
                </Link>
            </div>

            <div className={styles.small_item_date}>{formattedCreatedAt}</div>
        </div>
    );
};

export default Item;
