export const ESSENTIALS_kr = `
    <div>취소 및 환불 규정</div>
    <p>
    항공권이나 호텔 취소 수수료가 없을시:
    <br><br>
      - 여행시작 30일전까지 취소시: 전액 환불<br>
      - 여행시작 20일전까지 취소시: 결제요금 80% 환불<br>
      - 여행시작 7일전까지 취소시: 결제요금 70% 환불<br>
      - 여행시작 3일전까지 취소시: 결제요금 50% 환불<br>
      - 여행시작 당일취소시: 환불 불가<br>
      <br>
      항공권이나 호텔 취소 수수료를 합산한 금액과 위의 금액 중 더 큰 금액을 제외한 금액이 환불됩니다. 
      호텔이나 항공권 예약 후 취소시 부과되는 페널티는 고객부담입니다. 
      <br><br>
      예를 들어, 500불을 결제하시고 날짜가 확정되면 저희는 항공관이나 호텔을 예약합니다. 여행시작 22일전에 취소하고 항공사나 호텔측에서 예약 취소수수료를 부과하지 않으면 400불을 환불해드립니다.
      하지만, 미리 결제된 항공권과 호텔 취소시 항공사 수수료가 150불이고 호텔 수수료가 50불이면 400불이 아닌 항공사와 호텔 취소 수수료를 제외한 300불을 환불해드립니다. 

    </p>
    <div>호텔 이용</div>
    <p>
        <span>호텔 별로 객실 당 허용되는 최대 숙박 인원은 다르지만, C&K Tour의 상품은 일반적으로 성인 4인 1실 요금으로 구성되어 있습니다.
        호텔은 일반적으로 퀸사이즈 침대 2개로 이루어져 있습니다. 
        3인 미만이거나, 1인 객실 이용을 원하는 경우 담당자에게 문의해 주세요. 대부분의 경우 추가 요금을 지불해야 합니다.</span>
        <span>객실당 최대 허용 인원은 4명으로 제한되며, 캐나다 소방법에 따라 추가 침대 지원이 불가능합니다. 
        단, 유아의 경우 호텔마다 상이하므로 별도 문의 후 추가 비용이 발생할 수 있습니다.</span>
        <span>하우스키퍼에게는 하루에 C$1~$2 정도의 팁을 침대에 놓아 주는 것을 권장합니다.</span>
    </p>
    <div>가이드/기사 팁</div>
    <p>
        여행 가이드 및 기사분의 팁은 1인당 하루 C$15를 권장합니다. 
        예를 들어, 2박 3일 상품의 경우 1인당 권장팁은 C$45입니다.
    </p>
    <div>여행자 보험</div>
    <p>
        여행자 보험은 별도로 구입해야 하며, 저희 여행사는 여행자 보험 대행 업무를 수행하지 않습니다. 
        미리 여행자 보험 가입을 권장합니다.
    </p>
`;

export const ESSENTIALS_ca = `
    <div>Cancellation and Refund Policy</div>
    <p>
If there are no cancellation fees for flights or hotels:
<br><br>
      - Cancellation 30 days before the start of the trip: Full refund<br>
      - Cancellation 20 days before the start of the trip: 80% refund of the payment<br>
      - Cancellation 7 days before the start of the trip: 70% refund of the payment<br>
      - Cancellation 3 days before the start of the trip: 50% refund of the payment<br>
      - Same-day cancellation: No refund<br>
      <br>
      The refund will be the amount excluding the larger of the combined cancellation fees for flights or hotels and the specified amounts above.
      <br><br>
For example, if you have paid $500 and the date is confirmed,
 we will make a reservation for a flight or hotel. 
 If you cancel 22 days before the trip, and there are no cancellation fees imposed by the airline or
  hotel, we will refund $400. However, if we cancel the prepaid flight and hotel, 
  and the airline imposes a fee of $150 and the hotel fee is $50, we will refund $300,
   excluding the cancellation fees for the airline and hotel.
    </p>
    <div>Hotel Usage</div>
    <p>
        <span>Maximum occupancy per room varies by hotel, but C&K Tour's packages are generally priced based on double occupancy. 
        Hotels typically have two Queen-size beds per room, based on double occupancy. 
        If you wish to use a single room, please inquire with the manager for availability on specific dates. 
        In most cases, additional charges may apply.</span>
        <span>If three or four people will be using one room, a discounted rate may apply.</span>
        <span>The maximum allowed occupants per room are two adults and two children, with a total limit of four individuals.
        Additional beds are not supported according to Canadian fire regulations. 
        However, for infants, policies may vary by hotel, so please inquire separately, as additional costs may apply.</span>
        <span>It is recommended to leave a tip of approximately C$1 to C$2 per day for the housekeeper on the bed.</span>
    </p>
    <div>Guide/Driver Tips</div>
    <p>
        Tips for guides and drivers are recommended at C$15 per person per day. 
        For example, for a 2-night, 3-day package, the recommended tip per person would be C$45.
    </p>
    <div>Traveler's Insurance</div>
    <p>
        Traveler's insurance must be purchased separately. Our travel agency does not handle traveler's insurance, so we recommend obtaining it in advance.
    </p>
`;

export const SAFETY_ca = `
  <div>Basic Travel Safety Guidelines</div>
  <p>
    During the travel itinerary and free time, please adhere to the guide's instructions and safety guidelines for your safety.
    Pay special attention to the guide's precautions before participating in optional tours.
    Please be aware that you are responsible for any safety incidents resulting from your own fault or health condition.
    Disregarding guide instructions or participating in activities with anticipated risks, ignoring safety guidelines, is not within our responsibility.
    In Canada, it is a legal requirement for children who weigh less than 36 kg, are shorter than 145 cm, or are under 9 years old to use a car seat. If a car seat or booster is needed, please request it when making a reservation.
  </p>
  <div>Guidelines for Free Time and Independent Travel</div>
  <p>
    <span>
      Any accidents, thefts, or illnesses that occur during individual schedules in free time are not the responsibility of our company.
      This is due to the traveler's voluntary choice, agreements with hotels, and individually contracted terms, which are not included in our travel service.
      Immediate action by our company or guide may not be feasible in such cases.
    </span>
    <span>
      For optional tours, please familiarize yourself with the precautions beforehand and read and sign the waiver.
      Minors, pregnant women, elderly individuals, individuals under the influence of alcohol, and those with certain medical conditions should use the services with the consent of a guardian.
    </span>
    <span>
      For activities involving self-driving such as jet skiing, off-road vehicles, etc., please thoroughly understand the operating instructions before use.
      Accidents resulting from personal health conditions such as heart conditions and safety incidents due to personal negligence during free time are not our responsibility.
    </span>
  </p>
  <div>Disclaimer Agreement Guidelines</div>
  <p>
    Marine sports and some optional tour products involve enjoying thrills, and participants are required to check their health and mental state in advance.
    Participants must sign a disclaimer individually before proceeding with the event.
  </p>
  <div>Smoking Guidelines</div>
  <p>
    Smoking in public places is strictly prohibited.
    Smoking is allowed only in designated areas within buildings, such as restaurants, shopping centers, taxis, hotels, etc.
    Please use designated smoking areas if you must smoke. Smoking inside the hotel may result in a hefty fine.
  </p>
  <div>Drinking Guidelines</div>
  <p>
    In Canada and the United States, you must be at least 21 years old to purchase or consume alcohol.
    Identification is required to buy alcohol. Drinking in public places such as beaches and parks is prohibited in most cases, and violating this rule may lead to legal consequences.
  </p>
  <div>Water Play Guidelines</div>
  <p>
    Swimming after consuming alcohol is highly dangerous (risk of cardiac arrest).
    Avoid eating 1 hour before swimming. Warm-up exercises are essential before swimming.
    Even with low waves, you can be swept away momentarily, so avoid entering deep water without a lifeguard/guide.
    Swimming is prohibited during late hours or when there are no safety personnel (hotel staff).
  </p>
`;

export const SAFETY_kr = `
    <div>기본 여행 안전 수칙</div>
    <p>
        여행 일정 및 자유시간 동안 안전을 위해 안내사의 지침과 안전 수칙을 준수하십시오. 
        선택적인 관광에 참여하기 전에 가이드의 주의사항에 특별한 주의를 기울이십시오. 
        본인의 과실이나 건강 상태로 발생한 안전 사고에 대한 책임은 본인에게 있음을 인식해주십시오. 
        안내사의 지시를 무시하거나 예상된 위험을 감수하고 참여할 때, 해당 사고는 당사의 책임 범위에 포함되지 않습니다.
        캐나다 도로 교통법 상 몸무게 36kg 미만, 키 145cm미만 또는 만 9세 미만의 아동의 카시트 사용은 의무사항입니다. 카시트 및 부스트 필요한 경우, 예약시 요청해 주십시오.
    </p>
    <div>자유시간/자유여행을 위한 지침</div>
    <p>
        <span>자유 시간 동안 개별 일정으로 발생하는 모든 사고, 도난, 질병 등에 대한 책임은 당사가 지지 않습니다. 
        이는 여행자의 자발적인 선택, 호텔과의 계약 사항 등으로 당사의 여행 서비스 범위에 포함되지 않습니다. 
        해당 경우에 당사나 가이드의 즉각적인 조치는 취해질 수 없다는 점을 유의하십시오.</span>
        <span>선택적인 관광을 위해서는 사전에 주의사항을 충분히 숙지하고 면책 동의서를 읽고 서명하십시오. 
        미성년자, 임산부, 노약자, 음주자 등은 보호자의 동의하에만 참여하십시오.</span>
        <span>제트스키등 자가운전을 포함하는 활동을 이용하기 전에는 작동법을 완벽히 숙지하십시오. 
        본인의 건강이 원인인 사고와 자유시간 내 본인의 과실로 인한 안전 사고는 당사가 책임지지 않습니다.</span>
    </p>
    <div>면책 동의서</div>
    <p>
        해양 스포츠 및 일부 선택 관광 상품은 예상치 못한 위험이 동반될 수 있으므로 
        미리 건강 상태와 심리 상태를 점검해야 하며, 행사 진행 시 개별적으로 면책 동의서를 작성해야 합니다.
    </p>
    <div>흡연 주의사항</div>
    <p>
        공공 장소에서의 흡연은 엄격히 금지되어 있습니다. 
        레스토랑, 쇼핑 센터, 택시, 호텔 등 건물 내에서는 흡연이 금지되며, 
        제한된 장소에서만 가능합니다. 
        흡연이 반드시 필요한 경우 지정된 장소의 재떨이를 사용하십시오. 
        호텔 내부에서의 흡연은 벌금이 부과될 수 있으니 주의하십시오.
    </p>
    <div>음주 주의사항</div>
    <p>
        캐나다와 미국에서는 술을 사거나 마시려면 최소 21세 이상이어야 하며, 신분증이 필요합니다. 
        해변가, 공원 등의 공공 장소에서의 음주가 대부분 금지되어 있으므로 주의하십시오.
    </p>
    <div>물놀이 주의사항</div>
    <p>
        음주 후에는 수영이 매우 위험하며(심장 마비 가능성), 
        수영 1시간 전에는 음식을 섭취하지 마십시오. 
        수영 전에는 꼭 준비 운동을 하고, 
        낮은 파도에서도 갑자기 물에 휩쓸릴 수 있으므로 
        안전 요원 또는 가이드 없이는 깊은 물에 들어가지 마십시오.
    </p>
`;
