import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import styles from "./index.module.css";
import AlertBanner from "components/AlertBanner";
import axios from "axios";
import LoadingImg from "../../img/20x20loading.gif";

const EmailVerify = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const accessToken = useSelector((state) => state.app.accessToken);
    const [codeValidity, setCodeValidity] = useState(false);
    const [code, setCode] = useState("");
    const [email, setEmail] = useState(""); // Added email state
    const [alertState, setAlertState] = useState({});
    const [showVerifyButton, setshowVerifyButton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Extract code and email from the URL query string
        const queryParams = new URLSearchParams(window.location.search);
        const codeFromUrl = queryParams.get("code");
        const emailFromUrl = queryParams.get("email");

        if (codeFromUrl && /^\d{0,5}$/.test(codeFromUrl)) {
            // Set the code and validate it if it's a 5-digit number
            setCode(codeFromUrl);
            setCodeValidity(true);
        }

        // Set the email if it exists in the query parameter
        if (emailFromUrl) {
            setEmail(emailFromUrl);
        }
        if (code && email) {
            handleSubmit();
        }
    }, [email]);

    const handleChange = (e) => {
        const value = e.target.value;
        // Ensure the input is a 5-digit number or an empty string
        if (value < 99999) {
            if (
                value !== "" &&
                value >= 0 &&
                value <= 99999 &&
                /^\d{0,5}$/.test(value)
            ) {
                setCode(value);
                setCodeValidity(true);
            } else {
                if (value === "") {
                    setCode(value);
                }
                setCodeValidity(false);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setshowVerifyButton(false);

            const response = await axios.post("/api/user/verify", {
                email,
                code, // Send the code in the request body
            });

            // Check if the request was successful (status code 200-299)
            if (response.status === 200) {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("EMAIL_VERIFICATION_SUCCESS"),
                    alertSev: "success",
                });
            } else {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("EMAIL_VERIFICATION_FAILURE"),
                    alertSev: "error",
                });
            }
        } catch (error) {
            if (error.message.includes("404")) {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("EMAIL_DOES_NOT_EXIST"),
                    alertSev: "error",
                });
            } else if (error.message.includes("409")) {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("EMAIL_ALREADY_VERIFIED"),
                    alertSev: "info",
                });
            } else if (error.message.includes("401")) {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("INCORRECT_CODE"),
                    alertSev: "error",
                });
                setshowVerifyButton(true);
            } else {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("INTERNAL_SERVER_ERROR"),
                    alertSev: "error",
                });
            }

            console.error(
                "에러가 발생했습니다. 문의바랍니다 :",
                JSON.stringify(error)
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.regContainer}>
                <div className={styles.regImgContainer}>
                    <div className={styles.regTitle}>
                        {t("EMAIL_VERIFICATION_TITLE")}
                    </div>
                    <div className={styles.regDesc}>
                        {t("EMAIL_VERIFICATION_DESCRIPTION1")}
                    </div>
                    <div className={styles.regDesc}>
                        {t("EMAIL_VERIFICATION_DESCRIPTION2")}
                    </div>
                </div>
                <AlertBanner
                    type={alertState.alertSev}
                    visibility={alertState.alertOpen}
                    message={alertState.alertMsg}
                />
                <div
                    className={`row align-items-center ${styles.inputDiv} ${styles.top}`}
                >
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("EMAIL_LABEL")}{" "}
                        <span className={styles.mustTxt}>*</span>{" "}
                    </label>
                    <div className="col-sm-10 d-flex">
                        <Form.Control
                            type="text"
                            name="email"
                            placeholder={t("EMAIL_LABEL")}
                            value={email}
                            readOnly
                            style={{ maxWidth: "350px" }}
                        />
                    </div>
                </div>
                <div
                    className={`row align-items-center ${styles.inputDiv} ${styles.top}`}
                >
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("CODE_LABEL")}{" "}
                        <span className={styles.mustTxt}>*</span>{" "}
                    </label>
                    <div className="col-sm-10 d-flex">
                        <Form.Control
                            type="text"
                            name="code"
                            placeholder={t("CODE_LABEL")}
                            value={code}
                            onChange={handleChange}
                            isValid={codeValidity}
                            style={{ maxWidth: "350px" }}
                        />
                    </div>
                </div>
                {showVerifyButton && ( // Conditionally render the button
                    <div className={styles.registerButton}>
                        <button
                            onClick={handleSubmit}
                            disabled={!codeValidity || loading}
                            className={`myButton submit ${
                                !codeValidity ? "disabled" : ""
                            } ${loading ? "loading" : ""}`}
                        >
                            {loading ? (
                                <>
                                    <img
                                        src={LoadingImg}
                                        style={{
                                            verticalAlign: "sub",
                                            height: "18px",
                                        }}
                                        alt={t("VERIFY_EMAIL_SENT_LOADING_ALT")}
                                    />{" "}
                                    {t("VERIFY_EMAIL_SENT_LOADING_TEXT")}
                                </>
                            ) : (
                                t("VERIFY_BUTTON_TEXT")
                            )}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailVerify;
