import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div style={{ paddingTop: "100px", textAlign: "center" }}>
            <h3>{t("NotFoundMessage")}</h3>
        </div>
    );
};

export default NotFound;
