import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Form } from "react-bootstrap";
import styles from "./index.module.css";
import AlertBanner from "components/AlertBanner";
import validator from "validator";
import { sendEmail } from "../../utility/utility";
import LoadingImg from "../../img/20x20loading.gif";
import { INQUIRE_EMAIL_kr, INQUIRE_EMAIL_ca } from "../../tandc";

const ContactUs = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const accessToken = useSelector((state) => state.app.accessToken);
    const [x] = useState(Math.floor(Math.random() * 11));
    const [y] = useState(Math.floor(Math.random() * 11));
    const [subjectValidity, setSubjectValidity] = useState(false);
    const [bodyValidity, setBodyValidity] = useState("");
    const [alertState, setAlertState] = useState({});
    const [spamValidity, setSpamValidity] = useState(false);
    const [emailValidity, setEmailValidity] = useState(false);
    const [answerCorrect, setAnswerCorrect] = useState(false);
    const [hidePlz, setHidePlz] = useState(false);
    const email = useSelector((state) => state.app.email);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        subject: "",
        body: "",
        spamPrevention: "",
    });

    useEffect(() => {
        isSpamAnswerValid();
    }, [formData.spamPrevention]);

    useEffect(() => {
        if (typeof email === "string" && validator.isEmail(email)) {
            setFormData((prevData) => ({
                ...prevData,
                email: email,
            }));
            setEmailValidity(true);
        } else {
            setEmailValidity(false);
        }
    }, [email]);

    const handleHidePlzChange = (e) => {
        setHidePlz(e.target.checked);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "email") {
            setEmailValidity(validator.isEmail(value));
        } else if (name === "subject") {
            setSubjectValidity(value.length >= 2);
        } else if (name === "body") {
            setBodyValidity(value.length >= 10);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setAlertState({
            alertOpen: false,
        });
        console.log("formData.body:" + formData.body);
        let emailBody = formData.body.replace(/\n/g, "<br>");
        emailBody = INQUIRE_EMAIL_kr.replace("{0}", emailBody);
        emailBody = emailBody.replace("{1}", new Date().getFullYear());

        try {
            // 문의자에게 이메일 보내기
            const emailExists = await sendEmail(axios, {
                topicId: "",
                replyId: "",
                subject: "문의가 전달되었습니다",
                body: emailBody,
                emailto: formData.email,
            });
            if (emailExists === 200) {
                // 관리자에게 이메일 보내기
                const emailResponse = await sendEmail(axios, {
                    topicId: "",
                    replyId: "",
                    subject: "[문의하기]",
                    body: formData.body.replace(/\n/g, "<br>"),
                    replyEmail: formData.email,
                });
                if (emailResponse === 200) {
                    setAlertState({
                        alertOpen: true,
                        alertMsg: t("EmailSent"),
                        alertSev: "success",
                    });

                    await axios.post(
                        "/api/forum/654acd419e6ab009408810de/topic", // 문의하기 포럼 ID: 656d428066bd1720bc4bad70
                        {
                            topicName: formData.subject,
                            category: 3, // 3: 질문
                            tags: [],
                            content: formData.body,
                            hidePlz: hidePlz,
                        }
                    );
                    setFormData({
                        subject: "",
                        body: "",
                        spamPrevention: "",
                    });
                    setSubjectValidity(false);
                    setBodyValidity(false);
                    setSpamValidity(false);
                } else {
                    setAlertState({
                        alertOpen: true,
                        alertMsg: t("EmailFailed"),
                        alertSev: "error",
                    });
                }
            } else {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("ConfirmEmail"),
                    alertSev: "error",
                });
            }
        } catch (error) {
            if (error.response.status === 401) {
                alert(t("PlzLogin"));
            } else if (error.response.status === 403) {
                alert(t("WriteLimit"));
            } else if (error.response.status === 422) {
                alert(t("WriteContent"));
            } else {
                alert(error);
            }
            console.error(error);
            setAlertState({
                alertOpen: true,
                alertMsg: t("EmailFailedGenericError") + error.response.status,
                alertSev: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const isSpamAnswerValid = () => {
        const correctAnswer = x + y;
        const nonEmpty =
            formData.spamPrevention && formData.spamPrevention.length > 0;

        if (nonEmpty) {
            if (
                validator.isNumeric(formData.spamPrevention) &&
                parseInt(formData.spamPrevention, 10) === correctAnswer
            ) {
                setSpamValidity(true);
                setAnswerCorrect(true); // Reset answerCorrect if the answer is correct
            } else {
                setSpamValidity(false);
                setAnswerCorrect(false); // Set answerCorrect to true if the answer is incorrect
            }
        } else {
            setAnswerCorrect(true);
        }
    };

    return (
        <>
            <div className={styles.regContainer}>
                <div className={styles.regImgContainer}>
                    <div className={styles.regTitle}>{t("ContactUsTitle")}</div>
                    <div className={styles.regDesc}>
                        {t("ContactUsDescription")}
                    </div>
                    <div className={styles.regDesc}>
                        {t("RealTimeConsultation")}
                    </div>
                </div>
                <AlertBanner
                    type={alertState.alertSev}
                    visibility={alertState.alertOpen}
                    message={alertState.alertMsg}
                />
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("SpamPrevention")}{" "}
                        <span className={styles.mustTxt}>*</span>
                    </label>
                    <div className="col-sm-6 d-flex align-items-center">
                        <span>
                            {x} + {y} =
                        </span>
                        <Form.Control
                            type="text"
                            name="spamPrevention"
                            placeholder={t("EnterNumericAnswer")}
                            value={formData.spamPrevention}
                            onChange={handleChange}
                            isValid={spamValidity}
                            style={{ width: "173px", marginLeft: "5px" }}
                        />
                        {!answerCorrect && (
                            <span className={styles.errorTxt}>
                                {t("IncorrectAnswer")}
                            </span>
                        )}
                    </div>
                    {accessToken && accessToken !== "" && (
                        <>
                            <label className={`col-sm-2 ${styles.valignLabel}`}>
                                {t("PrivatePost")}
                            </label>
                            <div className="col-sm-2">
                                <label className={styles.customLabel}>
                                    <input
                                        type="checkbox"
                                        name="hidePlz"
                                        checked={hidePlz}
                                        onChange={(e) => {
                                            handleHidePlzChange(e);
                                        }}
                                    />{" "}
                                    {t("HideContentInBoard")}
                                </label>
                            </div>
                        </>
                    )}
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("Sender")} <span className={styles.mustTxt}>*</span>
                    </label>
                    <div className="col-sm-10 d-flex">
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={t("Email")}
                            value={formData.email}
                            onChange={handleChange}
                            isValid={emailValidity}
                            style={{ maxWidth: "330px" }}
                        />
                    </div>
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("Subject")} <span className={styles.mustTxt}>*</span>
                    </label>
                    <div className="col-sm-10 d-flex">
                        <Form.Control
                            type="text"
                            name="subject"
                            placeholder={t("MinCharacters", { count: 2 })}
                            value={formData.subject}
                            onChange={handleChange}
                            isValid={subjectValidity}
                        />
                    </div>
                </div>
                <div className={`row align-items-start ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("Content")} <span className={styles.mustTxt}>*</span>
                    </label>
                    <div className="col-sm-10 d-flex">
                        <Form.Control
                            as="textarea"
                            name="body"
                            placeholder={t("MinCharacters", { count: 10 })}
                            value={formData.body}
                            onChange={handleChange}
                            isValid={bodyValidity}
                            style={{ height: "400px" }}
                        />
                    </div>
                </div>
                <div className={styles.registerButton}>
                    <button
                        onClick={handleSubmit}
                        disabled={
                            !emailValidity ||
                            !subjectValidity ||
                            !bodyValidity ||
                            !spamValidity ||
                            loading
                        }
                        className={`myButton submit ${
                            !emailValidity ||
                            !subjectValidity ||
                            !bodyValidity ||
                            !spamValidity
                                ? "disabled"
                                : ""
                        } ${loading ? "loading" : ""}`}
                    >
                        {loading ? (
                            <>
                                <img
                                    src={LoadingImg}
                                    style={{
                                        verticalAlign: "sub",
                                        height: "18px",
                                    }}
                                    alt="loading"
                                />{" "}
                                {t("ContactUs")}
                            </>
                        ) : (
                            t("ContactUs")
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
