import React from "react";
import { hydrate, render } from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./style.css";
import axios from "axios";
import App from "app";
const { serverURI } = require("./config");

// Set the base URL for all Axios requests
axios.defaults.baseURL = serverURI;

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
