import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import { FORUM_CATEGORY_TYPE_ID } from "../../../constants";

/**
 * handler that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, action) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            action();
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

/**
 * CreateForumModal is a modal that used to create a forum
 */
const CreateForumModal = ({ url, visibility, onHide, onSubmit }) => {
    const [name, setName] = useState("");
    const [category, setCategory] = useState("1");
    const lang = useSelector((state) => state.app.lang);
    const accessToken = useSelector((state) => state.app.accessToken);
    const categories = Object.entries(FORUM_CATEGORY_TYPE_ID)
        .filter(([categoryId]) => categoryId !== "0")
        .map(([categoryId, categoryLabel]) => ({
            id: categoryId,
            label: categoryLabel,
        }));

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory);
    };
    const formRef = useRef();
    useOutsideAlerter(formRef, onHide);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let categoryId = category;
            await axios.post(
                url,
                {
                    name: name.trim(),
                    category: category,
                    lang: lang,
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );
            setName("");
            setCategory("1");
            onSubmit();
        } catch (error) {
            if (error.response.status === 401) {
                alert("로그인 하세요.");
            } else if (error.response.status === 409) {
                alert("같은 여행이름이 있습니다. 여행이름을 바꿔주세요.");
            } else {
                alert(error);
            }
            console.error(error);
        }
    };

    return (
        <>
            {visibility ? (
                <div className={styles.container}>
                    <div className={styles.modal} ref={formRef}>
                        <div className={styles.newForumHeader}>
                            새 여행상품 게시하기
                        </div>
                        <label className={styles.item}>
                            여행 상품명("/"가 포함되면 안됨):{" "}
                        </label>
                        <input
                            className={styles.input}
                            type="text"
                            value={name}
                            onChange={handleNameChange}
                        />
                        <label className={styles.item}>카테고리: </label>
                        <select
                            class={styles.inputSelect}
                            value={category}
                            onChange={handleCategoryChange}
                        >
                            {categories.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.label}
                                </option>
                            ))}
                        </select>
                        <div className={styles.createNewForumSubmitDiv}>
                            <button
                                className="myButton submit"
                                onClick={handleSubmit}
                                type="button"
                            >
                                게시하기
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default CreateForumModal;
