export const fitNameToUrl = (name) => {
    if (name) {
        return name
            .replace(/[\s.,'"!?()]+/g, " ")
            .trim()
            .replace(/\s+/g, "-");
    } else {
        return "";
    }
};

export const createAddress = (forumName, topicName) => {
    return "./" + fitNameToUrl(forumName) + topicName !== ""
        ? "/" + fitNameToUrl(topicName)
        : "";
};

export const getColor = (str) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    return "hsl(" + (hash % 360) + ",60%,60%)";
};

const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const todayDate = (addDays) => {
    var today = new Date();
    if (!addDays) addDays = 0;
    today.setDate(today.getDate() + addDays);

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Ensure two-digit month
    const day = today.getDate().toString().padStart(2, "0"); // Ensure two-digit day

    return `${year}-${month}-${day}`;
};

export const formatDate = (now, at) => {
    if (now - at < 86400000) {
        if (now - at > 3600000) {
            return Math.round((now - at) / 3600000) + " h";
        }
        if (now - at > 60000) {
            return Math.round((now - at) / 60000) + " min";
        }
        return Math.round((now - at) / 1000) + " sec";
    } else {
        return monthNames[at.getMonth()] + " '" + at.getDay().toString();
    }
};

export const calendarDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    let month = (1 + date.getUTCMonth()).toString().padStart(2, "0");
    let day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const newFormatDate = (date, lang) => {
    if (!(date instanceof Date)) {
        return "Invalid Date";
    }

    const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Use 12-hour clock
    };
    /*
    if (!lang) lang = "ko-KR";
    else if (lang === "kr") {
        lang = "ko-KR";
    } else if (lang === "ca") {
        lang = "en-US";
    }
    */

    return date.toLocaleDateString("en-US", options);
};

export const sendEmail = async (axiosInstance, emailParams) => {
    const {
        topicId,
        replyId,
        subject,
        body,
        emailto = "",
        replyEmail = "",
    } = emailParams;
    let result = 500;
    try {
        const response = await axiosInstance.post("/api/email", {
            emailto: emailto,
            subject: subject,
            html: body,
            topicId: topicId,
            replyId: replyId,
            replyEmail: replyEmail,
        });
        result = response.status;
        if (response.status === 200) {
            console.log("이메일을 보냈습니다.");
        } else {
            console.error(
                "이메일을 못 보냈습니다. response.status: " + response.status
            );
        }
    } catch (error) {
        console.error("이메일을 못 보냈습니다. error: ", error);
    }
    return result;
};

export const formatPrice = (price, status, discount, inquire) => {
    let formattedPrice = price;

    const DISCOUNTED_PRODUCT = 3;
    const priceDeducted = status === DISCOUNTED_PRODUCT && discount > 0;

    if (priceDeducted) {
        const discountedPrice =
            formattedPrice + formattedPrice * (discount / 100);
        formattedPrice = `C$ ${discountedPrice.toFixed(0)}`; // Format to two decimal places
    } else {
        formattedPrice =
            formattedPrice > 0 ? `C$ ${formattedPrice.toFixed(0)}` : inquire;
    }

    return formattedPrice;
};
