import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdminHeader from "components/AdminHeader";
import styles from "./index.module.css";
const { firebaseBaseUrl } = require("../../config");

const AdminImage = () => {
    const { forumId } = useParams();
    const [imageUrls, setImageUrls] = useState([]);
    const [originalImageUrls, setOriginalImageUrls] = useState([]);
    const accessToken = useSelector((state) => state.app.accessToken);

    useEffect(() => {
        const fetchImageUrls = async () => {
            try {
                const response = await axios.get(
                    `/api/forum/getImg/${forumId}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );
                if (response.status === 200) {
                    const resultUrls = response.data.allImageUrls || [];
                    // Use a Set to store unique values
                    const uniqueUrlsSet = new Set(resultUrls);

                    // Convert Set back to an array
                    const allImageUrls = Array.from(uniqueUrlsSet);

                    if (allImageUrls && allImageUrls.length > 0) {
                        const updatedImageUrls = allImageUrls.map((url) => {
                            // Check if the URL doesn't already contain "http"
                            if (!url.includes("http")) {
                                // Update the URL using firebaseBaseUrl
                                return firebaseBaseUrl.replace("{0}", url);
                            }
                            // If the URL already contains "http", keep it unchanged
                            return url;
                        });

                        setImageUrls(updatedImageUrls);
                        setOriginalImageUrls(allImageUrls);
                    }
                }
            } catch (error) {
                //alert("이미지 로딩에 실패했습니다: " + error);
            }
        };

        fetchImageUrls();
    }, [accessToken]);

    const handleDelete = (fileName) => {
        if (!accessToken) {
            alert("로그인 하세요.");
            return false;
        }

        if (window.confirm("정말 " + fileName + "를 삭제할까요?") === true) {
            axios
                .post(
                    `/api/file/delete`,
                    { forumId: forumId, filename: fileName }, // forumId 가 전해지긴 하지만 모든 포럼에서 URL을 없애니 server에서 사용안됨.
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => {
                    if (response.status === 200) {
                        // Update imageUrls by removing the deleted image
                        setImageUrls((prevImageUrls) =>
                            prevImageUrls.filter(
                                (url) => url.indexOf(fileName) === -1
                            )
                        );
                    }
                })
                .catch((error) => {
                    alert("이미지 삭제에 실패했습니다: " + error);
                });
        }
    };

    const copyToClipboard = (link) => {
        // Clear the existing clipboard contents
        navigator.clipboard
            .writeText("")
            .then(() => {
                // Copy the new link to the clipboard
                return navigator.clipboard.writeText(link);
            })
            .then(() => {})
            .catch((error) => {
                console.error("Failed to copy to clipboard:", error);
            });
    };

    return (
        <div className={styles.adminImageContainer}>
            <AdminHeader forumId={forumId} />
            <div className={styles.adminImageSubHeader}>
                이 여행상품에 사용된 이미지들
            </div>
            {imageUrls.map((imageUrl, index) => {
                const fileName = imageUrl
                    .replace("?alt=media", "")
                    .split("/")
                    .pop();

                const originalUrl = originalImageUrls[index]; // Get the corresponding updated URL

                return (
                    <div key={index} style={{ padding: "5px 10px" }}>
                        {imageUrl.includes(
                            "firebasestorage.googleapis.com"
                        ) && (
                            <button
                                className="myButton delete thinBtn extraRightSpace"
                                onClick={() => handleDelete(fileName, imageUrl)}
                            >
                                삭제
                            </button>
                        )}
                        <button
                            className="myButton secondary thinBtn extraRightSpace"
                            onClick={() => copyToClipboard(originalUrl)}
                            style={{ marginRight: "10px" }}
                        >
                            Url 복사
                        </button>
                        <img
                            src={imageUrl}
                            alt={`Image ${index + 1}`}
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                        <span style={{ marginLeft: "10px" }}>
                            {originalUrl}
                        </span>
                    </div>
                );
            })}
            {imageUrls.length === 0 ? "사용된 이미지가 없습니다." : ""}
        </div>
    );
};

export default AdminImage;
