import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import FileUploader from "components/FileHandling/FileUpload";
import { useParams } from "react-router-dom";
import SlideImage from "components/FileHandling/SlideImage";
import { useHistory } from "react-router-dom"; // Import useHistory from react-router-dom
import styles from "./index.module.css";
import ReactQuillWithTable from "react-quill-with-table";
import { FORUM_CATEGORY_TYPE_ID, TOUR_STATUS } from "../../constants";
import AdminHeader from "components/AdminHeader";
import { calendarDate } from "../../utility/utility";

const Admin = () => {
    const quillRef = useRef(null);
    const accessToken = useSelector((state) => state.app.accessToken);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    const [editedName, setEditedName] = useState("");
    const [editedDesc, setEditedDesc] = useState("");
    const [editedPrice, setEditedPrice] = useState("");
    const [editedRoute, setEditedRoute] = useState("");
    const [discountRate, setDiscountRate] = useState("");
    const [editedTripDays, setEditedTripDays] = useState("");
    const [editedMinPerson, setEditedMinPerson] = useState("");
    const [forumContent, setForumContent] = useState("");
    const [imgUrls, setImgUrls] = useState("");
    const [forumData, setForumData] = useState(null);
    const { forumId } = useParams();
    const lang = useSelector((state) => state.app.lang);
    const [joins, setJoins] = useState([]);
    const [newJoin, setNewJoin] = useState({
        male: "",
        female: "",
        startDate: "",
        endDate: "",
        remarks: "",
    });

    // 조인
    // Function to add a new join data
    const handleAddJoin = () => {
        setJoins([...joins, newJoin]);
        // Reset the newJoin state to clear the input fields
        setNewJoin({
            male: "",
            female: "",
            startDate: "",
            endDate: "",
            remarks: "",
        });
    };

    // Function to remove a join data
    const handleRemoveJoin = (index) => {
        const updatedJoins = [...joins];
        updatedJoins.splice(index, 1);
        setJoins(updatedJoins);
    };

    // Function to handle changes in input fields for new join data
    const handleNewJoinChange = (e) => {
        const { name, value } = e.target;
        setNewJoin((prevJoin) => ({
            ...prevJoin,
            [name]: value,
        }));
    };

    // Function to handle changes in input fields for existing join data
    const handleEditJoinChange = (index, e) => {
        const { name, value } = e.target;
        const updatedJoins = [...joins];
        updatedJoins[index] = {
            ...updatedJoins[index],
            [name]: value,
        };
        setJoins(updatedJoins);
    };

    // 카테고리
    const defaultCategoryId = 1;
    const [categoryId, setCategoryId] = useState(defaultCategoryId); // Add this line
    const handleCategoryChange = (event) => {
        setCategoryId(event.target.value); // Change here
    };
    const categories = Object.entries(FORUM_CATEGORY_TYPE_ID)
        .filter(([categoryId]) => categoryId !== "0")
        .map(([categoryId, categoryLabel]) => ({
            id: categoryId,
            label: categoryLabel,
        }));

    // 상품종류
    const [tourtype, setTourType] = useState(1);
    const tourtypes = Object.entries(TOUR_STATUS).map(
        ([tourtypeid, tourtype]) => ({
            id: tourtypeid,
            label: tourtype,
        })
    );
    const handleTourTypeChange = (event) => {
        setTourType(event.target.value);
    };

    // Function to update fields based on language code
    const updateFieldsByLangCode = (data) => {
        const langCode = lang;
        const name = data.name.find((item) => item.lang === langCode)?.txt;
        setEditedName(name);

        const description = data.description.find(
            (item) => item.lang === langCode
        )?.txt;
        setEditedDesc(description);

        let bulletin = "";
        if (data.bulletin && data.bulletin.length > 0) {
            bulletin = data.bulletin.find(
                (item) => item.lang === langCode
            )?.txt;
        }
        if (bulletin) {
            setForumContent(bulletin);
        }
        setEditedPrice(data.price);

        if (data.route) {
            const route = data.route.find(
                (item) => item.lang === langCode
            )?.txt;
            setEditedRoute(route);
        }

        setCategoryId(data.categoryId || defaultCategoryId);

        let discountRate = 0;
        if (data.discount) discountRate = data.discount;
        setDiscountRate(discountRate);

        setImgUrls(data.imageUrls.map((img) => img.url));
        setTourType(data.status);
        setEditedMinPerson(data.minPerson);
        setEditedTripDays(data.tripDays);
        setJoins(data.joins || []);
    };

    const getForumData = async () => {
        try {
            // Get forum bulletin
            const response = await axios.get(`/api/forum/byId/${forumId}`);

            if (response.status === 200) {
                if (response.data) {
                    updateFieldsByLangCode(response.data);
                    setForumData(response.data); // Save entire forum data so if lang changes, show the change right away.
                }
            } else {
                // Handle other status codes, e.g., non-200 responses
                alert("여행데이터를 불러올 수 없음");
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (forumData) {
            updateFieldsByLangCode(forumData);
        }
    }, [lang, forumData]);

    useEffect(() => {
        getForumData();
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["blockquote"],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                ],
                handlers: {
                    image: () => {
                        const imageUrl = window.prompt("이미지 URL:");
                        if (imageUrl) {
                            const range = quillRef.current
                                .getEditor()
                                .getSelection(true);
                            const index = range.index + range.length;
                            quillRef.current
                                .getEditor()
                                .insertEmbed(index, "image", imageUrl);
                        }
                    },
                },
            },
        }),
        []
    );

    const history = useHistory(); // Get the history object from react-router-dom

    const endEditing = async () => {
        try {
            // Filter out empty join arrays and those without valid male or female data
            const nonEmptyValidJoins = joins.filter((join) => {
                // Check if any value in the join object is not empty and male or female data is greater than 0
                return (
                    Object.values(join).some((value) => value !== "") &&
                    (join.male > 0 || join.female > 0)
                );
            });

            // Check if newJoin contains any data
            const newJoinHasData = Object.values(newJoin).some(
                (value) => value !== "" && (value.male > 0 || value.female > 0)
            );

            // Add newJoin only if it has data
            const updatedJoinsData = newJoinHasData
                ? [...nonEmptyValidJoins, newJoin]
                : nonEmptyValidJoins;

            const response = await axios.post(
                `/api/forum/update`,
                {
                    forumId: forumId,
                    lang: lang,
                    status: tourtype,
                    name: editedName,
                    price: editedPrice,
                    description: editedDesc,
                    bulletin: forumContent,
                    minPerson: editedMinPerson,
                    tripDays: editedTripDays,
                    discount: discountRate,
                    route: editedRoute,
                    categoryId: categoryId,
                    joins: updatedJoinsData,
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            if (response.status === 200) {
                alert("여행정보 수정됨");
                //window.location.reload();
            }
        } catch (error) {
            // Handle any errors here, e.g., display an error message
            console.error(error);
            alert("여행정보 수정 실패");
        }
    };

    if (!isSuperUser) {
        return (
            <div style={{ margin: "50px", fontWeight: "800" }}>
                관리자만 허가된 페이지입니다.
            </div>
        );
    }

    const handleMoveJoin = (index, direction) => {
        const updatedJoins = [...joins];
        const newIndex = direction === "up" ? index - 1 : index + 1;

        if (newIndex < 0 || newIndex >= updatedJoins.length) {
            return; // Do nothing if trying to move beyond array boundaries
        }

        const temp = updatedJoins[index];
        updatedJoins[index] = updatedJoins[newIndex];
        updatedJoins[newIndex] = temp;

        setJoins(updatedJoins);
    };

    return (
        <div className={styles.adminContainer}>
            <AdminHeader forumId={forumId} />
            <div className={styles.subHeader}>이미지 슬라이더</div>
            <SlideImage forumId={forumId} imgUrls={imgUrls} />
            <div style={{ margin: "10px" }}>
                * 아래상자를 이용해서 여행페이지 젤위에 이미지 슬라이더에 추가할
                이미지를 추가하세요.
            </div>
            <FileUploader forumId={forumId} />

            <div className={styles.subHeader}>여행 필수내용</div>
            <div style={{ marginTop: "15px", maxWidth: "80em" }}>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>카테고리: </span>
                    <select
                        class={styles.inputSelect}
                        value={categoryId}
                        onChange={handleCategoryChange}
                    >
                        {categories.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                                {cat.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>상품종류: </span>
                    <select
                        class={styles.inputSelect}
                        value={tourtype}
                        onChange={handleTourTypeChange}
                    >
                        {tourtypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                    &nbsp;&nbsp;("숨김" 선택시 이 상품은 관리자이외에는 볼수가
                    없습니다.)
                </div>
                {tourtype.toString() === "3" && (
                    <div className={styles.titleDiv}>
                        <span className={styles.title}>할인율: </span>
                        <input
                            type="text"
                            style={{ width: "60px" }}
                            value={discountRate}
                            onChange={(e) => setDiscountRate(e.target.value)}
                        />
                        %
                    </div>
                )}
                <div className={styles.titleDiv}>
                    <span className={styles.title}>여행 제목: </span>
                    <input
                        type="text"
                        style={{ width: "400px" }}
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                    />
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>간단한 설명: </span>
                    <input
                        type="text"
                        style={{ width: "600px" }}
                        value={editedDesc}
                        onChange={(e) => setEditedDesc(e.target.value)}
                    />
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>최소 출발가능 인원수: </span>
                    <input
                        type="text"
                        style={{ width: "60px" }}
                        value={editedMinPerson}
                        onChange={(e) => setEditedMinPerson(e.target.value)}
                    />
                    명
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>여행일수: </span>
                    <input
                        type="text"
                        style={{ width: "60px" }}
                        value={editedTripDays}
                        onChange={(e) => setEditedTripDays(e.target.value)}
                    />
                    일
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>일정: </span>
                    <input
                        type="text"
                        style={{ width: "600px" }}
                        value={editedRoute}
                        onChange={(e) => setEditedRoute(e.target.value)}
                    />
                </div>
                <div className={styles.titleDiv}>
                    <span className={styles.title}>가격: </span>
                    <input
                        type="number"
                        style={{ width: "70px" }}
                        value={editedPrice}
                        onChange={(e) => setEditedPrice(e.target.value)}
                    />{" "}
                    ("문의하세요"라고 표시하고 싶으면 0을 입력하세요.)
                </div>

                <div className={styles.subHeader}>현재 예약팀</div>
                <div>
                    {/* Render existing join data */}
                    {joins.map((join, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                            <button onClick={() => handleMoveJoin(index, "up")}>
                                &#9650;
                            </button>
                            <button
                                onClick={() => handleMoveJoin(index, "down")}
                            >
                                &#9660;
                            </button>
                            <span style={{ marginLeft: "5px" }}>출발일</span>
                            <input
                                type="date"
                                name="startDate"
                                style={{ width: "105px", marginLeft: "5px" }}
                                value={calendarDate(join.startDate)}
                                onChange={(e) => handleEditJoinChange(index, e)}
                            />
                            <span style={{ marginLeft: "5px" }}>도착일</span>
                            <input
                                type="date"
                                name="endDate"
                                style={{ width: "105px", marginLeft: "5px" }}
                                value={calendarDate(join.endDate)}
                                onChange={(e) => handleEditJoinChange(index, e)}
                            />
                            <input
                                type="number"
                                name="male"
                                style={{ width: "90px", marginLeft: "5px" }}
                                value={join.male}
                                onChange={(e) => handleEditJoinChange(index, e)}
                            />
                            <input
                                type="number"
                                name="female"
                                style={{ width: "90px", marginLeft: "5px" }}
                                value={join.female}
                                onChange={(e) => handleEditJoinChange(index, e)}
                            />
                            <input
                                type="text"
                                name="remarks"
                                style={{ width: "400px", marginLeft: "5px" }}
                                value={join.remarks}
                                onChange={(e) => handleEditJoinChange(index, e)}
                            />

                            <button onClick={() => handleRemoveJoin(index)}>
                                지우기
                            </button>
                        </div>
                    ))}

                    {/* Render input fields for adding new join data */}
                    <div style={{ marginBottom: "10px" }}>
                        출발일
                        <input
                            type="date"
                            name="startDate"
                            placeholder="Start Date"
                            style={{ width: "105px", marginLeft: "5px" }}
                            value={newJoin.startDate}
                            onChange={handleNewJoinChange}
                        />
                        <span style={{ marginLeft: "5px" }}>도착일</span>
                        <input
                            type="date"
                            name="endDate"
                            placeholder="End Date"
                            style={{ width: "105px", marginLeft: "5px" }}
                            value={newJoin.endDate}
                            onChange={handleNewJoinChange}
                        />
                        <input
                            type="number"
                            name="male"
                            placeholder="남자 인원수"
                            style={{ width: "90px", marginLeft: "5px" }}
                            value={newJoin.male}
                            onChange={handleNewJoinChange}
                        />
                        <input
                            type="number"
                            name="female"
                            placeholder="여자 인원수"
                            style={{ width: "90px", marginLeft: "5px" }}
                            value={newJoin.female}
                            onChange={handleNewJoinChange}
                        />
                        <input
                            type="text"
                            style={{ width: "400px", marginLeft: "5px" }}
                            name="remarks"
                            placeholder="특이사항"
                            value={newJoin.remarks}
                            onChange={handleNewJoinChange}
                        />
                        <button onClick={handleAddJoin}>추가하기</button>
                    </div>
                </div>

                <div style={{ padding: "0 0 20px 0", textAlign: "center" }}>
                    <button
                        className="myButton submit extraRightSpace"
                        onClick={endEditing}
                    >
                        수정완료
                    </button>
                </div>

                <div className={styles.subHeader}>여행 설명 페이지</div>
                <div style={{ margin: "10px" }}>
                    * 아래상자를 이용해서 여행 설명페이지에 추가할 이미지를
                    추가하세요.
                    <FileUploader forumId={0} />
                </div>
                <ReactQuillWithTable
                    ref={quillRef}
                    value={forumContent}
                    onChange={(content) => setForumContent(content)}
                    modules={modules}
                    style={{ border: "1px solid #c0c0c0" }}
                />
                <div style={{ padding: "10px", textAlign: "center" }}>
                    <button
                        className="myButton submit extraRightSpace"
                        onClick={endEditing}
                    >
                        수정완료
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Admin;
