import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    ESSENTIALS_kr,
    ESSENTIALS_ca,
} from "../../components/Bulletin/constants";
import "./Footer.css";

const Attention = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const ESSENTIALS = lang === "kr" ? ESSENTIALS_kr : ESSENTIALS_ca;

    return (
        <>
            <div className="contentContainer">
                <div className="contentTitle">{t("Attention")}</div>
                <div
                    className="explDiv"
                    dangerouslySetInnerHTML={{
                        __html: ESSENTIALS,
                    }}
                />
            </div>
        </>
    );
};

export default Attention;
