import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "./index.module.css";
import axios from "axios";
const { firebaseBaseUrl } = require("../../../config");

const SlideImage = ({ forumId, imgUrls }) => {
    const accessToken = useSelector((state) => state.app.accessToken);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);

    const [images, setImages] = useState([]); // State to hold the images

    // Function to construct the images array
    const constructImagesArray = (imageUrls) => {
        return imageUrls.map((url) => {
            const imageUrlWithBase = firebaseBaseUrl.replace("{0}", url);
            return {
                original: imageUrlWithBase,
                thumbnail: imageUrlWithBase, // Use the same URL for thumbnail
            };
        });
    };

    useEffect(() => {
        if (!imgUrls) return;

        const updatedImages = constructImagesArray(imgUrls);
        setImages(updatedImages);
    }, [imgUrls]);

    const makeImgPrimary = (url) => {
        if (!accessToken) {
            alert("로그인 하세요.");
            return false;
        }

        const fileName = extractFilenameFromURL(url);
        axios
            .post(
                `/api/forum/mainImg`,
                { forumId: forumId, mainImageUrl: fileName },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then((response) => {
                if (response.status === 200) {
                    alert("성공적으로 메인 이미지로 등록되었습니다.");
                }
            })
            .catch((error) => {
                console.error("메인 이미지 등록에 실패했습니다.");
                console.error(error);
            });
    };

    const extractFilenameFromURL = (url) => {
        let fileName = "";
        try {
            const urlObject = new URL(url);
            const pathname = urlObject.pathname;
            const parts = pathname.split("/");
            fileName = parts[parts.length - 1];
        } catch (error) {
            alert("filename을 url에서 추출도중 에러가 났습니다 : " + error);
        }
        return fileName;
    };

    const deleteImg = (url) => {
        if (!accessToken) {
            alert("로그인 하세요.");
            return false;
        }

        const fileName = extractFilenameFromURL(url);
        if (window.confirm("정말 " + fileName + "를 삭제할까요?") === true) {
            axios
                .post(
                    `/api/file/delete`,
                    { forumId: forumId, filename: fileName },
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => {
                    if (response.status === 200) {
                        // Display an alert if the response status is 200
                        alert("이미지가 삭제되었습니다.");
                        // Reload the page
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("이미지 삭제에 실패했습니다.");
                    console.error(error);
                });
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const deleteOrPromoteImage = (image) => {
        if (isSuperUser) {
            setSelectedImage(image);
        }
    };

    const handleChoice = (choice, forumId, fileName) => {
        setSelectedImage(null); // Close the modal
        //console.log(forumId + ` Promote clicked for file: ${fileName}`);
        if (choice === "promote") {
            makeImgPrimary(fileName);
        } else if (choice === "delete") {
            deleteImg(fileName);
        }
    };

    return (
        <>
            <div className={styles.galleryContainer}>
                <ImageGallery
                    items={images}
                    showIndex={true}
                    showFullscreenButton={false}
                    onErrorImageURL={"이미지 없음"}
                    slideDuration={300}
                    autoPlay={false}
                    onClick={(event) => deleteOrPromoteImage(event.target.src)}
                />
            </div>
            {selectedImage && (
                <div className={styles.modal}>
                    <p style={{ marginBottom: "20px" }}>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ maxWidth: "100%", height: "auto" }}
                        />
                    </p>
                    <button
                        className="myButton submit extraRightSpace"
                        onClick={() =>
                            handleChoice("promote", forumId, selectedImage)
                        }
                    >
                        메인이미지로 설정하기
                    </button>
                    <button
                        className="myButton delete extraRightSpace"
                        onClick={() =>
                            handleChoice("delete", forumId, selectedImage)
                        }
                    >
                        지우기
                    </button>
                    <button
                        className="myButton cancel"
                        onClick={() => handleChoice("cancel")}
                    >
                        취소
                    </button>
                </div>
            )}
        </>
    );
};

export default SlideImage;
