import React, {
    useEffect,
    useState,
    useRef,
    createRef,
    useCallback,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import FileUploader from "components/FileHandling/FileUpload";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import AdminHeader from "components/AdminHeader";
import ReactQuill from "react-quill";
const { quillToolBarFullAccess } = require("../../config");

const AdminRoute = () => {
    const accessToken = useSelector((state) => state.app.accessToken);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    const [forumData, setForumData] = useState(null);
    const { forumId } = useParams();
    const lang = useSelector((state) => state.app.lang);
    const [includedData, setIncludedData] = useState(Array(15).fill(""));
    const [excludedData, setExcludedData] = useState(Array(15).fill(""));
    const [routes, setRoutes] = useState([
        {
            id: 1,
            lang: lang,
            min: 0,
            isPic: false,
            url: "",
            title: "",
            body: "",
        },
    ]);

    const handleQuillChange = useCallback(
        (value, index) => {
            setRoutes((prevRoutes) => {
                const updatedRoutes = [...prevRoutes];
                updatedRoutes[index] = {
                    ...prevRoutes[index],
                    body: value,
                };
                return updatedRoutes;
            });
        },
        [setRoutes]
    );

    const setOneRoute = (routeId, fileName) => {
        setRoutes((prevRoutes) => {
            const index = prevRoutes.findIndex((r) => r.id === routeId);

            if (index !== -1) {
                return [
                    ...prevRoutes.slice(0, index),
                    { ...prevRoutes[index], url: fileName, isPic: true },
                    ...prevRoutes.slice(index + 1),
                ];
            }

            return prevRoutes;
        });
    };

    const copyToClipboard = (link) => {
        // Clear the existing clipboard contents
        navigator.clipboard
            .writeText("")
            .then(() => {
                // Copy the new link to the clipboard
                return navigator.clipboard.writeText(link);
            })
            .then(() => {
                //alert("유투브 링크 복사됨");
            })
            .catch((error) => {
                console.error("Failed to copy to clipboard:", error);
                //alert("복사 실패");
            });
    };

    useEffect(() => {
        if (forumData) {
            updateFieldsByLangCode(forumData);
        }
    }, [lang, forumData]);

    useEffect(() => {
        setRoutes((prevRoutes) =>
            prevRoutes.map((route) => ({
                ...route,
                lang: lang,
            }))
        );
    }, [lang]);

    useEffect(() => {
        getExtraForumData();
    }, []);

    const includedRefs = useRef(
        Array(15)
            .fill(null)
            .map(() => createRef())
    );
    const excludedRefs = useRef(
        Array(15)
            .fill(null)
            .map(() => createRef())
    );

    const updateInexcludeData = (data, lang) => {
        if (data && data.inexclude) {
            // Find the array with the specified lang
            const includedLangArray =
                (data.inexclude.find((value) => value.lang === lang) || {})
                    .included || Array(15).fill("");
            const excludedLangArray =
                (data.inexclude.find((value) => value.lang === lang) || {})
                    .excluded || Array(15).fill("");

            setIncludedData(includedLangArray);
            setExcludedData(excludedLangArray);
        }
    };

    // Function to update fields based on language code
    const updateFieldsByLangCode = (data) => {
        updateInexcludeData(data, lang);
        if (data && data.routeDetail && data.routeDetail.length > 0) {
            // Filter routes based on langCode
            const routeDetails = (data.routeDetail || []).filter(
                (route) => route.lang === lang
            );
            setRoutes(routeDetails);
        }
    };

    const getExtraForumData = async () => {
        try {
            // Get forum bulletin
            const response = await axios.get(`/api/forum/extra/${forumId}`);
            if (response.status === 200) {
                if (response.data) {
                    // Extract route details from the response and set in the state
                    if (
                        response.data.routeDetail &&
                        response.data.routeDetail.length > 0
                    ) {
                        const routeDetails = (
                            response.data.routeDetail || []
                        ).filter((route) => route.lang === lang);
                        setRoutes(routeDetails);
                    } else {
                        const defaultRoutes = Array.from(
                            { length: 5 },
                            (_, index) => ({
                                id: index + 1,
                                lang: lang,
                                min: 0,
                                isPic: false,
                                url: "",
                                title: "",
                                body: "",
                            })
                        );
                        setRoutes(defaultRoutes);
                    }

                    updateFieldsByLangCode(response.data);
                    setForumData(response.data); // Save entire forum data so if lang changes, show the change right away.
                }
            } else {
                // Handle other status codes, e.g., non-200 responses
                alert("여행데이터를 불러올 수 없음");
            }
        } catch (error) {}
    };

    const deleteAllForumRoute = async () => {
        const fileDeleteRes = await axios.post(
            `/api/file/deleteByForumId `,
            {
                forumId: forumId,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        if (fileDeleteRes.status === 200) {
            alert("모든 일정이 삭제되었습니다.");
            window.location.reload();
        } else {
            alert("일정 삭제에 실패했습니다.");
        }
    };

    const endEditing = async () => {
        try {
            const includedDataToSend = includedRefs.current
                .map((ref) => ref.current.value)
                .filter((value) => value.trim() !== ""); // Remove empty strings

            const excludedDataToSend = excludedRefs.current
                .map((ref) => ref.current.value)
                .filter((value) => value.trim() !== ""); // Remove empty strings

            const routesToSend = routes
                .filter((route) => route.lang === lang) // Filter routes by langCode
                .map((route) => ({
                    ...route,
                    title: (route.title || "").trim(), // Access title and trim it
                    body: (route.body || "").trim(), // Access body and trim it
                }))
                .filter((route) => route.title !== ""); // Filter routes with non-empty titles

            const response = await axios.post(
                `/api/forum/updateextra`,
                {
                    forumId: forumId,
                    lang: lang,
                    included: includedDataToSend,
                    excluded: excludedDataToSend,
                    routeDetail: routesToSend,
                },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            if (response.status === 200) {
                alert("여행정보 수정됨");
                //window.location.reload();
            }
        } catch (error) {
            // Handle any errors here, e.g., display an error message
            console.error(error);
            alert("여행정보 수정 실패");
        }
    };

    if (!isSuperUser) {
        return (
            <div style={{ margin: "50px", fontWeight: "800" }}>
                관리자만 허가된 페이지입니다.
            </div>
        );
    }
    const addRouteAfter = (index) => {
        setRoutes((prevRoutes) => {
            const newRoute = {
                id: prevRoutes.length + 1,
                lang: lang,
                min: 0,
                isPic: false,
                url: "",
                title: "",
                body: "",
            };

            const updatedRoutes = [
                ...prevRoutes.slice(0, index + 1),
                newRoute,
                ...prevRoutes.slice(index + 1),
            ];

            // Update indices of routes
            const routesWithUpdatedIndices = updatedRoutes.map((route, i) => ({
                ...route,
                id: i + 1,
            }));

            return routesWithUpdatedIndices;
        });
    };

    const removeRoute = (index) => {
        setRoutes((prevRoutes) => {
            const updatedRoutes = [
                ...prevRoutes.slice(0, index),
                ...prevRoutes.slice(index + 1),
            ];

            // Update indices of routes
            const routesWithUpdatedIndices = updatedRoutes.map((route, i) => ({
                ...route,
                id: i + 1,
            }));

            return routesWithUpdatedIndices;
        });
    };

    const addRemoveButtons = (index) => (
        <>
            &nbsp;
            <button
                onClick={() => addRouteAfter(index)}
                className="myButton secondary thinBtn extraRightSpace"
            >
                {" "}
                추가{" "}
            </button>
            <button
                onClick={() => removeRoute(index)}
                className="myButton delete thinBtn"
            >
                {" "}
                삭제{" "}
            </button>
        </>
    );

    return (
        <>
            <div className={styles.adminContainer}>
                <AdminHeader forumId={forumId} />
                <div className={styles.subHeader}>
                    포함/불포함 항목 수정하기
                </div>
                {/* Included column */}
                <div>
                    <div style={{ fontWeight: "bold" }}>포함항목</div>
                    {Array.from({ length: 15 }).map((_, index) => (
                        <input
                            key={`included_${index}`}
                            type="text"
                            ref={includedRefs.current[index]}
                            value={includedData[index]}
                            className={styles.inExInput}
                            onChange={(e) => {
                                const newData = [...includedData];
                                newData[index] = e.target.value;
                                setIncludedData(newData);
                            }}
                        />
                    ))}
                </div>

                {/* Excluded column */}
                <div style={{ marginTop: "10px" }}>
                    <div style={{ fontWeight: "bold" }}>불포함항목</div>
                    {Array.from({ length: 15 }).map((_, index) => (
                        <input
                            key={`excluded_${index}`}
                            type="text"
                            ref={excludedRefs.current[index]}
                            value={excludedData[index]}
                            className={styles.inExInput}
                            onChange={(e) => {
                                const newData = [...excludedData];
                                newData[index] = e.target.value;
                                setExcludedData(newData);
                            }}
                        />
                    ))}
                </div>

                <div className={styles.buttonDiv}>
                    <button
                        className="myButton submit extraRightSpace"
                        onClick={endEditing}
                    >
                        수정완료
                    </button>
                </div>

                <div className={styles.subHeader}>일정 수정하기</div>
                {/* Route details */}
                {routes.map((route, index) => (
                    <div className={styles.routeDiv} key={`route_${index}`}>
                        <div className={styles.routeNo}>
                            {`일정 #${route.id}`}
                            {addRemoveButtons(index)}
                            <button
                                className={`${styles.youtubeLink} myButton cancel thinBtn extraRightSpace`}
                                onClick={() =>
                                    copyToClipboard(
                                        `https://www.youtube.com/embed/${route.url}`
                                    )
                                }
                            >
                                유투브 링크복사
                            </button>
                        </div>
                        <div style={{ paddingTop: "5px" }}>
                            <input
                                type="text"
                                value={route.title || ""}
                                placeholder={`일정 제목`}
                                style={{ width: "460px" }}
                                onChange={(e) => {
                                    const updatedRoutes = [...routes];
                                    updatedRoutes[index] = {
                                        ...route,
                                        title: e.target.value,
                                    };
                                    setRoutes(updatedRoutes);
                                }}
                            />
                            <input
                                type="number"
                                value={route.min}
                                placeholder={`X분소요`}
                                style={{ width: "50px" }}
                                onChange={(e) => {
                                    const updatedRoutes = [...routes];
                                    updatedRoutes[index].min = e.target.value;
                                    setRoutes(updatedRoutes);
                                }}
                            />
                            <input
                                type="text"
                                value={route.url}
                                placeholder={`유투브 혹은 사진 Url`}
                                style={{ width: "320px" }}
                                onChange={(e) => {
                                    const updatedRoutes = [...routes];
                                    updatedRoutes[index].url = e.target.value;
                                    setRoutes(updatedRoutes);
                                }}
                            />
                            <FileUploader
                                forumId={forumId}
                                routeId={route.id}
                                isSmall={true}
                                setOneRoute={setOneRoute}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={route.isPic} // Assuming isPic is a property in your route object
                                    style={{ marginLeft: "10px" }}
                                    onChange={(e) => {
                                        const updatedRoutes = [...routes];
                                        updatedRoutes[index].isPic =
                                            e.target.checked;
                                        setRoutes(updatedRoutes);
                                    }}
                                />
                                사진
                                <input
                                    type="checkbox"
                                    style={{ marginLeft: "10px" }}
                                    checked={!route.isPic} // Assuming isPic is a property in your route object
                                />
                                유투브
                            </label>
                        </div>
                        <div className={styles.quillDiv}>
                            <ReactQuill
                                value={route.body || ""}
                                placeholder={`일정`}
                                modules={{
                                    toolbar: quillToolBarFullAccess,
                                }}
                                onChange={(value) =>
                                    handleQuillChange(value, index)
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>

            {routes && routes.length === 0 && addRouteAfter(0)}

            <div className={styles.buttonDiv}>
                <button
                    className="myButton submit extraRightSpace"
                    onClick={endEditing}
                >
                    수정완료
                </button>
                <button
                    className="myButton delete"
                    onClick={deleteAllForumRoute}
                >
                    모든 일정 삭제
                </button>
            </div>
        </>
    );
};

export default AdminRoute;
