import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import ReactQuillWithTable from "react-quill-with-table";
import SlideImage from "components/FileHandling/SlideImage";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
    FORUM_CATEGORY_TYPE_DB,
    FORUM_CATEGORY_TYPE_NAME,
} from "../../constants";
import {
    FacebookShareButton,
    EmailShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { UPDATE_APP_FORUMCATEGORYID } from "views/App/constants";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { SAFETY_ca, SAFETY_kr } from "./constants";
import Route from "img/route.png";
import { formatPrice, calendarDate } from "../../utility/utility";
import DiscountedImg from "../../img/sale.svg";
import { Helmet } from "react-helmet";
import MetaImg from "../../img/meta.svg";
import MailImg from "../../img/mail.svg";
import KakaoImg from "../../img/Kakao.png";
import XImg from "../../img/X.svg";
import WhatsappImg from "../../img/whatsapp.svg";
import ShareImg from "../../img/Share.svg";
import Interact from "img/Interact.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

const { Kakao } = window;
const { kakaoDevJSKey, firebaseBaseUrl } = require("../../config");

const Bulletin = ({ forumId, value, onAction }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const langCode = useSelector((state) => state.app.lang);

    let forumBulletin = "";
    let fName = "";
    let fDesc = "";
    let fPrice = 0;
    let fTripDays = "";
    let fMinPerson = "";
    let fImgUrls = [];
    let fRoute = "";
    let fIncluded = [];
    let fExcluded = [];
    let fRouteDetail = [];
    if (value) {
        dispatch({
            type: UPDATE_APP_FORUMCATEGORYID,
            forumCategoryId:
                FORUM_CATEGORY_TYPE_NAME[
                    FORUM_CATEGORY_TYPE_DB[value.category]
                ],
        });

        const name = value.name.find((item) => item.lang === langCode)?.txt;
        fName = name;

        const description = value.description.find(
            (item) => item.lang === langCode
        )?.txt;
        fDesc = description;

        let bulletin = "";
        if (value.bulletin && value.bulletin.length > 0) {
            bulletin = value.bulletin.find(
                (item) => item.lang === langCode
            )?.txt;
        }

        forumBulletin = bulletin ? bulletin : "";

        if (value.route) {
            const route = value.route.find(
                (item) => item.lang === langCode
            )?.txt;
            fRoute = route ? route : t("TobeNego");
        }

        if (value.routeDetail) {
            const routeDetail = value.routeDetail.filter(
                (item) => item.lang === langCode
            );
            fRouteDetail = routeDetail;
        }

        if (value.inexclude) {
            const inexclude = value.inexclude.find(
                (item) => item.lang === langCode
            );
            if (inexclude) {
                if (inexclude.included) fIncluded = inexclude.included;
                if (inexclude.excluded) fExcluded = inexclude.excluded;
            }
        }
        fPrice = formatPrice(
            value.price,
            value.status,
            value.discount,
            t("Inquire")
        );
        fTripDays = value.tripDays;
        fMinPerson = value.minPerson;
        fImgUrls = Array.from(
            new Set([
                ...value.imageUrls.map((img) => img.url).filter((url) => url), // Filter out empty URLs
                ...value.routeDetail
                    .filter((detail) => detail.isPic)
                    .map((detail) => detail.url)
                    .filter((url) => url), // Filter out empty URLs
            ])
        );
    }

    const renderJoinsSection = () => {
        return (
            <>
                <div className={styles.joinsTitle}>{t("JoinTitle")}</div>
                <div className={styles.joinsBody}>
                    <p
                        className={styles.joinsDesc}
                        dangerouslySetInnerHTML={{ __html: t("JoinDesc") }}
                    ></p>
                    <table className={styles.joinsTable}>
                        <thead>
                            <tr>
                                <th>{t("JoinStart")}</th>
                                <th>{t("JoinEnd")}</th>
                                <th>{t("JoinMale")}</th>
                                <th>{t("JoinFemale")}</th>
                                <th>{t("JoinRemarks")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.joins.map((join, index) => (
                                <tr key={index}>
                                    <td className={styles.joinsDate}>
                                        {calendarDate(join.startDate)}
                                    </td>
                                    <td className={styles.joinsDate}>
                                        {calendarDate(join.endDate)}
                                    </td>
                                    <td>{join.male}</td>
                                    <td>{join.female}</td>
                                    <td>{join.remarks}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    const renderHtmlContent = (content, style) => {
        return (
            <div
                className={style}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            ></div>
        );
    };

    const formatDuration = (minutes) => {
        if (minutes === 0) {
            return "";
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        let time = "";
        if (hours > 0) {
            time += langCode === "kr" ? `${hours}시간 ` : `${hours} hour(s) `;
        }

        if (remainingMinutes > 0 || hours === 0) {
            time +=
                langCode === "kr"
                    ? `${remainingMinutes}분`
                    : `${remainingMinutes} minute(s)`;
        }

        return time.trim();
    };

    const renderTabContent = (tabKey) => {
        switch (tabKey) {
            case "product":
                return (
                    <div className="hideqltoolbar">
                        <ReactQuillWithTable
                            value={forumBulletin}
                            readOnly={true}
                            modules={{
                                toolbar: [],
                            }}
                        />
                    </div>
                );

            case "routeDetail":
                return (
                    <>
                        {fRouteDetail.map((routeDetailItem, index) => {
                            let imgUrl = routeDetailItem.url;
                            if (routeDetailItem.isPic) {
                                imgUrl = firebaseBaseUrl.replace(
                                    "{0}",
                                    routeDetailItem.url
                                );
                            }
                            return (
                                <div
                                    key={`routeDetailItem_${index}`}
                                    className={styles.routeContainer}
                                >
                                    <div className={styles.routeContainer2}>
                                        <div className={styles.routeDiv}>
                                            <div className={styles.routeLeft}>
                                                <img
                                                    className={styles.routeImg}
                                                    src={Route}
                                                    alt="Route"
                                                />
                                                <div
                                                    className={styles.routeLine}
                                                ></div>
                                            </div>
                                            <div className={styles.routeMain}>
                                                {routeDetailItem.url === "" ? (
                                                    ""
                                                ) : routeDetailItem.isPic ? (
                                                    <div
                                                        className={
                                                            styles.routePhoto
                                                        }
                                                        style={{
                                                            backgroundImage: `url(${imgUrl})`,
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.routePhoto
                                                        }
                                                    >
                                                        <iframe
                                                            title={`YouTube Video ${
                                                                index + 1
                                                            }`}
                                                            width="100%"
                                                            height="100%"
                                                            src={
                                                                routeDetailItem.url
                                                            }
                                                            frameBorder="0"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                )}
                                                <div>
                                                    <div
                                                        className={
                                                            styles.routeTitle
                                                        }
                                                    >
                                                        {routeDetailItem.title}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.routeMin
                                                        }
                                                    >
                                                        {formatDuration(
                                                            routeDetailItem.min
                                                        )}
                                                    </div>
                                                </div>
                                                {renderHtmlContent(
                                                    routeDetailItem.body,
                                                    styles.routeDetail
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                );

            case "included":
                return (
                    <div className={styles.inExcludeDiv}>
                        <table border="1" className={styles.tableInExclude}>
                            <thead>
                                <tr>
                                    <td>{t("Included")}</td>
                                    <td>{t("Excluded")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul>
                                            {fIncluded
                                                .filter(
                                                    (includedItem) =>
                                                        includedItem.trim() !==
                                                        ""
                                                )
                                                .map((includedItem, index) => (
                                                    <li
                                                        key={`included_${index}`}
                                                    >
                                                        {includedItem}
                                                    </li>
                                                ))}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            {fExcluded
                                                .filter(
                                                    (excludedItem) =>
                                                        excludedItem.trim() !==
                                                        ""
                                                )
                                                .map((excludedItem, index) => (
                                                    <li
                                                        key={`excluded_${index}`}
                                                    >
                                                        {excludedItem}
                                                    </li>
                                                ))}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );

            case "safety":
                return renderHtmlContent(
                    langCode === "kr" ? SAFETY_kr : SAFETY_ca,
                    styles.explDiv
                );

            default:
                return null;
        }
    };

    const url = window.location.href;
    const shareKakao = () => {
        Kakao.Share.sendDefault({
            objectType: "feed",
            content: {
                title: fName,
                description: fDesc,
                imageUrl:
                    fImgUrls && fImgUrls.length > 0
                        ? firebaseBaseUrl.replace("{0}", fImgUrls[0])
                        : "", // Ensure fImgUrls is defined

                link: {
                    mobileWebUrl: url,
                    webUrl: url,
                },
            },
            buttons: [
                {
                    title: "페이지로 이동",
                    link: {
                        mobileWebUrl: url,
                        webUrl: url,
                    },
                },
            ],
        });
    };

    useEffect(() => {
        if (!Kakao.isInitialized()) {
            Kakao.init(kakaoDevJSKey);
        }
    }, []);

    return (
        <>
            {fName && ( // Add Helmet component when fName exists
                <Helmet>
                    <title>{fName}</title>
                    <meta name="og:description" content={fDesc} />
                    <meta
                        name="og:image"
                        content={
                            fImgUrls && fImgUrls.length > 0
                                ? firebaseBaseUrl.replace(
                                      "{0}",
                                      fImgUrls[0].url
                                  )
                                : "" // Ensure fImgUrls is defined
                        }
                    />
                </Helmet>
            )}
            <div className={styles.bulletinContainer}>
                {value && value.status === 3 && (
                    <span className={styles.discountImg}>
                        <img src={DiscountedImg} alt="할인" />
                    </span>
                )}
                <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} sm={12} md={5}>
                        <SlideImage forumId={forumId} imgUrls={fImgUrls} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className={styles.tourHeader}>
                            <div className={styles.tourName}>
                                {fName ? fName : t("TourName")}
                            </div>
                            <div className={styles.tourDescription}>
                                {fDesc ? fDesc : t("TourDesc")}
                            </div>
                        </div>
                        <div className={styles.ShareLinkDiv}>
                            <span className={styles.ShareLink}>
                                <img
                                    src={ShareImg}
                                    alt="Share"
                                    style={{
                                        cursor: "default",
                                        height: "20px",
                                    }}
                                />
                            </span>
                            <span className={styles.ShareText}>
                                {t("ShareProduct")}
                            </span>
                            <span className={styles.ShareLink}>
                                <Link onClick={shareKakao}>
                                    <img
                                        src={KakaoImg}
                                        alt="카카오톡"
                                        style={{ width: "30px" }}
                                    />
                                </Link>
                            </span>
                            <span className={styles.ShareLink}>
                                <FacebookShareButton url={url}>
                                    <img
                                        src={MetaImg}
                                        alt="Facebook"
                                        style={{ width: "30px" }}
                                    />
                                </FacebookShareButton>
                            </span>
                            <span className={styles.ShareLink}>
                                <EmailShareButton url={url}>
                                    <img
                                        src={MailImg}
                                        alt="Email"
                                        style={{ width: "30px" }}
                                    />
                                </EmailShareButton>
                            </span>
                            <span className={styles.ShareLink}>
                                <WhatsappShareButton url={url}>
                                    <img
                                        src={WhatsappImg}
                                        alt="WhatsApp"
                                        style={{ width: "30px" }}
                                    />
                                </WhatsappShareButton>
                            </span>
                            <span className={styles.ShareLink}>
                                <TwitterShareButton url={url}>
                                    <img
                                        src={XImg}
                                        alt="Twitter"
                                        style={{ width: "30px" }}
                                    />
                                </TwitterShareButton>
                            </span>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div className={styles.tourDetail}>
                            <span className={styles.detailHeader}>
                                {t("TripDays")}
                            </span>
                            <span className={styles.expl}>
                                {fTripDays ? `${fTripDays} ${t("Days")}` : "-"}
                            </span>
                        </div>
                        <div className={styles.tourDetail}>
                            <span className={styles.detailHeader}>
                                {t("MinPerson")}
                            </span>
                            <span className={styles.expl}>
                                {fMinPerson
                                    ? `${fMinPerson} ${t("Persons")}`
                                    : "-"}
                            </span>
                        </div>
                        <div className={styles.tourDetail}>
                            <span className={styles.detailHeader}>
                                {t("Route")}
                            </span>
                            <span className={styles.expl}>
                                {fRoute ? fRoute : "-"}
                            </span>
                        </div>
                        <div className={styles.tourDetail}>
                            <span className={styles.detailHeader}>
                                {t("Price")}
                            </span>
                            <span className={styles.expl}>
                                {fPrice &&
                                value &&
                                value.status === 3 &&
                                value.discount > 0 ? (
                                    <>
                                        {/* You need to define or replace `priceContent` here */}
                                        <div className={styles.tourPrice}>
                                            <div className={styles.oldPrice}>
                                                {fPrice}
                                            </div>
                                            <div className={styles.newPrice}>
                                                C${value.price}
                                            </div>{" "}
                                            <span className={styles.perPerson}>
                                                +5% GST /{t("PerPerson")}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <strong>{fPrice}</strong>{" "}
                                        <span className={styles.perPerson}>
                                            /{t("PerPerson")}
                                        </span>
                                    </>
                                )}
                            </span>
                            {fTripDays > 1 && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {t("OnePersonRuleDesc")}
                                        </Tooltip>
                                    }
                                >
                                    <span className={styles.exclMark}>
                                        <FaExclamationTriangle />
                                        <span
                                            style={{
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {t("OnePersonRule")}
                                        </span>
                                        {/* Bootstrap exclamation icon */}
                                    </span>
                                </OverlayTrigger>
                            )}
                        </div>

                        <div className={styles.interactInfo}>
                            <img src={Interact} width="50px" alt="Interact" />
                            <p style={{ margin: "0" }}>{t("InteractMsg")}</p>
                        </div>
                        <div className={styles.inquireBtn} style={{}}>
                            <button
                                className="myButton secondary"
                                onClick={onAction}
                            >
                                {t("TripInquire")}
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {value.joins && value.joins.length > 0 && renderJoinsSection()}
            <div className={styles.bulletinHeader}>
                <Tabs defaultActiveKey="product" id="tabs">
                    <Tab eventKey="product" title={t("Product")}>
                        {renderTabContent("product")}
                    </Tab>
                    {fRouteDetail && fRouteDetail.length > 0 && (
                        <Tab eventKey="routeDetail" title={t("RouteDetail")}>
                            {renderTabContent("routeDetail")}
                        </Tab>
                    )}
                    {(fIncluded?.some((item) => item.trim() !== "") ||
                        fExcluded?.some((item) => item.trim() !== "")) && (
                        <Tab eventKey="included" title={t("Inexcluded")}>
                            {renderTabContent("included")}
                        </Tab>
                    )}
                    <Tab eventKey="safety" title={t("Safety")}>
                        {renderTabContent("safety")}
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};

export default Bulletin;
