import {
    INITIAL_APP_STATE,
    UPDATE_APP_ADDRESS,
    UPDATE_APP_LANG,
    UPDATE_APP_AUTH,
    UPDATE_APP_TOKEN,
    UPDATE_APP_FORUMCATEGORYID,
} from "./constants";

const appReducer = (state = INITIAL_APP_STATE, action) => {
    switch (action.type) {
        case UPDATE_APP_ADDRESS:
            return {
                ...state,
                isAdmin: action.isAdmin,
                forumName: action.forumName,
                forumId: action.forumId,
                forumCategoryId: action.forumCategoryId,
                topicName: action.topicName,
                topicId: action.topicId,
                lang: action.lang,
            };
        case UPDATE_APP_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case UPDATE_APP_FORUMCATEGORYID:
            return {
                ...state,
                forumCategoryId: action.forumCategoryId,
            };
        case UPDATE_APP_AUTH:
            return {
                ...state,
                username: action.username,
                password: action.password,
                accessToken: action.accessToken,
                isAdmin: action.isAdmin,
                isSuperUser: action.isSuperUser,
                email: action.email,
            };
        case UPDATE_APP_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
            };
        default:
            return state;
    }
};

export default appReducer;
