export const CATEGORY_TYPES = {
    TourQuestion: "1",
    TourStory: "2",
    Question: "3",
    Other: "4",
};

export const TOUR_STATUS = {
    0: "숨김",
    1: "일반상품",
    2: "추천상품",
    3: "특가상품",
};

export const CATEGORY_TYPES_LANG_kr = {
    1: "여행문의",
    2: "여행후기",
    3: "질문",
    4: "기타",
};

export const CATEGORY_TYPES_LANG_ca = {
    1: "Reservation Inquiry",
    2: "Travelogue",
    3: "Question",
    4: "Etc",
};

export const FORUM_CATEGORY_TYPE_ID = {
    0: "모두보기",
    1: "록키",
    2: "캘거리",
    3: "카나나스키스",
    //5: "멕시코",
    4: "옐로나이프",
    //5: "아시아",
};

export const FORUM_CATEGORY_TYPE_ID_ca = {
    0: "All",
    1: "Rockies",
    2: "Calgary",
    3: "Kananaskis",
    //5: "Mexico",
    4: "Yellowknife",
    //5: "Asia",
};

export const FORUM_CATEGORY_TYPE_NAME = {
    전부: 0,
    록키: 1,
    캘거리: 2,
    카나나스키스: 3,
    //멕시코: 4,
    옐로나이프: 4,
    //아시아: 5,
};

export const FORUM_CATEGORY_TYPE_DB = {
    "65497fc49e6ab00940880e2c": "록키", // Banff
    "65501c22db750d3464b228ec": "재스퍼",
    "654acb359e6ab009408810b0": "캘거리",
    "654986709e6ab00940880ee6": "카나나스키스",
    "65501be2db750d3464b228ce": "멕시코",
    "655018b1db750d3464b22896": "옐로나이프",
};
