import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { fitNameToUrl, newFormatDate } from "../../../utility/utility";
import {
    CATEGORY_TYPES_LANG_ca,
    CATEGORY_TYPES_LANG_kr,
} from "../../../constants";
const { forumNameConfig } = require("../../../config");

const TopicItem = ({
    forumId,
    category,
    _id,
    user,
    name,
    viewNum,
    replyNum,
    updatedAt,
}) => {
    const lang = useSelector((state) => state.app.lang);
    const updatedDate = newFormatDate(new Date(updatedAt), lang);
    const categoryName =
        lang === "kr"
            ? CATEGORY_TYPES_LANG_kr[category.name]
            : CATEGORY_TYPES_LANG_ca[category.name];
    return (
        <div className={styles.topicItemContainer}>
            <div className={styles.small_item}>{categoryName}</div>
            <div className={styles.big_item}>
                <Link
                    className={styles.link}
                    to={{
                        pathname: `/${forumNameConfig}/${fitNameToUrl(
                            name
                        )}/${forumId}/${_id}`,
                        search: "",
                        hash: "",
                        state: {
                            forumId: forumId,
                            topicId: _id,
                            title: name,
                            updatedAt: updatedAt,
                        },
                    }}
                >
                    {name}
                </Link>
            </div>
            <div className={styles.small_item}>
                <Link
                    className={styles.link}
                    to={{
                        pathname: `/user/${user.username}`,
                        search: "",
                        hash: "",
                        state: {},
                    }}
                >
                    <img
                        alt="Avatar"
                        className={styles.circle_img}
                        src={user.avatarUrl}
                    >
                        {user.name}
                    </img>
                    &nbsp;{user.username}
                </Link>
            </div>

            <div className={styles.small_item}>{replyNum - 1}</div>
            <div className={styles.small_item}>{viewNum}</div>
            <div className={styles.time_item}>{updatedDate}</div>
        </div>
    );
};

export default TopicItem;
