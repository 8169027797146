module.exports = {
    //NAME_OF_CONFIG: process.env.NAME_OF_CONFIG || 'default-config',
    //X_ENABLED: process.env.X_ENABLED === 'true',
    siteUrl: "https://www.cnktour.com",
    supportedLang: ["/kr", "/ca", ""],
    serverURI:
        process.env.NODE_ENV === "production"
            ? "https://cnk-tour-server.vercel.app"
            : "",
    forumNameConfig: "travel",
    kakaoDevJSKey: "88ed24ab6bb804630cc81e0d6336c761", // 내 JS Key: d6b08278507a221fdf49c56c89548fa8 cnk6262키: 88ed24ab6bb804630cc81e0d6336c761
    kakaoChannelPublicId: "_tjjxfxj", // 내 채널: _vlabG, cnk6262채널: _tjjxfxj
    firebaseBaseUrl:
        "https://firebasestorage.googleapis.com/v0/b/cnk-tour.appspot.com/o/{0}?alt=media",
    quillToolBarFullAccess: [
        [{ header: [1, 2, 3, 4, 5, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["blockquote"],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
    ],
    quillToolBarMinAccess: [
        [{ header: [1, 2, 3, 4, 5, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "video"],
        ["blockquote"],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
    ],
};
