import React, { useState, useEffect } from "react";
import {
    FaExclamationTriangle,
    FaExclamationCircle,
    FaInfoCircle,
    FaCheckCircle,
    FaTimes, // Import the close icon
} from "react-icons/fa";
import "./AlertBanner.css";

// Disappear after duration (in seconds)
const AlertBanner = ({
    message,
    type,
    duration = 1000,
    showCloseButton = false,
    visibility = false,
    customStyle = {},
    onClose,
}) => {
    const [showAlert, setShowAlert] = useState(visibility);

    useEffect(() => {
        setShowAlert(visibility);
        if (duration < 999) {
            const timer = setTimeout(() => {
                setShowAlert(false); // Hide the alert after the specified duration
            }, duration * 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [visibility, duration]);

    const handleAlertClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const getIcon = () => {
        switch (type) {
            case "warning":
                return <FaExclamationTriangle />;
            case "error":
                return <FaExclamationCircle />;
            case "pink":
            case "info":
                return <FaInfoCircle />;
            case "success":
                return <FaCheckCircle />;
            default:
                return null;
        }
    };

    return (
        <>
            <div
                className={`alert-banner ${type} ${
                    showAlert ? "show" : "hide"
                }`}
                style={customStyle}
            >
                <div className="alert-icon">{getIcon()}</div>
                <div className="alert-message">{message}</div>
                {showCloseButton && (
                    <div className="alert-close" onClick={handleAlertClose}>
                        <FaTimes /> {/* Close icon */}
                    </div>
                )}
            </div>
        </>
    );
};

export default AlertBanner;
