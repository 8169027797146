import React, { useEffect } from "react";
import kakaoLogo from "img/kakao_talk.png";
import styles from "./index.module.css";
const { kakaoDevJSKey, kakaoChannelPublicId } = require("../../config");

const KakaoLoginButton = () => {
    const handleKakaoChatClick = () => {
        window.open("https://open.kakao.com/o/gQvu48vg", "_blank");
    };

    return (
        <>
            <img
                src={kakaoLogo}
                className={styles.kakaoImg}
                onClick={handleKakaoChatClick}
                alt="Kakao 실시간 문의하기"
            />
        </>
    );
};

export default KakaoLoginButton;
