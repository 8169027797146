import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const Career = () => {
    return (
        <>
            <div className={styles.regContainer}>
                <div className={styles.regImgContainer}>
                    <div className={styles.regTitle}>채용공고</div>
                    <div className={styles.regDesc}>
                        캘거리 최고의 여행사인 C&K투어와 함께할 인재를
                        모집합니다.
                    </div>
                </div>
                <div className={styles.careerContainer}>
                    <div className={styles.careerHeader}>
                        CRM 및 마케팅 매니저 (1명)
                    </div>
                    <div className={styles.careerSubHeader}>업무내용</div>
                    <p>- CRM 채널 운영/관리</p>
                    <ul>
                        <li>
                            카카오톡 채널 (친구톡, 알림톡), 앱 푸시, 문자 등 CRM
                            채널별 전략을 수립하고 실행합니다.
                        </li>
                        <li>
                            고객정보와 행동을 기반으로 인사이트를 도출하고
                            세그먼트별로 CRM 전략을 수립하고 실행합니다.
                        </li>
                        <li>
                            데이터 분석 툴을 활용해 전반적인 CRM 캠페인을
                            운영/실행/분석하고 싸이클/자동화합니다.
                        </li>
                    </ul>
                    <p>
                        - 캠페인 집행 결과에 따른 성과 분석, 비용 및 효율 최적화
                    </p>
                    <ul>
                        <li>
                            프로덕트, 사업팀 등과 협업하며 고객 관련 인사이트를
                            제공합니다.
                        </li>
                    </ul>
                    <p>- 기타 다양한 마케팅 활동</p>
                    <div className={styles.careerSubHeader}>자격조건</div>
                    <ul>
                        <li>[필수] 3년 이상의 CRM 마케팅 업무경험이 있는 분</li>
                        <li>[필수] 데이터로 증명이 가능한 과거 마케팅 성과</li>
                        <li>
                            데이터 툴 (Braze, Airbridge 등)을 활용한 캠페인
                            기획/실행/분석의 경험이 있는 분
                        </li>
                        <li>
                            데이터 중심의 의사결정과 커뮤니케이션 경험이
                            익숙하신 분
                        </li>
                        <li>
                            사업, PO, 디자인 등 다양한 유관부서와 협업 경험이
                            많고 커뮤니케이션이 원활한 분
                        </li>
                    </ul>
                </div>

                <div className={styles.careerContainer}>
                    <div className={styles.careerHeader}>
                        T&A 사업개발 매니저 (1명)
                    </div>
                    <div className={styles.careerSubHeader}>업무내용</div>
                    <ul>
                        <li>
                            투어&액티비티 신규 카테고리 개발 및 기존 카테고리
                            상품 확장
                        </li>
                        <li>투어&액티비티 매출 증대 사업 전략 수립 및 실행</li>
                        <li>
                            신규 B2C/B2B 파트너사 발굴 및 전략적 파트너쉽 구축
                        </li>
                    </ul>
                    <div className={styles.careerSubHeader}>
                        이런 역량이 있는 분들을 찾고 있어요.
                    </div>
                    <ul>
                        <li>
                            투어&액티비티 신규 카테고리 개발 및 기존 카테고리
                            상품 확장
                        </li>
                        <li>
                            투어&액티비티 글로벌 매출 증대 사업 전략 수립 및
                            실행
                        </li>
                        <li>
                            신규 B2C/B2B 파트너사 발굴 및 전략적 파트너쉽 구축
                        </li>
                        <li>
                            담당 도시 핵심 사업 지표 분석 및 대응 오퍼레이션
                        </li>
                        <li>
                            파트너사와의 전략적 협업을 통한 selection, price,
                            availability, contents 최적화
                        </li>
                        <li>
                            여행자(고객) 및 여행 트렌드 분석을 통한 상품
                            큐레이션 / 노출 영역 관리
                        </li>
                    </ul>
                    <div className={styles.careerSubHeader}>우대사항</div>
                    <ul>
                        <li>
                            창업/스타트업/플랫폼/e-커머스/글로벌 IT기업에서의
                            업무 경험
                        </li>
                        <li>비즈니스 레벨 이상의 영어 커뮤니케이션 능력</li>
                    </ul>
                </div>

                <div className={styles.careerContainer}>
                    <div className={styles.careerHeader}>여행 가이드 (3명)</div>
                    <div className={styles.careerSubHeader}>업무내용</div>
                    <ul>
                        <li>관광 명소 및 문화 유산을 소개하고 안내하기</li>
                        <li>
                            여행자들에게 지역의 특색과 역사를 설명하며 동행하기
                        </li>
                        <li>
                            다양한 여행 활동 및 이벤트를 조직하고 참여 유도하기
                        </li>
                    </ul>
                    <div className={styles.careerSubHeader}>
                        이런 역량이 있는 분들을 찾고 있어요.
                    </div>
                    <ul>
                        <li>3년 이상 여행 관련 업무 경험이 있는 분</li>
                        <li>
                            지역의 역사, 문화, 미식 여행 등에 대한 깊은 지식을
                            가진 분
                        </li>
                        <li>
                            다양한 국적 및 언어의 여행자와 원활한 소통이 가능한
                            분
                        </li>
                    </ul>
                    <div className={styles.careerSubHeader}>우대사항</div>
                    <ul>
                        <li>외국어 능통자 (영어, 중국어, 일본어 등)</li>
                        <li>지역 관련 자격증 소지자</li>
                        <li>Trail 가이드 자격증 소지자</li>
                        <li>세계 여행 경험이 풍부한 분</li>
                    </ul>
                </div>

                <div style={{ margin: "0 0 20px 10px" }}>
                    <div className={styles.careerHeader}>지원방법</div>
                    <div style={{ margin: "0 0 0 10px" }}>
                        <a href="mailto:info@cnktour.com">info@cnktour.com</a>로
                        간단한 본인소개와 함께 사진(필수)과 이력서를 보내시거나,{" "}
                        <Link className={styles.link} to="/contact">
                            여기를 클릭
                        </Link>
                        하여 LinkedIn이나 사이트 주소를 보내주십시요.
                    </div>
                </div>
            </div>
        </>
    );
};

export default Career;
