import React, { useState } from "react";
import { useSelector } from "react-redux";
import CreateForumModal from "components/Modal/CreateForumModal";
import { MdPlaylistAdd } from "react-icons/md";
import styles from "./index.module.css";

const ForumToolBar = ({ url, onNewForum }) => {
    const [visibility, setVisibility] = useState(false);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    return (
        <div className={styles.forumToolBarContainer}>
            {isSuperUser ? (
                <>
                    <div className={styles.addLink}>
                        <button
                            className="myButton submit"
                            onClick={() => {
                                setVisibility(true);
                            }}
                        >
                            <MdPlaylistAdd /> <span>여행지 추가</span>
                        </button>
                    </div>
                </>
            ) : (
                ""
            )}
            <CreateForumModal
                url={url}
                visibility={visibility}
                onHide={() => {
                    setVisibility(false);
                }}
                onSubmit={() => {
                    setVisibility(false);
                    onNewForum();
                }}
            />
        </div>
    );
};

export default ForumToolBar;
