import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { fitNameToUrl, newFormatDate } from "../../utility/utility";
import { useTranslation } from "react-i18next";
const { forumNameConfig } = require("../../config");

const Review = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    // State and topics per page
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalTopics, setTotalTopics] = useState(0);
    const topicsPerPage = 7;

    // Get `page` from URL query parameter, defaulting to 1 if not present
    const query = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(query.get("page")) || 1;
    const [page, setPage] = useState(pageFromUrl);

    // Sync `page` state with `pageFromUrl` whenever it changes
    useEffect(() => {
        setPage(pageFromUrl);
    }, [pageFromUrl]);

    // Fetch topics based on the current page
    useEffect(() => {
        const fetchTopics = async () => {
            setLoading(true);
            try {
                const response = await axios.get("/api/topics", {
                    params: { count: topicsPerPage, page },
                });
                setTopics(response.data.topics);
                setTotalTopics(response.data.totalCount);
            } catch (error) {
                console.error("Failed to fetch topics:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTopics();
    }, [page]);

    // Handle page changes by updating the URL
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= Math.ceil(totalTopics / topicsPerPage)) {
            history.push(`${location.pathname}?page=${newPage}`);
        }
    };

    // Calculate total pages
    const totalPages = Math.ceil(totalTopics / topicsPerPage);
    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    if (loading) {
        return <div>{t("Loading")}</div>;
    }

    return (
        <div>
            {/* Topics header and list */}
            <div
                className={`${styles.reviewItemContainer} ${styles.noPadding}`}
            >
                <div className={`${styles.big_item} ${styles.header}`}>
                    {t("Title")}
                </div>
                <div className={`${styles.small_item} ${styles.header}`}>
                    {t("Clicks")}
                </div>
                <div className={`${styles.time_item} ${styles.header}`}>
                    {t("PostDate")}
                </div>
            </div>

            {/* Render topics */}
            {topics.map((topic) => {
                const { _id, forum, name, viewNum, updatedAt } = topic;
                const forumId = forum;
                const updatedDate = newFormatDate(new Date(updatedAt));

                return (
                    <div key={name} className={styles.reviewItemContainer}>
                        <div className={styles.big_item}>
                            <Link
                                className={styles.link}
                                to={{
                                    pathname: `/${forumNameConfig}/${fitNameToUrl(
                                        name
                                    )}/${forumId}/${_id}`,
                                    state: {
                                        forumId,
                                        topicId: _id,
                                        title: name,
                                        updatedAt,
                                    },
                                }}
                            >
                                {name}
                            </Link>
                        </div>
                        <div className={styles.small_item}>{viewNum}</div>
                        <div className={styles.time_item}>{updatedDate}</div>
                    </div>
                );
            })}

            {/* Pagination Links */}
            <div className={styles.pager}>
                {page > 1 && (
                    <Link
                        to={`${location.pathname}?page=${page - 1}`}
                        className={styles.pageLink}
                    >
                        &laquo;
                    </Link>
                )}

                {pagesArray.map((pg) => (
                    <Link
                        key={pg}
                        to={`${location.pathname}?page=${pg}`}
                        className={`${styles.pageLink} ${
                            pg === page ? styles.activePage : ""
                        }`}
                    >
                        {pg}
                    </Link>
                ))}

                {page < totalPages && (
                    <Link
                        to={`${location.pathname}?page=${page + 1}`}
                        className={styles.pageLink}
                    >
                        &raquo;
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Review;
