import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
    UPDATE_DISCUSSION_START,
    UPDATE_DISCUSSION_SUCCESS,
    UPDATE_DISCUSSION_FAILURE,
} from "./constants";
import { UPDATE_APP_ADDRESS } from "views/App/constants";
import ReplyList from "components/Reply/List";
import Loading from "components/Loading";
import styles from "./index.module.css";
import AlertBanner from "../../components/AlertBanner";
import { sendEmail } from "../../utility/utility";
import ReactQuill from "react-quill";
const { forumNameConfig, quillToolBarMinAccess } = require("../../config");

const Discussion = () => {
    const accessToken = useSelector((state) => state.app.accessToken);
    const langCode = useSelector((state) => state.app.lang);
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [forumId, setForumId] = useState(
        useSelector((state) => state.app.forumId)
    );
    const [topicId, setTopicId] = useState(
        useSelector((state) => state.app.topicId)
    );

    const title = useSelector((state) => state.discussion.title);
    const replyList = useSelector((state) => state.discussion.replyList);
    const isLoading = useSelector((state) => state.discussion.isLoading);

    const [alertState, setAlertState] = useState({
        alertOpen: false,
        alertMsg: "",
        alertSev: "success",
    });

    const [bottomAlert, setBottomAlert] = useState({});

    const updateAlertBanner = (editedAlertState) => {
        setAlertState(editedAlertState);
    };

    const bannerClose = () => {
        setAlertState({
            alertOpen: false,
        });
    };

    const reloadReplyList = () => {
        // 이건 reply가 지워질때 불러지며 페이지를 refresh해서 reply가 지워진걸 보여주기위함.
        updateDiscussion();
    };

    const updateDiscussion = async () => {
        if (!forumId || !topicId) {
            // Check if forumId or topicId is empty, do not proceed
            return;
        }

        try {
            dispatch({
                type: UPDATE_DISCUSSION_START,
            });

            const [replies, topic] = await Promise.all([
                axios.get(`/api/forum/${forumId}/topic/${topicId}/reply`),
                axios.get(`/api/forum/${forumId}/topic/${topicId}`),
            ]);

            dispatch({
                type: UPDATE_DISCUSSION_SUCCESS,
                replyList: replies.data,
                title: topic.data.name,
                category: topic.data.category,
                updatedAt: topic.data.updatedAt,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_DISCUSSION_FAILURE,
                error: error.message,
            });
        }
    };

    useEffect(() => {
        let initialForumId = forumId;
        let initialTopicId = topicId;

        if (location.state) {
            initialForumId = location.state.forumId || forumId;
            initialTopicId = location.state.topicId || topicId;
        } else {
            const { forumId: urlForumId, topicId: urlTopicId } = params;
            initialForumId = urlForumId || initialForumId;
            initialTopicId = urlTopicId || initialTopicId;
        }

        if (!initialForumId || !initialTopicId) {
            history.push("/");
            return;
        } else {
            setForumId(initialForumId);
            setTopicId(initialTopicId);

            dispatch({
                type: UPDATE_APP_ADDRESS,
                isAdmin: false,
                forumName: params.forumName,
                forumId: initialForumId,
                topicName: params.topicName,
                topicId: initialTopicId,
            });
        }
    }, []);

    useEffect(() => {
        if (forumId && topicId) {
            updateDiscussion();
        }
    }, [forumId, topicId]);

    useEffect(() => {
        setBottomAlert({
            alertOpen: true,
            alertMsg: t("ReplyEmail"),
            alertSev: "info",
        });
    }, [langCode]);

    /* 퀼 사용 시작 */
    const [content, setContent] = useState("");

    const handleContentChange = (value) => {
        setContent(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(
                `/api/forum/${forumId}/topic/${topicId}/reply`,
                { content },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            if (response.status === 200) {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("ReplyAdded"),
                    alertSev: "success",
                });
                setContent("");
                updateDiscussion();

                sendEmail(axios, {
                    topicId: topicId,
                    replyId: "",
                    subject: "답글이 등록됨",
                    body: `${window.location.href}\n\n${content}`,
                    // emailto가 없으면 admin email로 감.
                });
            } else {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("GeneralError"),
                    alertSev: "error",
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("PlzLogin"),
                    alertSev: "error",
                });
            } else if (error.response && error.response.status === 403) {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("WriteLimit"),
                    alertSev: "error",
                });
            } else if (error.response && error.response.status === 422) {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("WriteMin"),
                    alertSev: "error",
                });
            } else {
                setBottomAlert({
                    alertOpen: true,
                    alertMsg: t("UnknownError"),
                    alertSev: "error",
                });
            }
        }
    };
    /* 퀼 사용 끝 */

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {isLoading === true && <Loading />}
            {isLoading === false && (
                <div className={styles.topicDiv}>
                    <div className={styles.bannerDiv}>
                        <AlertBanner
                            type={alertState.alertSev}
                            visibility={alertState.alertOpen}
                            message={alertState.alertMsg}
                            onClose={bannerClose}
                        />
                    </div>
                    <div className={styles.replyTitleContainer}>
                        <div className={styles.big_item}>
                            <div className={styles.title}>
                                {title} -{" "}
                                <Link
                                    className={styles.link}
                                    to={{
                                        pathname: `/${forumNameConfig}/tour-${forumId}-${langCode}`,
                                        search: "",
                                        hash: "",
                                    }}
                                >
                                    {t("LinkToTour")}
                                </Link>
                            </div>
                            <ReplyList
                                items={replyList}
                                updateAlertBanner={updateAlertBanner}
                                reloadReplyList={reloadReplyList}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.discussionEditor}>
                <div className={styles.boardMeTooTopBox}>{t("AddReply")}</div>
                <AlertBanner
                    type={bottomAlert.alertSev}
                    visibility={bottomAlert.alertOpen}
                    message={bottomAlert.alertMsg}
                />
                <ReactQuill
                    value={content}
                    onChange={handleContentChange}
                    modules={{
                        toolbar: quillToolBarMinAccess,
                    }}
                />
                <div className={styles.buttonSpacing}>
                    <button
                        className="myButton submit"
                        onClick={handleSubmit}
                        type="button"
                    >
                        {t("Post")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Discussion;
