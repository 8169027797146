import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import styles from "./index.module.css";
import AlertBanner from "components/AlertBanner";
import {
    TANDC_kr,
    TANDC_ca,
    PRIVACY_kr,
    PRIVACY_ca,
    SIGNUPEMAIL_kr,
    SIGNUPEMAIL_ca,
} from "../../tandc";
import { sendEmail } from "../../utility/utility";
import LoadingImg from "../../img/20x20loading.gif";

const Registration = () => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.app.lang);
    const MAX_USERNAME_LENGTH = 10;
    const MIN_PASSWORD_LENGTH = 6;
    const MAX_PASSWORD_LENGTH = 20;
    const MAX_EMAIL_LENGTH = 100;
    const [x] = useState(Math.floor(Math.random() * 11));
    const [y] = useState(Math.floor(Math.random() * 11));
    const [showPassword, setShowPassword] = useState(true);
    const [alertState, setAlertState] = useState({});
    const [spamValidity, setSpamValidity] = useState(false);
    const [emailValidity, setEmailValidity] = useState(false);
    const [usernameValidity, setUsernameValidity] = useState(false);
    const [passwordValidity, setPasswordValidity] = useState(false);
    const [confirmPasswordValidity, setConfirmPasswordValidity] =
        useState(false);
    const [passwordMatchValidity, setPasswordMatchValidity] = useState(true);
    const [isUsernameDuplicate, setIsUsernameDuplicate] = useState(false);
    const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
    const [agreeToMarketing, setAgreeToMarketing] = useState(true);
    const [isUsernameSpecialChar, setIsUsernameSpecialChar] = useState(false);
    const [answerCorrect, setAnswerCorrect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: "", // Updated from 'id' to 'username'
        email: "",
        password: "",
        confirmPassword: "",
        spamPrevention: "",
        agreeToTerms: false,
        agreeToPrivacy: false,
        agreeToAll: false,
    });
    const isFormValid =
        formData.username &&
        formData.email &&
        formData.password &&
        formData.confirmPassword &&
        formData.agreeToTerms &&
        formData.agreeToPrivacy &&
        spamValidity &&
        emailValidity &&
        usernameValidity &&
        passwordValidity &&
        confirmPasswordValidity &&
        passwordMatchValidity &&
        !isEmailDuplicate &&
        !isUsernameDuplicate;

    useEffect(() => {
        isUsernameValid();
        isSpamAnswerValid();
        isEmailValid();
        isPasswordValid();
        isConfirmPasswordValid();
    }, [formData]);

    const TANDC = lang === "kr" ? TANDC_kr : TANDC_ca;
    const PRIVACY = lang === "kr" ? PRIVACY_kr : PRIVACY_ca;
    const SIGNUPEMAIL = lang === "kr" ? SIGNUPEMAIL_kr : SIGNUPEMAIL_ca;

    const handleChange = async (e) => {
        let { name, value, type, checked } = e.target;

        if (name === "username" && value.length > MAX_USERNAME_LENGTH) {
            value = value.slice(0, MAX_USERNAME_LENGTH);
        }

        if (name === "email" && value.length > MAX_EMAIL_LENGTH) {
            value = value.slice(0, MAX_EMAIL_LENGTH);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));

        // Check for duplicates when username or email changes
        if (name === "username") {
            await checkForDuplicates(name, value);
        } else if (name === "email" && validator.isEmail(value)) {
            await checkForDuplicates(name, value);
        }
    };

    const handleOptChange = (e) => {
        setAgreeToMarketing(e.target.checked);
    };

    const checkForDuplicates = async (fieldName, fieldValue) => {
        try {
            const response = await axios.get(`/api/user/validate`, {
                params: { [fieldName]: fieldValue },
            });

            if (response.status === 200) {
                const result = response.data;

                if (fieldName === "username") {
                    setIsUsernameDuplicate(result.duplicateFound);
                } else if (fieldName === "email") {
                    setIsEmailDuplicate(result.duplicateFound);
                }
            } else {
                // Reset both duplicate states if there is an error
                setIsUsernameDuplicate(false);
                setIsEmailDuplicate(false);
            }
        } catch (error) {
            console.error("Error checking for duplicates:", error);

            // Reset both duplicate states if there is an error
            setIsUsernameDuplicate(false);
            setIsEmailDuplicate(false);
        }
    };

    const isPasswordValid = () => {
        if (
            validator.matches(formData.password, /^[\w\d!@#$%^&*]+$/) &&
            validator.isLength(formData.password, {
                min: MIN_PASSWORD_LENGTH,
                max: MAX_PASSWORD_LENGTH,
            })
        ) {
            setPasswordValidity(true);
        } else {
            setPasswordValidity(false);
        }
    };

    const isConfirmPasswordValid = () => {
        if (
            validator.matches(formData.confirmPassword, /^[\w\d!@#$%^&*]+$/) &&
            validator.isLength(formData.confirmPassword, {
                min: MIN_PASSWORD_LENGTH,
                max: MAX_PASSWORD_LENGTH,
            })
        ) {
            setConfirmPasswordValidity(true);
        } else {
            setConfirmPasswordValidity(false);
        }

        if (formData.password === formData.confirmPassword) {
            setPasswordMatchValidity(true);
        } else {
            setPasswordMatchValidity(false);
        }
    };

    const isUsernameValid = () => {
        const usernameRegex = /^[A-Za-z0-9가-힣]+$/;
        const isUsernameNonEmpty = formData.username.length > 0;
        const doesUsernameMatchRegex = validator.matches(
            formData.username,
            usernameRegex
        );
        setUsernameValidity(isUsernameNonEmpty && doesUsernameMatchRegex);
        setIsUsernameSpecialChar(isUsernameNonEmpty && !doesUsernameMatchRegex); // Only set special character warning if the username is non-empty and does not match the regex
    };

    const isEmailValid = () => {
        if (validator.isEmail(formData.email)) {
            setEmailValidity(true);
        } else {
            setEmailValidity(false);
        }
    };

    const handleSubmit = async (event) => {
        const code = Math.floor(Math.random() * 100000);
        let htmlToSend = SIGNUPEMAIL.replace("{0}", formData.email);
        htmlToSend = htmlToSend.replace("{1}", code);
        htmlToSend = htmlToSend.replace("{2}", new Date().getFullYear());

        try {
            setLoading(true);

            const res = await axios.post(`/api/user`, {
                email: formData.email,
                username: formData.username,
                password: formData.password,
                wantEmail: agreeToMarketing,
                code: code,
            });

            if (res.status === 200) {
                const emailResponse = await sendEmail(axios, {
                    topicId: "",
                    replyId: "",
                    subject: t("VerifyEmailSubject"),
                    body: htmlToSend,
                    emailto: formData.email,
                });
                if (emailResponse === 200) {
                    setFormData({
                        username: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                        spamPrevention: "",
                        agreeToTerms: false,
                        agreeToPrivacy: false,
                        agreeToAll: false,
                    });
                    setSpamValidity(false);
                    setAlertState({
                        alertOpen: true,
                        alertMsg: t("EmailSentSuccess", {
                            email: formData.email,
                        }),
                        alertSev: "success",
                    });
                } else {
                    setAlertState({
                        alertOpen: true,
                        alertMsg: t("EmailSentError", {
                            email: formData.email,
                        }),
                        alertSev: "error",
                    });
                }
            } else {
                setAlertState({
                    alertOpen: true,
                    alertMsg: t("ErrorMessage"),
                    alertSev: "error",
                });
            }
        } catch (error) {
            setAlertState({
                alertOpen: true,
                alertMsg: JSON.stringify(error.response.data),
                alertSev: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const isSpamAnswerValid = () => {
        const correctAnswer = x + y;
        const nonEmpty = formData.spamPrevention.length > 0;

        if (nonEmpty) {
            if (
                validator.isNumeric(formData.spamPrevention) &&
                parseInt(formData.spamPrevention, 10) === correctAnswer
            ) {
                setSpamValidity(true);
                setAnswerCorrect(true); // Reset answerCorrect if the answer is correct
            } else {
                setSpamValidity(false);
                setAnswerCorrect(false); // Set answerCorrect to true if the answer is incorrect
            }
        } else {
            setAnswerCorrect(true);
        }
    };

    return (
        <>
            <div className={styles.regContainer}>
                <div className={styles.regImgContainer}>
                    <div className={styles.regTitle}>{t("RegisterTitle")}</div>
                    <div className={styles.regDesc}>
                        {t("RegisterDescription")}
                    </div>
                </div>
                <AlertBanner
                    type={alertState.alertSev}
                    visibility={alertState.alertOpen}
                    message={alertState.alertMsg}
                />
                <div
                    className={`row align-items-center ${styles.inputDiv} ${styles.top}`}
                >
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("UsernameLabel")}{" "}
                        <span className={styles.mustTxt}>*</span>{" "}
                    </label>
                    <div
                        className={`col-sm-10 d-flex align-items-center ${styles.mobileInputPad}`}
                    >
                        <Form.Control
                            type="text"
                            name="username"
                            placeholder={t("UsernamePlaceholder")}
                            value={formData.username}
                            onChange={handleChange}
                            isValid={usernameValidity && !isUsernameDuplicate}
                            className={
                                isUsernameDuplicate
                                    ? "is-invalid"
                                    : usernameValidity
                                    ? "is-valid"
                                    : ""
                            }
                            style={{ maxWidth: "185px" }}
                        />
                        {isUsernameDuplicate && (
                            <span className={styles.errorTxt}>
                                {t("UsernameInUse")}
                            </span>
                        )}
                        {isUsernameSpecialChar && (
                            <span className={styles.errorTxt}>
                                {t("UsernameSpecialChar")}
                            </span>
                        )}
                    </div>
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("EmailLabel")}{" "}
                        <span className={styles.mustTxt}>*</span>
                    </label>
                    <div
                        className={`col-sm-10 d-flex align-items-center ${styles.mobileInputPad}`}
                    >
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={t("EmailPlaceholder")}
                            value={formData.email}
                            onChange={handleChange}
                            isValid={emailValidity && !isEmailDuplicate}
                            className={
                                isEmailDuplicate
                                    ? "is-invalid"
                                    : emailValidity
                                    ? "is-valid"
                                    : ""
                            }
                            style={{ maxWidth: "330px" }}
                        />
                        {isEmailDuplicate && (
                            <span className={styles.errorTxt}>
                                {t("EmailInUse")}
                            </span>
                        )}
                    </div>
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("PasswordLabel")}{" "}
                        <span className={styles.mustTxt}>*</span>
                    </label>
                    <div
                        className={`col-sm-10 d-flex align-items-center ${styles.mobileInputPad}`}
                    >
                        <InputGroup
                            style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder={t("PasswordPlaceholder")}
                                value={formData.password}
                                onChange={handleChange}
                                style={{
                                    maxWidth: "175px",
                                    zIndex: 0,
                                    width: "calc(100% - 30px)",
                                    border: passwordValidity
                                        ? "1px solid #28a745"
                                        : "1px solid #ced4da",
                                }}
                            />
                            {/* Add a piece of text to indicate show/hide password state */}
                            <span
                                style={{
                                    position: "absolute",
                                    right: "15px", // Adjust as needed for alignment
                                    cursor: "pointer",
                                    fontSize: "0.9em",
                                    color: "#007bff", // Optional: use a color that matches your design
                                    zIndex: 1, // Ensure the text is in front of the password field
                                }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? t("Hide") : t("Show")}
                            </span>
                            {passwordValidity && (
                                <InputGroup.Text
                                    style={{
                                        position: "absolute",
                                        right: "45px", // Adjust as needed for alignment
                                        backgroundColor: "transparent",
                                        border: "none",
                                        fontWeight: "900",
                                        color: "#28a745", // Green color for valid
                                    }}
                                >
                                    ✓
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                        &nbsp;&nbsp;
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder={t("ConfirmPassword")}
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            isValid={confirmPasswordValidity}
                            style={{ maxWidth: "155px" }}
                        />
                        {!passwordMatchValidity && (
                            <span className={styles.errorTxt}>
                                {t("PasswordMismatch")}
                            </span>
                        )}
                    </div>
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("SpamPreventionLabel")}{" "}
                        <span className={styles.mustTxt}>*</span>
                    </label>
                    <div
                        className={`col-sm-10 d-flex align-items-center ${styles.mobileInputPad}`}
                    >
                        <span>
                            {x} + {y} =
                        </span>
                        <Form.Control
                            type="text"
                            name="spamPrevention"
                            placeholder={t("SpamPreventionPlaceholder")}
                            value={formData.spamPrevention}
                            onChange={handleChange}
                            isValid={spamValidity}
                            style={{ width: "173px", marginLeft: "5px" }}
                        />
                        {!answerCorrect && (
                            <span className={styles.errorTxt}>
                                {t("SpamPreventionIncorrect")}
                            </span>
                        )}
                    </div>
                </div>
                <div className={`row align-items-center ${styles.inputDiv}`}>
                    <label className={`col-sm-2 ${styles.valignLabel}`}>
                        {t("ConsentLabel")}
                    </label>
                    <div
                        className="col-sm-10"
                        style={{ padding: "5px 10px 0px 20px" }}
                    >
                        <label className={styles.customLabel}>
                            <input
                                type="checkbox"
                                name="agreeToMarketing"
                                checked={agreeToMarketing}
                                onChange={(e) => {
                                    handleOptChange(e);
                                }}
                                className={styles.rightMargin}
                            />{" "}
                            {t("ConsentAgreeText")}
                        </label>
                        <div className={styles.safeTxt}>
                            {t("ConsentNotice")}
                        </div>
                    </div>
                </div>
                <div className={styles.tableTop}>
                    <label className={styles.customLabel}>
                        <input
                            type="checkbox"
                            name="agreeToAll"
                            checked={formData.agreeToAll}
                            className={styles.rightMargin}
                            onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked) {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        agreeToTerms: true,
                                        agreeToPrivacy: true,
                                    }));
                                    setAgreeToMarketing(true);
                                }
                            }}
                        />
                        {t("ConsentAllAgreeText")}
                    </label>
                </div>
                <div className={`container-fluid ${styles.fullWidthRow}`}>
                    <div className={`row`}>
                        <div className={`col-sm-6 ${styles.tableLeft}`}>
                            <label className={styles.customLabel}>
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    className={styles.rightMargin}
                                    onChange={handleChange}
                                />
                                {t("ConsentTermsAgreeText")}{" "}
                                <span className={styles.mustTxt}>
                                    ({t("Required")})
                                </span>
                                <div
                                    className={styles.ygDiv}
                                    dangerouslySetInnerHTML={{
                                        __html: TANDC,
                                    }}
                                />
                            </label>
                        </div>
                        <div className={`col-sm-6 ${styles.tableRight}`}>
                            <label className={styles.customLabel}>
                                <input
                                    type="checkbox"
                                    name="agreeToPrivacy"
                                    checked={formData.agreeToPrivacy}
                                    className={styles.rightMargin}
                                    onChange={handleChange}
                                />
                                {t("ConsentPrivacyAgreeText")}{" "}
                                <span className={styles.mustTxt}>
                                    ({t("Required")})
                                </span>
                                <div
                                    className={styles.ygDiv}
                                    dangerouslySetInnerHTML={{
                                        __html: PRIVACY,
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles.registerButton}>
                    <button
                        onClick={handleSubmit}
                        disabled={!isFormValid || loading}
                        className={`myButton submit ${
                            !isFormValid ? "disabled" : ""
                        } ${loading ? "loading" : ""}`}
                    >
                        {loading ? (
                            <>
                                <img
                                    src={LoadingImg}
                                    style={{
                                        verticalAlign: "sub",
                                        height: "18px",
                                    }}
                                    alt="loading"
                                />{" "}
                                {t("SignUpButton")}
                            </>
                        ) : (
                            t("SignUpButton")
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Registration;
