import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import AlertBanner from "../../AlertBanner";

const Toggle = ({ name, topicSortMethods, toggleSortMethod }) => {
    const { t } = useTranslation();
    const [topicSort, setTopicSort] = useState("");

    const switchTopicSort = () => {
        switch (topicSort) {
            case topicSortMethods.asc:
                setTopicSort(topicSortMethods.des);
                break;
            case topicSortMethods.des:
                setTopicSort(topicSortMethods.asc);
                break;
            default:
                setTopicSort(topicSortMethods.des);
                break;
        }
        toggleSortMethod(topicSort);
    };

    return (
        <>
            <button className={styles.text_button} onClick={switchTopicSort}>
                {t(name)}
            </button>
        </>
    );
};

const TopicListController = ({ topicSortMethods, toggleSortMethod }) => {
    const { t } = useTranslation();
    const email = useSelector((state) => state.app.email);
    const langCode = useSelector((state) => state.app.lang);
    const [alertState, setAlertState] = useState({});
    useEffect(() => {
        let alertMsg = t("UseEmailCorrectly", { email });
        if (!email || email === "") {
            alertMsg = t("NotLoggedinBoardMsg");
        }
        setAlertState({
            alertOpen: true,
            alertMsg,
            alertSev: "info",
        });
    }, [email, langCode]);

    return (
        <>
            <div className={styles.bannerDiv}>
                <AlertBanner
                    type={alertState.alertSev}
                    visibility={alertState.alertOpen}
                    message={alertState.alertMsg}
                />
            </div>
            <div className={styles.topicListContainer}>
                <div className={styles.small_item}>
                    <Toggle
                        name="Category"
                        topicSortMethods={topicSortMethods.category}
                        toggleSortMethod={toggleSortMethod}
                    />
                </div>
                <div className={styles.big_item}>
                    <Toggle
                        name="Topic"
                        topicSortMethods={topicSortMethods.name}
                        toggleSortMethod={toggleSortMethod}
                    />
                </div>
                <div className={styles.small_item}></div>
                <div className={styles.small_item}>
                    <Toggle
                        name="Replies"
                        topicSortMethods={topicSortMethods.replies}
                        toggleSortMethod={toggleSortMethod}
                    />
                </div>
                <div className={styles.small_item}>
                    <Toggle
                        name="Clicks"
                        topicSortMethods={topicSortMethods.views}
                        toggleSortMethod={toggleSortMethod}
                    />
                </div>
                <div className={styles.time_item}>
                    <Toggle
                        name="PostDate"
                        topicSortMethods={topicSortMethods.activity}
                        toggleSortMethod={toggleSortMethod}
                    />
                </div>
            </div>
        </>
    );
};

export default TopicListController;
