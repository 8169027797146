import React, { useRef, useState } from "react";
import { Form, Alert, InputGroup } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi"; // Import the error icon
import { useHistory } from "react-router-dom";
import validator from "validator";
import LoginImg from "../../../img/login.svg";
import LoadingImg from "../../../img/20x20loading.gif";

const Login = ({ setToken }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const form = useRef();
    const user = useRef();
    const history = useHistory();
    const password = useRef();
    const [showPassword, setShowPassword] = useState(true);
    const [passwordValidity, setPasswordValidity] = useState(false);
    const MIN_PASSWORD_LENGTH = 6;
    const MAX_PASSWORD_LENGTH = 20;
    const [loading, setLoading] = useState(false);

    const onSubmit = async (event) => {
        try {
            setLoading(true);
            event.preventDefault();
            const res = await axios.post(`/api/session/local`, {
                username: user.current.value,
                password: password.current.value,
            });
            if (res.status === 200) {
                // Set user data in cookies
                const loginData = {
                    username: res.data.username,
                    password: password.current.value,
                    email: res.data.email,
                    accessToken: res.data.accessToken,
                    isSuperUser: res.data.isSuperUser,
                };

                // Set the expiration time to approximately 70 hours (in milliseconds)
                const expirationTime =
                    new Date().getTime() + 70 * 60 * 60 * 1000;

                // Store loginData and expirationTime in localStorage
                localStorage.setItem("loginData", JSON.stringify(loginData));
                localStorage.setItem(
                    "loginDataExpiration",
                    expirationTime.toString()
                );

                setToken(
                    res.data.username,
                    password.current.value,
                    res.data.accessToken,
                    res.data.isSuperUser,
                    res.data.email
                );

                if (
                    window.location.pathname === "/logout" ||
                    window.location.pathname.startsWith("/verify")
                ) {
                    history.push("/");
                }
            } else {
                throw Error("authentication failed");
            }
        } catch (error) {
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const isPasswordValid = () => {
        if (
            validator.matches(password.current.value, /^[\w\d!@#$%^&*]+$/) &&
            validator.isLength(password.current.value, {
                min: MIN_PASSWORD_LENGTH,
                max: MAX_PASSWORD_LENGTH,
            })
        ) {
            setPasswordValidity(true);
        } else {
            setPasswordValidity(false);
        }
    };

    return (
        <Form onSubmit={onSubmit} ref={form}>
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img src={LoginImg} height="80" alt="Login" />
            </div>
            {showAlert && (
                <Alert
                    variant="danger"
                    onClose={() => {
                        setShowAlert(false);
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <BiErrorCircle size={25} />
                    &nbsp;&nbsp;
                    {t("LoginFailed")}
                </Alert>
            )}
            <div style={{ margin: "10px 0" }}>
                <Form.Control
                    type="text"
                    ref={user}
                    placeholder={t("EmailOrUserName")}
                    autoComplete="username"
                />
            </div>
            <InputGroup
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Form.Control
                    type={showPassword ? "text" : "password"}
                    ref={password}
                    placeholder={t("Password")}
                    autoComplete="current-password"
                    onChange={isPasswordValid}
                    style={{
                        zIndex: 0,
                        width: "calc(100% - 30px)",
                        border: passwordValidity
                            ? "1px solid #28a745"
                            : "1px solid #ced4da",
                    }}
                />
                {/* Add a piece of text to indicate show/hide password state */}
                <span
                    style={{
                        position: "absolute",
                        right: "15px", // Adjust as needed for alignment
                        cursor: "pointer",
                        fontSize: "0.9em",
                        color: "#007bff", // Optional: use a color that matches your design
                        zIndex: 1, // Ensure the text is in front of the password field
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? t("Hide") : t("Show")}
                </span>
                {passwordValidity && (
                    <InputGroup.Text
                        style={{
                            position: "absolute",
                            right: "45px", // Adjust as needed for alignment
                            backgroundColor: "transparent",
                            border: "none",
                            fontWeight: "900",
                            color: "#28a745", // Green color for valid
                        }}
                    >
                        ✓
                    </InputGroup.Text>
                )}
            </InputGroup>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
                <button
                    disabled={!passwordValidity || loading}
                    className={`myButton submit ${
                        !passwordValidity ? "disabled" : ""
                    } ${loading ? "loading" : ""}`}
                    type="submit"
                >
                    {loading ? (
                        <>
                            <img
                                src={LoadingImg}
                                style={{ verticalAlign: "sub", height: "18px" }}
                                alt="loading"
                            />{" "}
                            {t("Login")}
                        </>
                    ) : (
                        t("Login")
                    )}
                </button>
                <div style={{ marginTop: "17px" }}>
                    {/* Add "Forgot Password" link */}
                    <a
                        href="#"
                        onClick={() => alert("기능 준비중입니다.")}
                        style={{ cursor: "pointer" }}
                    >
                        {t("ForgotPassword")}
                    </a>
                </div>
            </div>
        </Form>
    );
};

Login.defaultProps = { address: "/", setToken: () => {} };

export default Login;
