import React from "react";
import TopicItem from "components/Topic/Item";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const TopicList = ({ isLoading, items, forumName, forumId }) => {
    const { t } = useTranslation();
    return (
        <>
            {isLoading === false && (
                <div className={styles.topicListContainer}>
                    {Array.isArray(items) && items.length > 0 ? (
                        items.map((item, index) => {
                            return (
                                <TopicItem
                                    key={index}
                                    forumName={forumName}
                                    forumId={forumId}
                                    {...item}
                                />
                            );
                        })
                    ) : (
                        <div className={styles.noTopicFound}>{t("NoPost")}</div>
                    )}
                </div>
            )}
        </>
    );
};

export default TopicList;
