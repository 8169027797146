import React from "react";
import ReplyItem from "./Item";
import styles from "components/Reply/List.module.css";

const ReplyList = ({
    isLoading,
    items,
    updateAlertBanner,
    reloadReplyList,
}) => {
    return (
        <>
            {isLoading === false && (
                <div className={styles.replyListContainer}>
                    {items.map((item, index) => {
                        return (
                            <ReplyItem
                                key={index}
                                {...item}
                                updateAlertBanner={updateAlertBanner}
                                reloadReplyList={reloadReplyList}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

ReplyList.defaultProps = {
    isLoading: false,
    replies: [],
};

export default ReplyList;
