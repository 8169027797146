import React from "react";
import List from "components/Profile/List";
import styles from "./index.module.css";
import { AiTwotoneMail } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";

const UserProfile = ({
    name,
    email,
    avatarUrl,
    topics,
    replies,
    isLoading,
}) => (
    <div className={styles.userProfileContainer}>
        <div className={styles.item}>
            <img
                alt="Avatar"
                className={styles.profile_img}
                src={avatarUrl}
            ></img>
            <div className={styles.itemTitleTop} style={{ marginLeft: "20px" }}>
                <BiSolidUser />
                &nbsp;이름: {name}
            </div>
            <div className={styles.itemTitleTop} style={{ marginLeft: "20px" }}>
                <AiTwotoneMail />
                &nbsp;이메일: {email}
            </div>
        </div>
        <div className={styles.itemTitle}>게시판 기록들</div>
        <List isLoading={isLoading} items={topics} />
        <div className={styles.itemTitle}>답글 기록들</div>
        <List isLoading={isLoading} items={replies} />
    </div>
);

export default UserProfile;
