import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Loading from "components/Loading";
import ForumList from "components/Forum/List";
import ForumToolBar from "components/Forum/ForumToolBar";
import styles from "./index.module.css";
import {
    UPDATE_FORUMS_START,
    UPDATE_FORUMS_SUCCESS,
    UPDATE_FORUMS_FAILURE,
} from "./constants";

const Forums = () => {
    const dispatch = useDispatch();
    const forumList = useSelector((state) => state.forums.forumList);
    const isLoading = useSelector((state) => state.forums.isLoading);
    const isSuperUser = useSelector((state) => state.app.isSuperUser);
    const getAllForumsExceptBulletinData = async () => {
        try {
            dispatch({ type: UPDATE_FORUMS_START });

            let storedData = localStorage.getItem("forumData");
            //storedData = false; // 무조건 DB에서 새로 전체 여행 로딩
            let forumList = [];
            if (storedData) {
                const storedTime = localStorage.getItem("forumDataTime");
                const apiResponse = await fetchDataFromApi(storedTime);
                if (apiResponse.data.length > 0) {
                    console.log("전체 포럼: DB가 업데이트되 새로 데이터 얻음");
                    forumList = apiResponse.data;
                    // Update localStorage with fresh data and current time
                    localStorage.setItem(
                        "forumData",
                        JSON.stringify(forumList)
                    );
                    localStorage.setItem("forumDataTime", new Date().getTime());
                } else {
                    console.log(
                        "전체 포럼: DB가 업데이트되지 않아 예전 데이터 사용"
                    );
                    forumList = JSON.parse(storedData);
                }
            } else {
                // If no data in localStorage, make an API call to get fresh data
                const apiResponse = await fetchDataFromApi(0); // Pass 0 if no stored time
                forumList = apiResponse.data;

                // Update localStorage with fresh data and current time
                localStorage.setItem("forumData", JSON.stringify(forumList));
                localStorage.setItem("forumDataTime", new Date().getTime());
                console.log(
                    "전체 포럼: 로컬스토리지에 데이터가 없음. 새로 데이터 얻음"
                );
            }

            dispatch({
                type: UPDATE_FORUMS_SUCCESS,
                forumList: forumList,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_FORUMS_FAILURE,
                error: error.message,
            });
        }
    };

    const fetchDataFromApi = async (storedTime) => {
        let status = 0; // Get only active forums
        if (isSuperUser) {
            status = -1; // Get all forums
        }

        const url = `/api/forum?status=${status}&storedTime=${storedTime}`;
        return await axios.get(url);
    };

    useEffect(getAllForumsExceptBulletinData, []);

    return (
        <div className={styles.forumContainer}>
            {isLoading === true && <Loading />}
            <ForumList isLoading={isLoading} forums={forumList} />
            <ForumToolBar
                url={`/api/forum`}
                onNewForum={getAllForumsExceptBulletinData}
            />
        </div>
    );
};

export default Forums;
