import React from "react";
import Item from "components/Profile/Item";
import styles from "./List.module.css";

const ProfileList = ({ isLoading, items }) => {
    return (
        <div className={styles.profileListContainer}>
            {isLoading === false ? (
                items.length > 0 ? (
                    items.map((item, index) => {
                        const topicName =
                            item.topic && item.topic.name
                                ? item.topic.name
                                : "";
                        const topicId =
                            item.topic && item.topic._id ? item.topic_id : "";
                        return (
                            <Item
                                key={index}
                                forumName={item.forum.name}
                                forumId={item.forum._id}
                                topicName={topicName}
                                topicId={topicId}
                                createdAt={item.createdAt}
                            />
                        );
                    })
                ) : (
                    <div className={styles.noData}>데이터가 없습니다.</div>
                )
            ) : (
                <div>로딩중...</div>
            )}
        </div>
    );
};

export default ProfileList;
